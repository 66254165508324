<!--
 * @Descripttion: 
 * @Date: 2024-08-23 15:28:01
 * @LastEditTime: 2024-09-27 16:44:20
-->
<template>
  <view class="entNewFavorite">
    <view class="entNewFavorite-outer">
      <image :src="formdata.d.logo" />
      <view class="entNewFavorite-outer-title">
        <text>{{ formdata.d.title }}</text>
      </view>
      <view @click="toggle"
            :class="status ? 'favorite-selected' : 'favorite'" /> 
    </view>
  </view>
</template>

<script>
import store from "@/store"
export default {
  props: {
    formdata: {
      type: Object,
      default: () => { }
    },
  },
  computed: {
    status () {
      return store.state.corpFavorite.status
    }
  },
  methods: {
    toggle () {
      const path = this.status ? 'delFavorite' : 'saveFavorite'
      const params = {
        favType: 14,
        favId: this.formdata.d.id
      }

      this.$util.api(`kuaibao.personal.${path}`, params).then(res => {
        if (res.c === 200) {
          store.dispatch("setCorpStatus", this.status)
        } else {
				  uni.showToast({ title: res.e, icon: 'none' })
				}
      })
    }
  }
}
</script>

<style lang="scss" scoped scoped>
.entNewFavorite {
  position: relative;
  &-outer {
    position: absolute;
    top: -36px;
    left: 0;
    right: 0;
    z-index: 800;
    display: flex;
    align-items: center;
    padding: 8px 15px 8px 22px;
    background: #00010280;
    image {
      width: 45rpx;
      height: 40rpx;
    }
    &-title {
      flex: 1;
      display: grid;
      grid-template-columns: auto;
      text {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: #fff;
        font-family: OPPOSans;
        font-size: 24rpx;
        margin-right: 5px;
        margin-left: 8px;
      }
    }
    .favorite {
      width: 115rpx;
      height: 42rpx;
      background: url("https://static.jiuzhouauto.com/h5_image/showroom/favorite-img.png") no-repeat;
      background-size: 100% 100%;
    }
    .favorite-selected {
      width: 115rpx;
      height: 42rpx;
      background: url("https://static.jiuzhouauto.com/h5_image/showroom/favorite-img-selected.png") no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>