<template>
  <view>
    <swipe-action
      v-if="cardData.d.mySwapCard && cardData.d.mySwapCard === 1"
      @content-click="clickCard"
      @open="openSwipe"
      :show="cardData.d.show"
      :options="swipeAction"
    >
      <view style="padding: 20rpx 30rpx; height: 100%;box-sizing:border-box">
        <bus-card
          :showEnt="showEnt"
          :card-data="cardData"
          :showNameCardImg="showNameCardImg"
          :showNameCard="true"
          :hideNoneData="true"
          :hidePhone="isMyCard ? false : !exchanged"
          :hideEmail="isMyCard ? false : !exchanged"
          :isMyCard="isMyCard"
        />
      </view>
      <template v-slot:right>
        <view @click.stop.prevent="deleteCard" class="delBtn flex justify-center align-center">
          <image class="image" src="http://static.jiuzhouauto.com/h5_image/bus_card/delete_card.png" mode="aspectFit" />
        </view>
      </template>
    </swipe-action>
    <bus-card
      v-else
      :showNameCard="true"
      :showNameCardImg="showNameCardImg"
      :showEnt="showEnt"
      :card-data="cardData"
      @click.native="exchangeCard"
      :hideNoneData="true"
      :hidePhone="isMyCard ? false : !exchanged"
      :hideEmail="isMyCard ? false : !exchanged"
      :isMyCard="isMyCard"
    >
      <!-- 是否已交换按钮，没有mySwapCard为标识的时候显示是否已交换-->
      
      <view
        v-if="!isMyCard && !cardData.d.mySwapCard && showExchange"
        slot="button"
        class="exchange flex justify-start align-center bg-white"
      >
        <view class="exchangeCard" :class="[exchanged ? '' : 'active']">{{
          exchanged ? $t("entCard.ent_card_list.已交换") : $t("entCard.ent_card_list.交换名片")
        }}</view>
        <!--        <view class="text" :class="exchanged ? '' : 'active'"></view>-->
        <!--        <view v-if="!exchanged" class="icon">-->
        <!--          <image src="http://static.jiuzhouauto.com/h5_image/bus_card/exchange.png" mode="aspectFit"></image>-->
        <!--        </view>-->
      </view>
    </bus-card>
    <!-- <login-modal :show="loginModal" @close="loginModal = false" /> -->
  </view>
</template>

<script>
// import loginModal from "@/components/loginModal";
import SwipeAction from "@/components/common/SwipeAction";
import Card from "@/components/busCard/Card";
import { mapState } from "vuex";
export default {
  name: "TypeBusinessCard",
  components: {
    "swipe-action": SwipeAction,
    "bus-card": Card
  },
  props: {
    showNameCardImg: { type: Boolean, default: false },
    showEnt: {
      type: Boolean,
      default: true
    },
    showExchange: {
      type: Boolean,
      default: true
    },
    formdata: {
      type: Object,
      default: {}
    },
    hideNoneData: {
      type: Boolean,
      default: true
    },
    locale: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      loginModal: false,
      cardData: {
        t: "",
        s: "",
        d: {
          industry: {
            title: [],
            code: []
          }
        }
      },
      swipeAction: [
        {
          text: "删除",
          style: {
            backgroundColor: "#007aff"
          }
        }
      ],
      exchanged: false,
      isMyCard: false
    };
  },
  computed: {
    ...mapState({
      storeMyExCardId: state => state.BusinessCardModule.myExchangedCardId,
      storeMyCardId: state => state.BusinessCardModule.myCardId
    })
  },
  watch: {
    storeMyExCardId: {
      immediate: true,
      handler: function() {
        this.checkExchanged();
      }
    },
    storeMyCardId: {
      immediate: true,
      handler: function() {
        this.checkIsMyCard();
      }
    },
    formdata: {
      immediate: false,
      handler: function(newValue) {
        this.cardData = newValue;
      },
      deep: true
    }
  },
  methods: {
    clickCard() {
      console.log('click')
      this.$emit('toInfo',{exchanged:this.exchanged,id:this.cardData.d._id})
      this.$emit("click-card", this.cardData.d._id);
    },
    async exchangeCard() {
      if (this.isMyCard) {
        uni.showModal({
          showCancel: false,
          title: this.$t("common.提示"),
          content: this.$t("entCard.exchange_card.不能交换自己的名片"),
          confirmText: this.$t("common.确定"),
          success: function(res) {
            // if (res.confirm) {
            //   uni.navigateTo({
            //     url: '/packages_form/pages/bus_card/personal'
            //   })
            // }
          }
        });
        return false;
      }
      let isLogin = await this.$util.checkLogin();
      if (!isLogin) {
        // #ifdef H5
        // this.$util.showLoginDialog()
        // #endif
        return false;
      }
      let params = {
        cardId: this.cardData.d._id,
        exchanged: this.exchanged
      };
      this.$emit("exchange-card", params);
    },
    openSwipe() {
      this.$emit("open-swipe", this.cardData.d._id);
    },
    deleteCard() {
      this.$emit("delete-card", this.cardData.d._id);
    },
    checkExchanged() {
      let exchangedId = this.storeMyExCardId;
      if (exchangedId.some(item => item === this.cardData.d._id)) {
        this.exchanged = true;
      }
      // if (!this.cardData.d.mySwapCard) {
      // let exchangedId = JSON.parse(uni.getStorageSync('__exchangeCardId') || JSON.stringify([]))
      // }
    },
    checkIsMyCard() {
      let myCardId = this.storeMyCardId;
      if (myCardId.some(item => item === this.cardData.d._id)) {
        this.isMyCard = true;
      }
      // if (!this.cardData.d.mySwapCard) {
      // let myCardId = JSON.parse(uni.getStorageSync('__myCardId') || JSON.stringify([]))
      // myCardId.forEach(item => {
      //   console.log(item, this.cardData.d._id)
      // })
      // }
    }
  },
  created() {
    let cardData = this.formdata;
    if (Object.keys(cardData.d).length) {
      this.cardData = cardData;
      this.checkExchanged();
      this.checkIsMyCard();
    }
  }
};
</script>

<style lang="scss" scoped scoped>
.delBtn {
  width: 100%;
  height: 100%;
  .image {
    width: 120upx;
    height: 60upx;
    /*width: 100%;*/
    /*height: 100%;*/
  }
}
/*是否已交换标识*/
.exchangeCard {
  width: 160upx;
  height: 51upx;
  border-radius: 50upx;
  background-color: rgb(121, 121, 121);
  color: #fff;
  text-align: center;
  line-height: 51upx;
  font-weight: bold;
  font-size: 28upx;
  margin-bottom: 20upx;
  &.active {
    background-color: rgb(87, 190, 106);
  }
}
</style>
