const QiNiuWebPlayerSrc = '//sdk-release.qnsdk.com/qiniu-web-player-1.2.3.js'
// const AMapSrc = '//webapi.amap.com/maps?v=1.4.15&key=aee3cafdd5932211f7369b6e1408baf1&plugin=AMap.CitySearch'

function getScript (src) {
  return new Promise((resolve, reject) => {
    let script = document.createElement('script');
    script.src = src
    script.type = 'text/javascript'
    script.onload = () => {
      resolve(true)
    }
    script.onerror = () => {
      reject(true)
    }
    document.getElementsByTagName('head')[0].appendChild(script)
  })
}

// ip获取城市
function getAMap() {
  const retryTime = 3
  let curTime = 1;
  return new Promise((resolve, reject) => {
    function getLocalCity() {
      if (curTime <= retryTime) {
        AMap.plugin('AMap.CitySearch', function () {
          var citySearch = new AMap.CitySearch()
          citySearch.getLocalCity(function (status, result) {
            if (status === 'complete' && result.info === 'OK') {
              sessionStorage.setItem('CURRENT_ADCODE', result.adcode)
              sessionStorage.setItem('CURRENT_PROVINCE', result.province)
                resolve(true)
            } else {
              curTime += 1
              getLocalCity()
            }
          })
        })
      } else {
        resolve({adcode: '', province: ''})
      }
    }
    if (!window.AMap) {
      getScript(AMapSrc).then(() => {
        getLocalCity()
      }).catch(() => {resolve(false)})
    } else {
      resolve(true)
    }
  })
}

// 七牛云播放器sdk
function getQiNiuWebPlayer() {
  return new Promise((resolve, reject) => {
    if (!window.QPlayer) {
      getScript(QiNiuWebPlayerSrc).then(() => {
        resolve(true)
      }).catch(() => {
        reject(true)
      })
    } else {
      resolve(true)
    }
  })
}

export {
  getAMap,
  getQiNiuWebPlayer
}
