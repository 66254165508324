<template>
  <view v-if="visible" class="openWeappModal" @touchmove.stop.prevent>
    <view class="body">
      <view class="closeBtn" @click="close">
        <image class="close" src="//img.jiuzhouauto.com/i/2020/11/02/1604298746486-98746.png" mode="''"></image>
      </view>
      <image class="tipImg" :src="imageUrl" mode="widthFix"></image>
	  <view v-html="htmlStr"></view>
    </view>
  </view>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      title: '',
      imageUrl: '//img.jiuzhouauto.com/i/2020/12/29/1609214234075-14234.png',
      buttonText: '知道了',
      appId: '',
      path: '',
	  htmlStr: ''
    }
  },
  mounted() {
	this.renderDom()
	const that = this
	setTimeout(() => {
		const launchBtn = document.getElementById('launch-weapp-btn')
		if (launchBtn) {
		  launchBtn.addEventListener('launch', function (e) {
		    that.close()
		  });
		  launchBtn.addEventListener('error', function (e) {
		    console.log('fail', e.detail);
		  });
		}
	}, 500)
  },
  created() {
  	let path = this.path.split('?')
  	let prefix = path[0] + '.html?'
  	let params
  	if (path.length > 1) {
  		path.shift()
  		params = path.join('')
  	}
  	this.path = prefix + params
  },
  methods: {
    close() {
      this.visible = false;
	  this.htmlStr = '';
      this.$destroy();
      this.$el.parentNode.removeChild(this.$el);
    },
	renderDom() {
	  let script = document.createElement('script')
	  script.type = 'text/wxtag-template'
	  script.text = `<button style="${this.styleStr}">${this.buttonText}</button>`
	  this.htmlStr = `<wx-open-launch-weapp id="launch-weapp-btn" style="width:100%;display:block;height:100%;" username="${this.appId}" path="${this.path}">${script.outerHTML}</wx-open-launch-weapp>`
	}
  },
  computed: {
    styleStr() {
		let style = `line-height: 2.55555556;border: 0;width: calc(45vw);`
		style += `border-radius: ${uni.upx2px(100)}px;margin-top: ${uni.upx2px(90)}px;`
		style += `background-color: #03c15f;color: white;font-size: ${uni.upx2px(36)}px;`
		style += `margin-left: calc(55vw / 2)`
		return style
    }
  }
}
</script>

<style scoped scoped>
.openWeappModal {
  position: absolute;
  left: 50%;
  transform: translate(-50%,0);
  top: 0;
  z-index: 999999;
  background-color: rgba(0, 0, 0, 0.5);
  max-width: var(--max-width);
  height: 100%;
  padding: 0 40upx;
  display: flex;
  justify-content: center;
  align-items: center;
}

.openWeappModal .closeBtn {
  padding-right: 20upx;
  display: flex;
  justify-content: flex-end;
}

.closeBtn .close {
  width: 40upx;
  height: 40upx;
}

.openWeappModal .tipImg {
  margin-top: 90upx;
  width: calc(100vw - 50upx);
  max-width:  var(--max-width);
  border-radius: 30upx;
}

.openWeappModal .button {
  width: calc(45vw);
  border-radius: 100upx;
  margin-top: 90upx;
  background-color: #03c15f;
  color: white;
  font-size: 36upx;
}
</style>
