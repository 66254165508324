import Vue from "vue";
import Component from './component';

let constructor = Vue.extend(Component);
let instance

const TipModel = (options) => {
  instance = new constructor()
  for(let prop in options) {
    if (options.hasOwnProperty(prop)) {
      instance[prop] = options[prop]
    }
  }
  instance.$mount();
  console.log(instance.$el)
  document.body.appendChild(instance.$el);
}

export default TipModel
