<template>
  <view class="box">
    <view class="box-content">
      <!-- 头部固定导航 -->

      <!-- 透明-->
      <template v-if="showTopBar">
        <!--  #ifndef MP-WEIXIN -->
        <view v-if="showBack" class="topLift forPC" :style="[{ opacity: '' + op + '' }]" @tap="BackPage">
          <image class="topimage" src="https://static.jiuzhouauto.com/h5_image/business_back1.png" mode=""></image>
        </view>
        <!--  #endif -->
        <view v-if="showfav" class="topRight" :style="[{ opacity: '' + op + '' }]" @tap="changeFavorite">
          <image class="topimage" src="http://static.jiuzhouauto.com/h5_image/menu_white.png" mode=""></image>
        </view>

        <!-- 背景白色-->
        <!--  #ifndef MP-WEIXIN -->
        <view v-if="showBack" class="topLift forPC" :style="[{ opacity: '' + 1 - op + '' }]" @tap="BackPage">
          <image class="topimage" src="http://static.jiuzhouauto.com/h5_image/business_back2.png" mode=""></image>
        </view>
        <!--  #endif -->
        <view v-if="showfav" class="topRight" :style="[{ opacity: '' + 1 - op + '' }]" @tap="changeFavorite">
          <image class="topimage" src="http://static.jiuzhouauto.com/h5_image/menu_black.png" mode=""></image>
        </view>
        <view v-if="!topmenuData.length > 0" class="topFix top2" :style="[{ opacity: '' + 1 - op + '' }]">
          <view class="pageTitle text-cut">{{ pageTitle }}</view>
        </view>
      </template>

      <view v-if="showFavorite" class="showFavorite text-sm" @tap="changeFavorite" style="line-height: 40upx">
        <view class="showFavoriteMain" @tap.stop="">
          <view class="FavorItem" v-if="showfavType" @tap="toSaveFavorite">
            <image src="http://static.jiuzhouauto.com/h5_image/icon_follow.png" mode="" class="iconImg"></image>
            {{ $t("ent.关注") }}
          </view>
          <view class="FavorItem" v-else @tap="toSaveFavorite">
            <image src="http://static.jiuzhouauto.com/h5_image/icon_collect.png" mode="" class="iconImg"></image>
            {{ $t("ent.收藏") }}
          </view>
          <view class="FavorItem" v-if="showBusiness" @tap="toEntCardList">
            <image src="http://static.jiuzhouauto.com/h5_image/icon_business.png" mode="" class="iconImg"></image>
            {{ $t("ent.企业名片") }}
          </view>
          <view class="FavorItem" v-if="showChat" @tap="toEntChat">
            <image src="http://static.jiuzhouauto.com/h5_image/icon_chat.png" mode="" class="iconImg"></image>
            {{ $t("ent.企业咨询") }}
          </view>
          <!--  #ifdef  H5 -->
          <view class="FavorItem" v-if="language.show" @tap="changeLan">
            <image
              :src="
                $i18n.locale && $i18n.locale === 'en'
                  ? 'http://static.jiuzhouauto.com/h5_image/lang_cn.png'
                  : 'http://static.jiuzhouauto.com/h5_image/lang_en.png'
              "
              mode=""
              class="iconImg"
            ></image>
            {{ $t("common.切换语言") }}
          </view>
          <!--  #endif -->
        </view>
      </view>
      <!-- 头部固定导航 end  -->

      <!-- 头部搜索 -->
      <view class="barList forPC" v-if="barData.t && barData.t == 'grid'" :style="[{ background: '' + rgbaOP + '' }]">
        <type-grid v-bind:formdata="barData"></type-grid>
      </view>
      <!-- 头部搜索 -->

      <!-- 导航栏区域  -->
      <!-- 移动端  -->
      <scroll-view
        scroll-x
        v-if="!isPc && topmenuData.length > 0"
        class="nav fixed topNav forPC"
        scroll-with-animation
        :scroll-left="scrollLeft"
        :style="[{ top: topNavTop + 'px', background: rgbaOP }]"
        :class="op == 0 ? 'text-black' : ' text-white'"
      >
        <view
          class="cu-item"
          :class="index == TabCur ? 'cur' : ''"
          v-for="(item, index) in topmenuData"
          :key="index"
          @tap="tabSelect(item, index)"
          :data-id="index"
        >
          {{ item.title }}
        </view>
      </scroll-view>
      <!-- PC端  -->
			<!-- #ifndef MP-WEIXIN -->
      <PcScrollBar
        v-else-if="isPc && topmenuData.length > 0"
        class="nav fixed topNav forPC"
        :style="[{ top: topNavTop + 'px', background: rgbaOP }]"
        :cur-index="TabCur"
        :class="op == 0 ? 'text-black' : ' text-white'"
      >
        <PcScrollBarItem v-for="(item, index) in topmenuData" :key="index">
          <view class="cu-item" :class="index == TabCur ? 'cur' : ''" @tap="tabSelect(item, index)">
            {{ item.title }}
          </view>
        </PcScrollBarItem>
      </PcScrollBar>
			<!-- #endif -->
      <!-- 导航栏区域 end -->

      <type-component-list
        :main-data="mainData"
        :page-id="pageId"
        @refresh="refresh"
        @upper="upper"
        @lower="lower"
        @scroll="scroll"
        :mainBottom="mainBottom || 0"
        :mainPaddingBottom="mainPaddingBottom"
        :mainTop="formdata.mainTop || 0"
        :mainBgColor="mainBgColor"
        :height="menuData.t ? '100%' : height"
        :isIndex="isIndex"
      ></type-component-list>
      <!-- 聊天组件-->
      <type-chatInfo
        v-if="showTypeChat"
        :style="[menuData.t ? { bottom: typeChatBottom + 'px' } : {}]"
        class="typeChatInfo"
        v-bind:formdata="typeChatData"
        :page-id="pageId"
      ></type-chatInfo>
    </view>
    <!-- 底部 -->
    <view class="footerNav" v-if="menuData.t">
      <type-grid v-bind:formdata="menuData" @clickBottomNav="checkoutNav"></type-grid>
    </view>
    <!-- 底部 end -->
  </view>
</template>
<script>
// #ifndef MP-WEIXIN
import PcScrollBar from "@/components/common/PcScrollBar";
import PcScrollBarItem from "@/components/common/PcScrollBarItem";
// #endif
import typeComponentList from "@/components/dataType/type-component-list/index";
import TypeListMixin from "@/mixins/type-list";
export default {
  mixins: [TypeListMixin],
  components: {
		// #ifndef MP-WEIXIN
    PcScrollBar,
    PcScrollBarItem,
    // #endif
    "type-component-list": typeComponentList
  },
  props: {
    isIndex: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      default: "85vh"
    },
    formdata: {
      type: Object,
      default: {}
    },
    pageId: {
      type: String,
      default: ""
    },
    showTopBar: {
      //是否显示顶部导航栏
      type: Boolean,
      default: true
    }
  },
  computed: {
    
  },
  methods: {
    refresh() {
      this.$emit("refresh");
    },
    // 点击收藏/关注
    async toSaveFavorite() {
      const isLogin = await this.$util.checkLogin();
      if (!isLogin) {
        // #ifdef H5
        // this.$util.showLoginDialog()
        // #endif
        return false;
      }
      let res = await this.$util.api("kuaibao.personal.saveFavorite", this.theFav);
      // console.log(res)
      if (res.t || res.e) {
        uni.showToast({
          title: res.t || res.e,
          icon: "none",
          duration: 500
        });
      }
      if (res && parseInt(res.c) === 200) {
        this.followBooth = true;
        let mainData = this.mainData;
        this.mainData = mainData.map(item => {
          if (item.t === "entFavorite" || item.t === "boothFavorite") {
            item.followBooth = true;
          }
          return item;
        });
      }
      this.changeFavorite();
    },
    changeFavorite() {
      this.showFavorite = !this.showFavorite;
    },
    // 点击右上角企业名片
    toEntCardList() {
      uni.navigateTo({
        url: `/packages_form/pages/bus_card/ent_card_list?entId=${this.businessEntId}`
      });
      this.showFavorite = false;
    },
    // 点击右上角企业咨询
    async toEntChat() {
      const isLogin = await this.$util.checkLogin();
      if (!isLogin) return false;
      this.showFavorite = false;
      uni.navigateTo({
        url: `/packages_form/pages/chat/index?entId=${this.chatEntId}`
      });
    },
    // 点击右上角切换语言
    changeLan() {
      // this.$util.changeLanguage()
      let targetLan = this.$i18n.locale === "en" ? "zh" : "en";
      uni.setStorageSync("__setProdLan", targetLan);
      // this.$util.changeLanguage(targetLan)
      this.setLan(targetLan);
      this.getNewsList(this.newsParam, "up");
    },
    // 切换导航
    tabSelect(item, index) {
      if (this.TabCur != index) {
        this.TabCur = index;
        this.scrollLeft = (index - 1) * 60;
        this.tabApp = item.app;
				this.noneData = false;
        this.getTabNewsList();
      } else {
        // console.log('就是我不要点了')
      }
    },
    checkoutNav(res) {
      console.log(res, 'res----')
      if (res.menuIndex && this.menuIndex !== res.menuIndex && res.m) {
        this.menuIndex = res.menuIndex;
        this.formdata = res;
        delete res.menuIndex;
        this.newsParam = res;
        this.typeChatData = {};
        this.getNewsList(res, "bottom");
      }
    },
    // 更新公用导航切换后列表内容
    getTabNewsList() {
      this.mainData = [];
      this.toGetPage(this.tabApp);
      this.op = 1;
      this.rgbaOP = "rgba(255, 255, 255,0)";
    },
    // 获取jiuzhouauto
    async toGetPage(obj) {
      let a = await this.$util.gotoApp({
        type: 1,
        app: obj
      });
      this.newsParam = a;
      if (a) {
        let resN = await this.$util.api(a.m, a.d, { lan: this.locale });
        if (resN.d.l.length > 0) {
          this.$util.setMatomo(resN);
          let mainData = this.mainData;
          for (let i of resN.d.l) {
            let data = this.assemblyData(i, resN.d.lan);
            mainData.push(data);
          }
          // 将半图组件组装成瀑布流
          mainData = this.initMasonry(mainData);
          this.mainData = mainData;
        }
      }
    },
    // 设置当前页面的语言
    setLan(lan) {
      if (lan && lan !== "") {
        this.locale = lan;
      }
    },
    // 设置内容部分的底部内边距，用于留言组件
    setBottomPadding() {
      let query = uni.createSelectorQuery().in(this);
      query.select(".typeChatInfo").boundingClientRect();
      query.exec(data => {
        if (data[0] && data[0].height) {
          this.mainPaddingBottom = data[0].height;
        } else {
          this.mainPaddingBottom = 0;
        }
      });
    },
    // 初始化瀑布流
    initMasonry(data) {
      let tmpData = [];
      let masonry = {
        d: [],
        t: "halfImgList"
      };
      for (let i = 0; i < data.length; i++) {
        if (data[i].t === "halfImg") {
          if (tmpData[tmpData.length - 1] && tmpData[tmpData.length - 1].t === "halfImgList") {
            tmpData[tmpData.length - 1].d.push(data[i]);
          } else {
            masonry.d.push(data[i]);
            if (i === data.length - 1) {
              if (masonry.d.length) {
                tmpData.push(masonry);
              }
            }
          }
        } else if (data[i].t !== "halfImg") {
          if (masonry.d.length) {
            tmpData.push(masonry);
            masonry = {
              d: [],
              t: "halfImgList"
            };
          }
          tmpData.push(data[i]);
        } else {
        }
      }
      return tmpData;
    },
    refreshPage() {
      setTimeout(() => {
        this.nextPageParams = {};
        this.newsParam = this.formdata;
        this.newsParam.d.pageIndex = 1;
        this.newsParam.d.pageSize = 20;
        console.log(this.newsParam, "par");
        this.getNewsList(this.newsParam, "up");
      }, 30);
    },
		// 切换导航样式
		scroll(e) {
				this.$emit('scroll', e)
		    if (e.detail.scrollTop <= 100) {
		        this.op = 1 - e.detail.scrollTop / 100
		        this.rgbaOP = 'rgba(255, 255, 255, ' + e.detail.scrollTop / 100 + ')'
		    } else {
		        this.op = 0
		        this.rgbaOP = 'rgba(255, 255, 255, 1)'
		    }
		},
    // 初始化页面
    initPage() {
      if (this.pageTitle) {
        uni.setNavigationBarTitle({
          title: this.pageTitle
        });
      }

      let m = this.newsParam.m;
      // // 产品详情和展位详情判断是否有切换过语言的标识
      let proLan = uni.getStorageSync("__setProdLan");
      // 设置当前页面的语言
      if (this.newsParam && this.newsParam.d && this.newsParam.d.lan && this.newsParam.d.lan !== "") {
        if (
          m &&
          (m === "kuaibao.onlineExpo.getOlineBoothHomePageInfo" ||
            m === "kuaibao.onlineExpo.getProductDetailAndComments")
        ) {
          if (proLan && proLan !== "" && ["en", "zh"].some(item => item === proLan)) {
            this.setLan(proLan);
          } else {
            this.setLan(this.newsParam.d.lan);
          }
        } else {
          this.setLan(this.newsParam.d.lan);
        }
      } else {
        let m = this.newsParam.m;
        // m === 'kuaibao.onlineExpo.getOlineBoothHomePageInfo' || m === 'kuaibao.onlineExpo.getProductDetailAndComments
        if (m === "kuaibao.onlineExpo.getProductDetailAndComments") {
          if (proLan && proLan !== "" && ["en", "zh"].some(item => item === proLan)) {
            this.setLan(proLan);
          }
        }
      }

      if (this.pageId) {
        let _this = this;
        uni.$on("refresh_" + this.pageId, function(data) {
          console.log("this.pageId", this.pageId, data);
          console.log("监听到事件来自 pageRefresh ，携带参数 msg 为：" + data.msg);
          _this.refreshPage();
        });
      }
      if (this.newsParam.m && this.newsParam.d) {
        this.getNewsList(this.newsParam, "up");
      }
      this.clientWidth = getApp().globalData.systemInfo.windowWidth;
    }
  },
  beforeDestroy() {
    // 移除所有监听事件
    uni.$off("refresh_" + this.pageId);
    uni.$off(`addChatMessage_${this.pageId}`);
  },
  created() {
    this.newsParam = this.formdata;
    this.initPage();
  }
};
</script>

<style scoped scoped>
.box {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.box-content {
  flex: 1;
  overflow: auto;
}
.cu-bar.fixed,
.nav.fixed {
  box-shadow: none;
}

.topFix {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
  padding: 0 6px;
}

.topLift {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.topRight {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
}

.pageTitle {
  flex: 1;
  line-height: 99upx;
  text-align: center;
  max-width: 80%;
  margin: 0 auto;
}

.top2 {
  background-color: #fff;
}

.topFix .topimage,
.topLift .topimage,
.topRight .topimage {
  width: 20px;
  height: 20px;
  display: inline-block;
  padding: 15px 10px;
  box-sizing: content-box;
  /* margin: 4% 0; */
}

.barList {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  /* background: #f00; */
  overflow: hidden;
  z-index: 2;
}

.topNav {
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  /* height: 40px; */
  /* background: #c2ffa9; */
  overflow: hidden;
}

.footerNav {
  flex: none;
  /* position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden; */
}

.main {
  position: absolute;
  top: 0px;
  /* background: #0079FF; */
  left: 0;
  right: 0;
  bottom: 0px;
  overflow: auto;
}

/*聊天组件*/
.typeChatInfo {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: rgb(246, 246, 246);
  z-index: 100;
  padding: 16upx 49upx 16upx 30upx;
}

.waterfall {
  width: 48%;
  float: left;
  background: #f0f0f0;
  margin: 1%;
  overflow: hidden;
  border-radius: 5px;
  z-index: 10;
}

/* 收藏关注区域 */
.showFavorite {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 101;
}

.showFavoriteMain {
  background: #fff;
  position: absolute;
  right: 10px;
  top: 50px;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 1px 0px 12px 0px rgba(0, 0, 0, 0.1);
}

.showFavoriteMain .FavorItem:nth-child(n + 2) {
  margin-top: 30rpx;
}

.showFavoriteMain:after {
  content: "";
  border-top: solid 0px #00800000;
  border-left: solid 5px #00800000;
  border-right: solid 5px #00800000;
  border-bottom: solid 8px white;
  position: absolute;
  top: -8px;
  right: 7px;
}

.iconImg {
  width: 40upx;
  height: 40upx;
  display: inline-block;
  vertical-align: top;
  margin-right: 5px;
  /*background-color: #999;*/
  border-radius: 50px;
  overflow: hidden;
}
</style>
