<template>
	<view class="posR padding-sm" style="background-color: #ebebeb;">
		<view class="bg-white padding-sm borderR5 flex">
			<image v-if ="newData.d.logo" class="logo bg-img margin-right-sm" :src="newData.d.logo" mode="widthFix"></image>
			<image v-else class="logo bg-img margin-right-sm" src="http://static.jiuzhouauto.com/h5_image/info.png" mode="widthFix"></image>
			<view class="flex-sub">
				<view class="text-black margin-top">{{newData.d.shortName}}</view>
				<view class="text-gray text-xs">{{$t('entFavour.粉丝数')}}{{newData.d.favNum}}</view>
			</view>
			<view>
				<text v-if="!thegz" class="margin-top gzBtn" @tap="toPage()">+{{$t('entFavour.关注')}}</text>
				<text v-else class="margin-top gzBtn btn2" @tap="toPage('del')">{{$t('entFavour.已关注')}}</text>
			</view>
		</view>
	</view>
</template>

<script>
  import VueI18n from "vue-i18n";
	export default {
		behaviors:['uni://form-field'],
		props: {
			formdata: {
				type: Object,
				default: {}
			}
		},
		data () {
			return {
				newData: [],
				thegz: false
			}
		},
    watch: {
      followed(val) {
        if (val) {
          this.thegz = val
        }
      }
    },
		methods:{
			async toPage(obj){
				let redM = ''
				if(obj){
					// 关注-取消关注
					redM = 'kuaibao.personal.delFavorite'
				}else{
					// 未关注-关注
					redM = 'kuaibao.personal.saveFavorite'
				}
				let res = await this.$util.api(redM,{
					'favId': this.newData.d._id,
					'favType': 3
				});
				if(res.c == '200' || res.c == '400'){
					uni.showToast({
						title: res.t,
						icon: 'none',
						duration: 500
					});
					if(obj){
						// 关注-取消关注
						this.thegz = false
					}else{
						// 未关注-关注
						this.thegz = true
					}
				}
			},
			// 判断用户是否收藏了当前页面或id数据接口
			async getFavorite() {
        let token = uni.getStorageSync('token') || ''
        if (token && token !== '') {
          try {
            let res = await this.$util.api('kuaibao.personal.getUserFavorite',{
              'favId': this.newData.d._id,
              'favType': 3
            });
            if (res && res.c =='200') {
              if(res.d.favorite == '1'){
                this.thegz = true
              }else{
                this.thegz = false
              }
            }
          } catch (e) {
          }
        }
			},

		},
		created() {
      // if (this.locale && this.locale !== '') {
      //   this.language = new VueI18n({
      //     locale: this.locale,
      //     messages: {
      //       en: require('@/common/i18n/language/en.json'),
      //       zh: require('@/common/i18n/language/zh.json')
      //     }
      //   })
      // }
			this.newData = this.formdata
			this.getFavorite()
		}
	}
</script>

<style scoped scoped>
	.logo{
		width: 50px;
		height: 50px;
		border-radius: 50px;
		overflow: hidden;
	}
	.gzBtn{
		background: #f00;
	    border-radius: 50px;
	    color: #fff;
	    width: 130upx;
	    display: inline-block;
	    text-align: center;
	    height: 28px;
	    line-height: 28px;
	}
	.btn2{
		background: #c2c2c2;
	}
</style>
