<template>
  <view class="counter">
    <view class="counter-inner">
      {{$t("artical.当前已有")}}
      <view class="counter-num" v-for="(item, idx) in counterNum" :key="idx">{{ item }}</view>
      {{$t("artical.次阅读")}}
    </view>
  </view>
</template>

<script>
export default {
  props: {
    formdata: {
      type: Object,
      default: {}
    }
  },
  computed: {
    counterNum() {
      let num = this.formdata.d.count + "";
      return num.split("");
    }
  }
};
</script>

<style lang="scss" scoped scoped>
.counter {
  width: 100%;
  padding: 20upx 30upx;
  .counter-inner {
    text-align: center;
    background: #ffffff;
    border: 1px solid #1cc053;
    border-radius: 10upx;
    height: 55upx;
    color: #fd8507;
    text-align: center;
    line-height: 55upx;
    .counter-num {
      display: inline-block;
      width: 29upx;
      height: 35upx;
      background: #fe8200;
      border-radius: 5upx;
      color: #fff;
      line-height: 35upx;
      margin-right: 3px;
      text-align: center;
      vertical-align: 1px;
      &:first-child {
        margin-left: 3px;
      }
    }
  }
}
</style>
