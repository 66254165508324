<template>
<view class="type-purchase-quote">
  <view class="date-add text-center">{{new Date(quotationData.createdAt).format('yyyy-MM-dd HH:mm:ss')}}</view>
  <view class="purchase-quote-info app-flex app-flex-fs-fs padding-left">
    <view class="avatar">
      <image :src="quotationData.headimgurl" mode="widthFix" />
    </view>
    <view class="info">
      <view class="nickname">{{quotationData.nickname}}</view>
      <view class="content posR">{{quotationData.content}}</view>
    </view>
  </view>
</view>
</template>

<script>
export default {
  props: {
    quotationData: {
      headimgurl: '',
      nickname: '',
      content: ''
    }
  }
}
</script>

<style lang="scss" scoped scoped>
.type-purchase-quote {
  padding: 40upx 0;
  background-color: #F5F5F5;
  .date-add {
    color: #C4C4C4;
  }
  .purchase-quote-info {
    margin-top: 37upx;
    .avatar {
      width: 95upx;
      height: 95upx;
    }
    .info {
      margin-left: 36upx;
      .nickname {
        color: #8A8A8A;
        font-weight: 400;
        font-size: 26upx;
      }
      .content {
        padding: 20upx;
        margin-top: 21upx;
        max-width: calc(70vw);
        border-radius: 5upx;
        background-color: #FFFFFF;
        color: #000000;
        display: -webkit-box;
        font-size: 28upx;
        line-height: 42upx;
        word-break: normal;
        white-space: pre-wrap;
        display: inline-block;
        word-wrap: break-word;
        user-select: auto;
          &:before {
            position: absolute;
            top: 5px;
            left: -6px;
            content: "";
            border-top: solid 5px transparent;
            border-left: solid 0 transparent;
            border-right: solid 6px #FFFFFF;
            border-bottom: solid 5px transparent;
          }
        }
      }
  }
}
</style>
