<template>
  <view class="textBoxContainer" :style="[textBoxStyle]" @click="action(app)">
    <view v-for="(item, index) in itemList" :key="index" :style="[item.style]">{{item.text || ''}}</view>
  </view>
</template>

<script>
export default {
  props: {
    formdata: {
      type: Object,
      default: {
        d: {
          item: []
        },
        s: {}
      }
    }
  },
  data() {
    return {
      itemList: [],
      winWidth: getApp().globalData.systemInfo.windowWidth,
      totalWidth: 0,
      app: ''
    }
  },
  computed: {
    textBoxStyle() {
      let style = {}
      if (this.formdata.s.height) {
        style.height = `${this.winWidth * this.formdata.s.height}px`
      } else {
        style.height = 0
      }
      if (this.formdata.s.background && this.formdata.s.background !== '') {
        style.backgroundImage = `url(${this.formdata.s.background})`
      }
      return style
    }
  },
  methods: {
    async action(obj) {
      let a = await this.$util.gotoApp({type: 1, app: obj})
      if (a) {
        await this.$util.gotoPage(a)
      } else {
        if (obj && obj !== '') getApp().globalData.appLocation.href = obj
      }
    },
    gStyle(data) {
      let style = {}
      if (this.formdata.d.item && this.formdata.d.item.length) {
        style.color = data.color || '#000000'
        style.width = ((data.width / this.totalWidth) * this.winWidth) + 'px'
        // sp*ppi/160 = px
        style.fontSize = ((data.size?data.size:30) / 750) * this.winWidth - 1
        // 单行的
        if (data.maxLines && parseInt(data.maxLines) === 1) {
          style.lineHeight = style.fontSize + 1 + 'px'
          style.height = style.fontSize * parseInt(data.maxLines) + 'px'
          style.overflow = 'hidden'
          // 是否显示省略号, 否则直接截断
          if (data.ellipsis && parseInt(data.ellipsis) === 1) {
            style.overflow = 'hidden'
            style.textOverflow = 'ellipsis'
            style.whiteSpace = 'nowrap'
          }
        }
        // 多行
        else if (data.maxLines && parseInt(data.maxLines) > 1) {
          style.overflow = 'hidden'
          style.lineHeight = style.fontSize + 1 + 'px'
          style.height = style.fontSize * parseInt(data.maxLines) + 'px'
          // 是否显示省略号, 否则直接截断
          if (data.ellipsis && parseInt(data.ellipsis) === 1) {
            style.display = '-webkit-box'
            style['-webkit-box-orient'] = 'vertical'
            style['-webkit-line-clamp'] = data.maxLines
          }
        }
        if (!data.text || data.text === '') {
          style.height = '100%'
        }
				style.fontSize += 'px'
      }
      return style
    },
    init() {
      let width = 0
      let itemList = this.formdata.d.item
      itemList.forEach((item, index) => {
        if (item.width) {
          width += item.width
        }
        if (item.app) {
          this.app = item.app
        }
      })
      this.totalWidth = width
      itemList.map(item => {
        item.style = this.gStyle(item)
        return item
      })
      this.itemList = itemList
      Object.freeze(this.itemList)
    }
  },
  created() {
  },
  watch:{
    "formdata.d.item":{
      handler() {
       this.init()
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped scoped>
.textBoxContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-size: cover;
  background-position: left center;
}
</style>
