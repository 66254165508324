<template>
  <view class="wrap">
    <!-- 小图区域-->

    <scroll-view v-if="!isPc" scroll-x show-scrollbar>
      <view :style="{height:imgStyle.height * 2 + 'px'}" class="imgContainer">
<!--        <view class="item" v-for="(item,index) in imgType1List" :key="index" @click="previewImage(index)">-->
<!--            <image class="item-img" :src="item.poster" :style="{width: imgStyle.width * 2+'upx',height:imgStyle.height*2+'upx'}" mode="aspectFill" />-->
<!--        </view>-->
        <template>
          <image
                  :style="{width:imgStyle.width * 2 + 'px', height: imgStyle.height* 2 + 'px'}"
                  class="item"
                  v-for="(item,index) in imgType1List"
                  :key="index"
                  :src="item.poster" @click="previewImage(index)"
                  mode="scaleToFill">
          </image>
        </template>
      </view>
    </scroll-view>

		<!-- #ifndef MP-WEIXIN -->
    <PcScrollBar v-else :style="{height:imgStyle.height * 2 + 'px'}">
      <PcScrollBarItem v-for="(item,index) in imgType1List" :key="index">
        <image
                :style="{width: imgStyle.width * 2 + 'px', height: imgStyle.height * 2 + 'px'}"
                lazy-load
                :src="item.poster"
                mode="scaleToFill"
                @click="previewImage(index)"
        />
      </PcScrollBarItem>
    </PcScrollBar>
		<!-- #endif -->
  </view>
</template>

<script>
  import {isPcBroswer} from "@/common/tools/system";
	// #ifndef MP-WEIXIN
  import PcScrollBar from "@/components/common/PcScrollBar";
  import PcScrollBarItem from "@/components/common/PcScrollBarItem"
	// #endif
  export default {
    name: "TypeBoothCarousel",
    components: {
			// #ifndef MP-WEIXIN
      PcScrollBar,
      PcScrollBarItem
			// #endif
    },
    props: {
      formdata: {
        type: Object,
        default: {}
      }
    },
    data() {
      return {
        current: 0,
        imgType1List: [], // 列表图
        imgType2List: [], // 大图
        isPc: isPcBroswer()
      };
    },
    computed: {
      imgStyle() {
        let width = getApp().globalData.systemInfo.windowWidth * this.formdata.s.height / this.formdata.s.ratio
        let height = getApp().globalData.systemInfo.windowWidth * this.formdata.s.height
        return {
          width: uni.upx2px( width),
          height: uni.upx2px(height)
        }
      }
    },
    watch: {},
    methods: {
      async getImgType1Data(type = 1) {
        let res = await this.$util.api('kuaibao.onlineExpo.getOlineBoothPosterList', {
          type: type, // 1获取小图数组，2获取全屏大图列表。为2的时候出现：app、展位号、公司名
          showroom: this.formdata.d.showroom
        })
        if (res && res.c === 200) {
          if (type === 1) {
            this.imgType1List = res.d.boothList
            Object.freeze(this.imgType1List)
          } else {
            this.imgType2List = res.d.boothList
            Object.freeze(this.imgType2List)
          }
        }
      },
      async previewImage(index) {
        if (!this.imgType2List.length) {
          await this.getImgType1Data(2)
        }
        this.imgType1List.forEach(img1 => {
          this.imgType2List.map(img2 => {
            if (img2._id === img1._id) {
              img2.posterPreview = img1.poster
            }
            return img2
          })
        })
        uni.setStorageSync('__booth_carousel_img', JSON.stringify(this.imgType2List))
        if (this.imgType2List.length) {
          uni.navigateTo({
            url: `/pages/booth_carousel_preview/index?showroom=${this.formdata.d.showroom}&current=${index}`
          })
        }
      }
    },
    created() {
      this.getImgType1Data()
    }
  }
</script>

<style lang="scss" scoped scoped>
.wrap {
  margin-bottom: 10upx;
}
  .imgContainer {
    display: inline-flex;

    .item {
      &:nth-child(n+1) {
        margin-right: 10upx;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
</style>
