import Vue from 'vue';
import Main from "./main";

let ModalConstructor = Vue.extend(Main)
let instance

const LaunchWeappModal = (options) => {
  instance = new ModalConstructor()
  for(let prop in options) {
    if (options.hasOwnProperty(prop)) {
      instance[prop] = options[prop]
    }
  }
  console.log(options)
  instance.$mount()
  document.body.appendChild(instance.$el);
  instance.visible = true
}

export default LaunchWeappModal

