var components;
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-uni-view',[(_vm.picData.d.img && parseInt(_vm.picData.d.type) != 4)?_c('v-uni-view',{staticClass:"type-halfImgItem",on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.toPage(_vm.picData)
}}},[_c('v-uni-view',{staticClass:"type-halfImgItemWarp"},[_c('v-uni-view',{staticClass:"imgBg1Block",style:({ height: _vm.imgHeight })},[_c('lazy-load',{staticClass:"imgBg1",attrs:{"image":_vm.picData.d.img,"borderTopRadius":8,"height":_vm.imgHeight,"loading-img":"http://static.jiuzhouauto.com/h5_image/loading_squ.jpg","error-img":"http://static.jiuzhouauto.com/h5_image/loading_failed_squ.jpg","img-mode":"aspectFill"},on:{"load":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.loadFinish).apply(void 0, arguments)
}}})],1),(parseInt(_vm.picData.d.type) !== 2)?_c('v-uni-view',[(
            parseInt(_vm.picData.d.type) === 3 &&
              (_vm.picData.d.title || _vm.picData.d.desc || _vm.picData.d.logo || _vm.picData.d.company)
          )?_c('v-uni-view',{staticClass:"text-black"},[(_vm.picData.d.title)?_c('v-uni-view',{staticClass:"halfImg-title text-justify padding-tb-xs text-bold text-cut",staticStyle:{"line-height":"60upx"}},[_vm._v(_vm._s(_vm.picData.d.title))]):_vm._e(),(_vm.picData.d.desc)?_c('v-uni-view',{staticClass:"text-justify text-sm over-hidden",staticStyle:{"height":"64upx","-webkit-box-orient":"vertical","-webkit-line-clamp":"2","display":"-webkit-box"}},[_vm._v(_vm._s(_vm.picData.d.desc))]):_vm._e(),(_vm.picData.d.logo || _vm.picData.d.company)?_c('v-uni-view',{staticClass:"flex align-center padding-tb-xs"},[(_vm.showLogo)?_c('v-uni-image',{staticClass:"logo",attrs:{"src":_vm.picData.d.logo,"mode":""}}):_vm._e(),(_vm.picData.d.company)?_c('v-uni-view',{staticClass:"text-xs text-cut text-gray"},[_vm._v(_vm._s(_vm.picData.d.company))]):_vm._e()],1):_vm._e()],1):_vm._e(),_c('v-uni-view',{staticClass:"tag",style:({ bottom: parseInt(_vm.picData.d.type) === 3 ? '38%' : '24%' })},[(_vm.picData.d.redtag)?_c('v-uni-view',{staticClass:"btn btn1"},[_vm._v(_vm._s(_vm.picData.d.redtag))]):(_vm.picData.d.tag)?_c('v-uni-view',{staticClass:"btn btn2"},[_vm._v(_vm._s(_vm.picData.d.tag))]):_vm._e()],1)],1):_vm._e(),(parseInt(_vm.picData.d.type) === 2)?_c('v-uni-view',[_c('v-uni-view',{staticClass:"halfImg-title text-justify padding-sm text-bold text-cut",style:(_vm.titleStyle)},[_vm._v(_vm._s(_vm.picData.d.title))]),(_vm.picData.d.redtag || _vm.picData.d.tag)?_c('v-uni-view',{staticClass:"footer"},[(_vm.picData.d.redtag)?_c('v-uni-view',{staticClass:"btn btn1"},[_vm._v(_vm._s(_vm.picData.d.redtag))]):(_vm.picData.d.tag)?_c('v-uni-view',{staticClass:"btn btn2"},[_vm._v(_vm._s(_vm.picData.d.tag))]):_vm._e()],1):_vm._e()],1):_vm._e()],1)],1):_vm._e(),(_vm.picData.d.img && parseInt(_vm.picData.d.type) == 4)?_c('v-uni-view',{staticClass:"type-halfImgItemWarp3",on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.toPage(_vm.picData)
}}},[_c('v-uni-view',{staticClass:"imgBg1Block",style:({ height: _vm.imgHeight })},[_c('lazy-load',{staticClass:"imgBg1",attrs:{"image":_vm.picData.d.img,"borderTopRadius":8,"height":_vm.imgHeight,"loading-img":"http://static.jiuzhouauto.com/h5_image/loading_squ.jpg","error-img":"http://static.jiuzhouauto.com/h5_image/loading_failed_squ.jpg","img-mode":"aspectFill"},on:{"load":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.loadFinish).apply(void 0, arguments)
}}})],1),_c('v-uni-view',[(_vm.picData.d.title || _vm.picData.d.desc || _vm.picData.d.logo || _vm.picData.d.company)?_c('v-uni-view',{staticClass:"text-black"},[(_vm.picData.d.title)?_c('v-uni-view',{staticClass:"halfImg-title text-justify padding-tb-xs text-cut",staticStyle:{"line-height":"60upx"}},[_vm._v(_vm._s(_vm.picData.d.title))]):_vm._e(),(_vm.picData.d.desc)?_c('v-uni-view',{staticClass:"text-justify text-sm over-hidden",staticStyle:{"height":"64upx","-webkit-box-orient":"vertical","-webkit-line-clamp":"2","display":"-webkit-box"}},[_vm._v(_vm._s(_vm.picData.d.desc))]):_vm._e(),(_vm.picData.d.logo || _vm.picData.d.company)?_c('v-uni-view',{staticClass:"flex align-center padding-tb-xs"},[(_vm.showLogo)?_c('v-uni-image',{staticClass:"logo",attrs:{"src":_vm.picData.d.logo,"mode":""}}):_vm._e(),(_vm.picData.d.company)?_c('v-uni-view',{staticClass:"text-xs text-cut text-gray"},[_vm._v(_vm._s(_vm.picData.d.company))]):_vm._e()],1):_vm._e()],1):_vm._e(),_c('v-uni-view',{staticClass:"tag",style:({ bottom: parseInt(_vm.picData.d.type) === 3 ? '38%' : '24%' })},[(_vm.picData.d.redtag)?_c('v-uni-view',{staticClass:"btn btn1"},[_vm._v(_vm._s(_vm.picData.d.redtag))]):(_vm.picData.d.tag)?_c('v-uni-view',{staticClass:"btn btn2"},[_vm._v(_vm._s(_vm.picData.d.tag))]):_vm._e()],1)],1)],1):_vm._e()],1)}
var recyclableRender = false
var staticRenderFns = []

export { render, staticRenderFns, recyclableRender, components }