var components;
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.swiperList.s.height)?_c('v-uni-view',{style:([{
      'background-image': 'url(' + _vm.swiperList.s.background + ')',
      'background-size': 'cover',
      'background-repeat': 'no-repeat',
      'height': _vm.carouselH + 'px'}]),attrs:{"id":_vm.carouselId}},[_c('v-uni-swiper',{class:_vm.dotStyle ? 'square-dot' : 'round-dot',style:([{ height: _vm.carouselH + 'px' , '--indicator-bottom': _vm.indicatorBottom + 'px' }]),attrs:{"id":_vm.videoId,"indicator-dots":_vm.swiperList.d.item && _vm.swiperList.d.item.length > 1,"circular":true,"autoplay":_vm.autoplay,"interval":_vm.swiperList.s.interval||5000,"duration":"500"},on:{"animationfinish":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.finishSwipe).apply(void 0, arguments)
},"change":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.changeSwipe).apply(void 0, arguments)
},"transition":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.transitionAction).apply(void 0, arguments)
}}},_vm._l((_vm.swiperList.d.item),function(item,index){return _c('v-uni-swiper-item',{key:index,on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.carouselTo(item, index)
}}},[_c('v-uni-view',{staticClass:"warp",style:({
              width: '100%',
              height: '100%'})},[(item.video)?[(_vm.playingId !== index)?_c('v-uni-view',{staticClass:"play"}):_vm._e(),_c('v-uni-view',{staticClass:"muted",class:{ noMuted: _vm.isMuted },on:{"click":function($event){$event.stopPropagation();
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.handleToggleMuted).apply(void 0, arguments)
}}}),(_vm.autoplay)?_c('v-uni-image',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":item.img,"mode":"aspectFit"}}):_vm._e(),_c('v-uni-video',{key:_vm.videoKey,staticStyle:{"width":"100%","height":"100%"},attrs:{"id":_vm.videoId + index,"controls":false,"autoplay":false,"muted":_vm.isMuted,"show-center-play-btn":false,"src":item.video},on:{"play":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.onPlay(index)
},"pause":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.onPause(index)
}}})]:[(!item.error)?_c('v-uni-image',{key:_vm.refreshKey,staticStyle:{"width":"100%","height":"100%"},attrs:{"src":item.isShow ? item.img : 'http://static.jiuzhouauto.com/h5_image/loading_hori.jpg',"mode":"aspectFit"}}):_c('v-uni-image',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":"http://static.jiuzhouauto.com/h5_image/loading_hori.jpg","mode":"aspectFit"}})]],2)],1)}),1)],1):_vm._e()}
var recyclableRender = false
var staticRenderFns = []

export { render, staticRenderFns, recyclableRender, components }