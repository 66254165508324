var components;
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-uni-view',{staticClass:"agents-block"},[_c('v-uni-view',{staticClass:"head app-flex app-flex-sb-c"},[_c('v-uni-view',{staticClass:"title text-black"},[_vm._v(_vm._s(_vm.formdata.d.title))]),(_vm.formdata.d.type === 2)?_c('v-uni-view',{staticClass:"location app-flex app-flex-fs-c"},[_c('v-uni-image',{staticStyle:{"width":"21upx","height":"29upx"},attrs:{"src":"http://static.jiuzhouauto.com/h5_image/agent/location.png"}}),_c('v-uni-text',{staticClass:"margin-left-xs",on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.switchProvince).apply(void 0, arguments)
}}},[_vm._v(_vm._s(_vm.region.city[0] || ''))])],1):_vm._e()],1),_c('v-uni-view',{staticClass:"content",class:[_vm.formdata.d.type === 1 ? 'no-data':'']},[(_vm.formdata.d.type === 1)?[_c('v-uni-view',{staticClass:"agent-item"},[_c('v-uni-view',{staticClass:"company text-df"},[_vm._v("我是经销商，我想展示…")]),_c('v-uni-view',{staticClass:"phone-btn text-center",on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.call(_vm.formdata.d.tel)
}}},[_vm._v("电话咨询")])],1)]:_vm._e(),(_vm.formdata.d.type === 2)?_vm._l((_vm.itemList),function(item){return _c('v-uni-view',{key:item.id,staticClass:"agent-item app-flex app-flex-sb-c"},[_c('v-uni-view',{staticClass:"company"},[_vm._v(_vm._s(item.text))]),_c('v-uni-view',{staticClass:"phone-btn text-center",on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.call(item.tel)
}}},[_vm._v("电话咨询")])],1)}):_vm._e()],2),_c('c-popup',{attrs:{"safe-area-inset-bottom":true,"mode":"bottom","border-radius":"20"},model:{value:(_vm.showPopup),callback:function ($$v) {_vm.showPopup=$$v},expression:"showPopup"}},[_c('v-uni-view',{staticClass:"province-head"},[_vm._v("经销商所在省份"),_c('v-uni-image',{staticClass:"close-btn",attrs:{"src":"http://static.jiuzhouauto.com/h5_image/agent/close.png"},on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.closeProvince).apply(void 0, arguments)
}}})],1),_c('v-uni-scroll-view',{staticClass:"province-list",attrs:{"scroll-y":true}},_vm._l((_vm.provinceList),function(item,index){return _c('v-uni-view',{key:index,staticClass:"province-item-warp"},[_c('v-uni-view',{staticClass:"province-item",class:[index === _vm.provinceActiveIndex ? 'active' : ''],on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.selectProvince(index)
}}},[_vm._v(_vm._s(item.city))])],1)}),1)],1)],1)}
var recyclableRender = false
var staticRenderFns = []

export { render, staticRenderFns, recyclableRender, components }