<template>
  <view>
    <view :style="outBoxStyle" class="scroll-bar">
      <view
        @mouseleave="handleUp"
        @mousedown="handleDown"
        @mousemove="handleMove"
        @mouseup="handleUp"
        v-if="isPc"
        :id="girdId + 'box'"
        :style="innerStyle"
        scroll-x
        @scroll="handleScroll"
        class="scroll-box"
      >
        <template v-for="(page, idx) in renderList">
          <view class="item-box" :class="'gird-' + girdId" :key="`page-${idx}`">
            <view class="scroll-item">
              <template v-for="(col, colIdx) in page">
                <view class="scroll-col" :key="`col-${colIdx}-${idx}`">
                  <template v-for="(row, rowIdx) in col"><view class="scroll-row" :key="`row-${colIdx}-${idx}-${rowIdx}`" @click="() => gridTo(row, rowIdx)">
                      <view class="row-img-box" :style="'background-image:url(' + row.img + ')'"></view>
                    </view></template>
                </view>
              </template>
            </view>
          </view>
        </template>
      </view>
      <scroll-view v-else :id="girdId + 'box'" :style="innerStyle" scroll-x @scroll="handleScroll" class="scroll-box">
        <template v-for="(page, idx) in renderList">
          <view class="item-box" :class="'gird-' + girdId" :key="`page-${idx}`">
            <view class="scroll-item">
              <template v-for="(col, colIdx) in page"><view class="scroll-col" :key="`col-${colIdx}-${idx}`">
                  <template v-for="(row, rowIdx) in col">
                    <view class="scroll-row" :key="`row-${colIdx}-${idx}-${rowIdx}`" @click="() => gridTo(row, rowIdx)">
                      <view class="row-img-box" :style="'background-image:url(' + row.img + ')'"></view>
                    </view>
                  </template>
                </view></template>
            </view>
          </view>
        </template>
      </scroll-view>
      <view class="scroll-tips-bar" v-if="renderList.length > 1">
        <view class="tips-bar">
          <view class="tips-bar-inner" :style="[{ width: barWidth, left: barLeft }]"></view>
        </view>
      </view>
    </view>
  </view>
</template>

<script>
import { isPcBroswer } from "@/common/tools/system";

import { chunk } from "lodash-es";
import { uuid } from "@/common/util";
export default {
  behaviors: ["uni://form-field"],
  props: {
    formdata: {
      type: Object,
      default() {
        return {
          d: {
            item: []
          },
          s: {
            height: 0,
            background: ""
          }
        };
      }
    }
  },
  data: () => ({
    isPc: isPcBroswer(),
    girdId: uuid(),
    barWidth: "0%",
    boxWidth: 0,
    pageNum: 0,
    barLeft: 0,
    isStart: false,
    move: 0,
    startX: 0,
    scrollLeft: 0,
    lastX: 0
  }),
  computed: {
    backgroundImage() {
      if (this.isShow) {
        return this.formdata.s.background || "";
      }
      return "";
    },
    gridH() {
      return (getApp().globalData.systemInfo.windowWidth * this.formdata.s.height) + this.formdata.s.marginTop + this.formdata.s.marginBottom;
    },
    outBoxStyle() {
      let str = `height:${this.gridH}px;background-image:url(${this.formdata.s.background});`;
      str += `padding: ${this.formdata.s.marginTop}rpx ${this.formdata.s.marginRight}rpx ${this.formdata.s.marginBottom}rpx ${this.formdata.s.marginLeft}rpx;`;
      return str;
    },
    innerStyle() {
      const { radiusBottomLeft, radiusBottomRight, radiusTopLeft, radiusTopRight } = this.formdata.s;
      let str = `border-radius:${radiusTopLeft}rpx; ${radiusTopRight}rpx ${radiusBottomRight}rpx ${radiusBottomLeft}rpx`;
      return str;
    },
    renderList() {
      return this.sliceList({
        list: this.formdata.d.item,
        row: this.formdata.s.rows,
        col: this.formdata.s.columns
      });
    },
    typeNumber() {
      if (this.formdata.footerType) {
        return this.formdata.footerType;
      }
      return 4;
    }
  },
  methods: {
    handleDown(e) {
      this.isStart = true;
      this.lastX = e.clientX;
    },
    handleUp() {
      this.isStart = false;
      this.lastX = 0;
    },
    handleMove(e) {
      let box = document.getElementById(this.girdId + "box");
      if (this.isStart) {
        this.scrollLeft += this.lastX - e.clientX;
        box.scrollTo({ left: this.scrollLeft });
        this.lastX = e.clientX;
      }
      this.barLeft = (box.scrollLeft / box.scrollWidth) * 100 + "%";
    },
    gridTo(e, index) {
      if (e.app) {
        this.toGetPage(e.app, index);
      } else if (e.h5) {
        getApp().globalData.appLocation.href = e.h5;
      } else {
        console.log("啥都没有的 不做操作");
      }
    },
    async toGetPage(obj, index) {
      let a = await this.$util.gotoApp({
        type: this.typeNumber,
        app: obj
      });
      if (a) {
        if (parseInt(this.typeNumber) === 1) {
          a.menuIndex = index + 1;
          this.$emit("clickBottomNav", a);
        } else {
          await this.$util.gotoPage(a, this.typeNumber);
        }
      } else {
        if (/^https?:\/\/(([a-zA-Z0-9_-])+(\.)?)*(:\d+)?(\/((\.)?(\?)?=?&?[a-zA-Z0-9_-](\?)?)*)*$/i.test(obj)) {
          getApp().globalData.appLocation.href = obj;
        }
      }
    },
    sliceList({ list = [], row = 1, col = 4 }) {
      let _list = JSON.parse(JSON.stringify(list));
      let pageNum = Math.ceil(list.length / (row * col));
      let fullLength = pageNum * row * col;
      this.pageNum = pageNum;
      let orLength = list.length;
      if (fullLength > orLength) {
        for (let i = 0; i < fullLength - orLength; i++) {
          _list.push({ id: Math.random() });
        }
      }
      // 分页
      let res = [];
      _list = chunk(_list, row * col);
      _list.forEach(item => {
        // 分行
        res.push(chunk(item, row));
      });
      return res;
    },
    handleScroll(e) {
      this.barLeft = (e.detail.scrollLeft / e.detail.scrollWidth) * 100 + "%";
      //   this.barWidth = (((e.detail.scrollLeft + this.boxWidth + 10) / e.detail.scrollWidth) * 100).toFixed(2) + "%";
    }
  },
  mounted() {
    this.$nextTick(() => {
      const query = uni.createSelectorQuery().in(this);
      query
        .select(".gird-" + this.girdId)
        .boundingClientRect(data => {
          if (data) {
            this.boxWidth = data.width;
            this.barWidth = ((1 / this.pageNum) * 100).toFixed(2) + "%";
          }
        })
        .exec();
    });
  }
};
</script>

<style lang="scss" scoped scoped>
.scroll-bar {
  width: 100%;
  height: 300upx;
  background-color: #eee;
  box-sizing: border-box;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  .scroll-box {
    overflow-x: auto;
    width: 100%;
    height: 100%;
    white-space: nowrap;
    .item-box {
      white-space: normal;
      word-break: break-all;
      width: 100%;
      height: 100%;
      display: inline-block;
    }
    .scroll-item {
      width: 100%;
      height: 100%;
      display: flex;

      .scroll-col {
        flex: 1;
        display: flex;
        flex-direction: column;
        .scroll-row {
          flex: 1;
          .row-img-box {
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
          }
        }
      }
    }
  }
}
.item-box {
  white-space: normal;
  word-break: break-all;
  width: 100%;
  height: 100%;
  display: inline-block;
}
.scroll-item {
  width: 100%;
  height: 100%;
  display: flex;
  .scroll-col {
    flex: 1;
    display: flex;
    flex-direction: column;
    .scroll-row {
      flex: 1;
      .row-img-box {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }
    }
  }
}
.scroll-tips-bar {
  position: absolute;
  bottom: 20rpx;
  left: 50%;
  transform: translate(-50%, 0);
}
.tips-bar {
  position: relative;
  margin: auto;
  width: 100upx;
  border-radius: 10upx;
  height: 20upx;
  background-color: #d4d8de;
  overflow: hidden;
  .tips-bar-inner {
    width: 0;
    height: 100%;
    position: absolute;
    background-color: #ff4439;
    top: 0;
    left: 0;
    border-radius: 10upx;
    transition: all 0.1s;
  }
}
</style>
