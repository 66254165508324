const checkWebp = async() => {
    return new Promise((resolve, reject) => {



        let status = uni.getStorageSync('webp');
        if (status) {
            resolve(status === 'support');
        }
        let img = new Image();
        img.onload = function() {
            uni.setStorageSync('webp', 'support');
            resolve(true);
        };
        img.onerror = function() {
            uni.setStorageSync('webp', 'unSupport');
            resolve(false);
        };
        img.src = 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=';
    });
}

export default checkWebp;