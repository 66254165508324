const pageIndex = 1
const pageSize = 10

const componentList = [ 'purchaseFilter' ]

const componentParams = {
  purchaseFilter: {
    t: 'purchaseFilter',
    m: 'kuaibao.purchase.getPurchaseList',
    d: {
      pageIndex,
      pageSize
    }
  }
}

export {componentList, componentParams}
