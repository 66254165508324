var components;
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-uni-view',{staticClass:"cu-modal",class:[_vm.visible ? 'show' : ''],on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.clickModal).apply(void 0, arguments)
}}},[_c('v-uni-view',{staticClass:"cu-dialog"},[_c('v-uni-view',{staticClass:"cu-bar bg-white justify-end"},[(_vm.title && _vm.title !== '')?_c('v-uni-view',{staticClass:"content"},[_vm._v(_vm._s(_vm.title))]):_vm._e(),_c('v-uni-view',{staticClass:"action"},[_c('v-uni-text',{staticClass:"cuIcon-close text-red",on:{"click":function($event){$event.stopPropagation();
arguments[0] = $event = _vm.$handleEvent($event);
_vm.handleAction('close')
}}})],1)],1),_c('v-uni-view',{staticClass:"padding-xl"},[_vm._v(_vm._s(_vm.content))]),_c('v-uni-view',{staticClass:"cu-bar bg-white justify-end"},[_c('v-uni-view',{staticClass:"action"},[(this.$type && this.$type !== 'alert')?_c('v-uni-button',{staticClass:"cu-btn line-green text-green",on:{"click":function($event){$event.stopPropagation();
arguments[0] = $event = _vm.$handleEvent($event);
_vm.handleAction('cancel')
}}},[_vm._v("取消")]):_vm._e(),_c('v-uni-button',{staticClass:"cu-btn bg-green margin-left",on:{"click":function($event){$event.stopPropagation();
arguments[0] = $event = _vm.$handleEvent($event);
_vm.handleAction('confirm')
}}},[_vm._v("确定")])],1)],1)],1)],1)}
var recyclableRender = false
var staticRenderFns = []

export { render, staticRenderFns, recyclableRender, components }