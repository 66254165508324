var components;
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-uni-view',{staticClass:"posR padding-sm",staticStyle:{"background-color":"#ebebeb"}},[_c('v-uni-view',{staticClass:"bg-white padding-sm borderR5 flex"},[(_vm.newData.d.logo)?_c('v-uni-image',{staticClass:"logo bg-img margin-right-sm",attrs:{"src":_vm.newData.d.logo,"mode":"widthFix"}}):_c('v-uni-image',{staticClass:"logo bg-img margin-right-sm",attrs:{"src":"http://static.jiuzhouauto.com/h5_image/info.png","mode":"widthFix"}}),_c('v-uni-view',{staticClass:"flex-sub"},[_c('v-uni-view',{staticClass:"text-black margin-top-lg"},[_vm._v(_vm._s(_vm.newData.d.shortName))]),_c('v-uni-view',{staticClass:"text-gray text-xs"},[(_vm.newData.d.favNum && parseInt(_vm.newData.d.favNum) > 0)?_c('v-uni-text',[_vm._v(_vm._s(_vm.$t('entFavour.粉丝数'))+" "+_vm._s(_vm.newData.d.favNum))]):_vm._e(),(_vm.newData.d.boothCode && _vm.newData.d.boothCode !== '')?_c('v-uni-text',{staticClass:"margin-left"},[_vm._v(_vm._s(_vm.$t('entFavour.展位号'))+" "+_vm._s(_vm.newData.d.boothCode))]):_vm._e()],1)],1),_c('v-uni-view',{staticClass:"flex align-center margin-right"},[(!_vm.thegz)?_c('v-uni-view',{staticClass:"collectItem margin-top-lg padding-left padding-right padding-bottom",on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.toPage()
}}},[_c('v-uni-image',{staticClass:"image",attrs:{"src":"http://static.jiuzhouauto.com/h5_image/collect.png","mode":"widthFix"}})],1):_c('v-uni-view',{staticClass:"collectItem margin-top-lg padding-left padding-right padding-bottom",on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.toPage('del')
}}},[_c('v-uni-image',{staticClass:"image",attrs:{"src":"http://static.jiuzhouauto.com/h5_image/collect_active.png","mode":"widthFix"}})],1)],1)],1)],1)}
var recyclableRender = false
var staticRenderFns = []

export { render, staticRenderFns, recyclableRender, components }