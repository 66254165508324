<template>
  <view class="swiper-item">
    <view class="item-inner">
      <view class="person-name">
        <view class="name"> {{ cardData.d.name || $t("entCard.personal.未填写姓名") }}</view>
        <view class="person-duties">{{
          (cardData.d.position || $t("entCard.personal.未填写职位")) | ellipsis(10)
        }}</view>
      </view>
      <view class="company">
        <view class="company-name">{{ cardData.d.company }}</view>
        <view class="company-job" v-if="cardData.d.concurrentPosition">{{cardData.d.concurrentPosition}}</view>
        <!-- <view class="qi" v-if="cardData.d.displayType === 2">企</view> -->
      </view>
      <view class="contact-msg">
        <view class="msg-item msg-mobile" v-if="cardData.d.mobile">{{ maskString(cardData.d.mobile) }}</view>
        <view class="msg-item msg-email" v-if="cardData.d.email">{{ cardData.d.email }}</view>
        <view class="msg-item msg-home" v-if="cardData.d.homepage">{{ cardData.d.homepage }}</view>
        <view class="msg-item msg-ad" v-if="addressString">{{ addressString }}</view>
      </view>
      <view class="btn-bar">
        <view class="btn" v-if="cardData.d.showroom || cardData.d.entBooth" @click.stop.prevent="goToAction(cardData.d)">{{
          $t("searchBooth.企业展厅")
        }}</view>
        <slot name="button"></slot>
        <!-- <view class="btn">交换名片</view> -->
      </view>
      <image
        :src="cardData.d.logo || 'http://static.jiuzhouauto.com/h5_image/bus_card/no_logo.png'"
        class="img"
        mode="widthFix"
      />
    </view>
    <view class="item-bar">
      <view class="bar1"></view>
      <view class="bar2"></view>
    </view>
    <view class="item-pic" v-if="cardData.d.nameCardImg && showNameCardImg">
      <image class="pic" :src="cardData.d.nameCardImg" mode="widthFix" />
    </view>
    <slot></slot>
  </view>
</template>

<script>
import { mapState } from "vuex";

export default {
  components: {},
  filters: {
    ellipsis(value, len) {
      if (!value) return "";
      if (!len) return value;
      if (value.length > len) {
        return value.slice(0, len) + "...";
      }
      return value;
    },
    handleHidePhone(value, isHandle) {
      if (!isHandle) return value;
      try {
        // return value.replace(/(\d{5})(\d{3})(\d{3})/,"$1***$3")
        return value.replace(/(.*)(.{3})(.{3})$/, "$1***$3");
      } catch (e) {}
      return value;
    },
    handleHideEmail(value, isHandle) {
      if (!isHandle) return value;
      try {
        if (value.indexOf("@") > 0) {
          let str = value.split("@");
          let _s = "";
          if (str[0].length > 3) {
            for (let i = 0; i <= str[0].length - 1; i++) {
              if (i === 0 || i === str[0].length - 1) {
                _s += str[0][i];
              } else {
                _s += "*";
              }
            }
          }
          return _s + str[1];
        } else {
          return value;
        }
      } catch (e) {}
      return value;
    }
  },
  props: {
    showNameCardImg: { type: Boolean, default: false },
    cardData: {
      type: Object,
      default() {
        return {
          d: {
            industry: {
              title: [],
              code: []
            }
          },
          s: {},
          t: "entCard"
        };
      }
    },
    hidePhone: {
      type: Boolean,
      default: false
    },
    hideEmail: {
      type: Boolean,
      default: false
    },
    hideNoneData: {
      //没有数据时是否显示提示信息
      type: Boolean,
      default: true
    },
    showEnt: {
      type: Boolean,
      default: true
    },
    isMyCard: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      mobileOrder: 6,
      emailOrder: 6,
      addressOrder: 6,
      outputAddress: "",
      outputCompany: ""
    };
  },
  watch: {
    cardData: {
      immediate: false,
      handler: function() {
        if (this.hideNoneData) this.orderInfo();
      },
      deep: true
    }
  },
  computed: {
    ...mapState({
      storeMyExCardId: state => state.BusinessCardModule.myExchangedCardId,
      storeMyCardId: state => state.BusinessCardModule.myCardId
    }),
    exchanged() {
      return this.storeMyExCardId.some(item => item === this.cardData.d._id);
    },
    // showEnt() {
    //   console.log(this.$route)
    //   return true
    //   // let noEntList = ['/share_info']
    //   // return this.$route.path
    // },
    locale() {
      return this.$i18n.locale;
    },
    occupationString() {
      let industryL = (this.cardData.d.industry && this.cardData.d.industry.title.length) || 0;
      if (industryL > 0) {
        if (this.cardData.d.industry.title[industryL - 1] && this.cardData.d.industry.title[industryL - 1] !== "") {
          let result = this.cardData.d.industry.title[industryL - 1] || "未填写行业";
          if (!result) result = "未填写行业";
          return result;
        } else {
          return "未填写行业";
        }
      } else {
        return "未填写行业";
      }
    },
    addressString() {
      let cardData = this.cardData.d;
      if (/[\u4E00-\u9FA5]/.test(cardData.address)) {
        return cardData.region && cardData.region.title && cardData.region.title.length > 0
          ? cardData.region.title.join("") + cardData.address
          : this.$t("entCard.personal.未填写地址");
      } else {
        return cardData.displayAddress;
      }
    }
  },
  methods: {
    maskString(data, start, len) {
      return data;
      // if (this.exchanged) return data;
      // let type;
      // let procData;
      // if (Array.isArray(data)) {
      //   procData = data;
      //   type = 1; // array
      // } else if (data === data + "") {
      //   procData = [data];
      //   type = 2; // string
      // } else if (data === +data) {
      //   procData = [data + ""];
      //   type = 2; // Number
      // } else {
      //   return data;
      // }

      // let ret = [];
      // for (let i of procData) {
      //   if (i === i + "" || i === +i) {
      //     let strLen = (i + "").length;
      //     let s, l;
      //     if (start === undefined || !start >= 0) {
      //       s = Math.round(strLen * 0.37);
      //     } else {
      //       s = Math.round(start);
      //     }
      //     if (len === undefined || !len >= 1) {
      //       l = Math.round(strLen * 0.37);
      //     } else {
      //       l = Math.round(len);
      //     }

      //     if (s < 0) s = 0;
      //     if (l < 1) l = 1;
      //     const reg = "^(.{" + s + "})(.{" + l + "})";
      //     const str = i.replace(new RegExp(reg), "$1" + "*".repeat(l));
      //     ret.push(str);
      //   } else {
      //     ret.push(i);
      //   }
      // }
      // if (type === 1) {
      //   return ret;
      // } else {
      //   return ret[0];
      // }
    },
    orderInfo() {
      let mobile = this.cardData.d.mobile || "";
      let email = this.cardData.d.email || "";
      let address =
        this.cardData.d.region && this.cardData.d.region.title && this.cardData.d.region.title.length > 0
          ? this.cardData.d.region.title.join("")
          : "";
      if (mobile !== "" && email === "" && address === "") {
        this.mobileOrder = 10;
      } else if (mobile === "" && email !== "" && address === "") {
        this.emailOrder = 10;
      } else if (mobile === "" && email === "" && address !== "") {
        this.addressOrder = 10;
      } else if (mobile !== "" && email !== "" && address === "") {
        this.mobileOrder = 11;
        this.emailOrder = 10;
      } else if (mobile !== "" && email === "" && address !== "") {
        this.mobileOrder = 11;
        this.addressOrder = 10;
      } else if (mobile === "" && email !== "" && address !== "") {
        this.emailOrder = 11;
        this.addressOrder = 10;
      } else {
      }
      // console.log(this.cardData.d._id,this.mobileOrder, this.emailOrder, this.addressOrder)
    },
    async goToAction(obj) {
      if (this.isSwiping) return false;
      const url = obj.showroom ? obj.showroom : obj.entBooth
      let a = await this.$util.gotoApp({ type: 1, app: url });
      if (a) {
        await this.$util.gotoPage(a);
      } else {
        getApp().globalData.appLocation.href = url;
      }
    }
  },
  created() {
    if (this.hideNoneData) this.orderInfo();
  }
};
</script>

<style lang="scss" scoped scoped>
.swiper-item {
  background: #fff;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  z-index: 2;
  // margin: 20upx 30upx;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  .item-inner {
    padding: 40upx;
    padding-bottom: 28upx;
    box-sizing: border-box;
    width: 100%;
    min-height: 402upx;
    position: relative;
    vertical-align: middle;
    .person-name {
      width: 100%;
      display: inline-block;
      vertical-align: middle;
      font-size: 38upx;
      lighting-color: 38upx;
      font-weight: bold;
      .name {
        display: inline-block;
        max-width: calc(100% - 290upx);
        word-break: break-all;
      }
    }
    .person-duties {
      display: inline-block;
      font-size: 24upx;
      line-height: 24upx;
      font-weight: 400;
      vertical-align: 3upx;
      margin-left: 20upx;
    }
    .company {
      margin-top: 17upx;
      .company-name {
        font-size: 26upx;
        display: inline-block;
        max-width: 440upx;
      }
      .company-job {
        margin-top: 7upx;
        font-size: 26upx;
        max-width: 440upx;
        font-weight: bold;
      }
      .qi {
        display: inline-block;
        width: 34upx;
        height: 34upx;
        border-radius: 50%;
        background-color: #000;
        color: #ffcc33;
        font-size: 24upx;
        line-height: 34upx;
        text-align: center;
        vertical-align: top;
        margin-left: 25upx;
      }
    }
    .contact-msg {
      color: #333;
      font-size: 20upx;
      position: absolute;
      bottom: 10px;
      left: 40upx;
      max-width: calc(100% - 290upx);
      .msg-item {
        height: 40upx;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        line-height: 40upx;
      }
      .msg-ad {
        text-overflow: initial;
        white-space: normal;
        width: 338upx;
        height: auto;
        max-height: 200upx;
        line-height: 30upx;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        &::before {
          content: "";
          display: inline-block;
          width: 20upx;
          height: 20upx;
          vertical-align: -2upx;
          margin-right: 8upx;
          background-image: url("https://img.jiuzhouauto.com/i/2023/08/07/1691397447685-11371.png");
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
      .msg-home {
        &::before {
          content: "";
          display: inline-block;
          width: 20upx;
          height: 20upx;
          vertical-align: -2upx;
          margin-right: 8upx;
          background-image: url("https://img.jiuzhouauto.com/i/2023/08/07/1691397756602-27216.png");
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
      .msg-email {
        &::before {
          content: "";
          display: inline-block;
          width: 20upx;
          height: 20upx;
          vertical-align: -4upx;
          margin-right: 8upx;
          background-image: url("https://img.jiuzhouauto.com/i/2023/08/07/1691397808291-43460.png");
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
      .msg-mobile {
        &::before {
          content: "";
          display: inline-block;
          width: 20upx;
          height: 20upx;
          vertical-align: -2upx;
          margin-right: 8upx;
          background-image: url("https://img.jiuzhouauto.com/i/2023/08/07/1691397846056-24846.png");
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
      .msg-row {
        white-space: normal;
      }
    }
    .btn-bar {
      position: absolute;
      bottom: 40upx;
      right: 20upx;
      .btn {
        width: 170upx;
        height: 51upx;
        border-radius: 50upx;
        background-color: #57bf6a;
        color: #fff;
        text-align: center;
        line-height: 51upx;
        font-weight: bold;
        font-size: 28upx;
        margin-bottom: 20upx;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .gary {
        background-color: rgb(121, 121, 121);
      }
    }
    .img {
      position: absolute;
      right: 40upx;
      top: 40upx;
      width: 129upx;
      height: auto;
      img {
        height: auto;
      }
    }
  }
  .item-bar {
    width: 100%;
    height: 5px;
    display: flex;
    .bar1 {
      flex: 2;
      background-color: #376aee;
    }
    .bar2 {
      flex: 1;
      background-color: #376aee;
    }
  }
  .item-pic {
    border-radius: 0 0 10px 10px;
    // padding: 20upx 40upx 40upx 40upx;
    background-color: rgb(240, 240, 240);
    .pic {
      width: 100%;
      height: auto;
    }
  }
}
</style>
