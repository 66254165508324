var components;
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-uni-view',{staticClass:"u-field",class:{'u-border-top': _vm.borderTop, 'u-border-bottom': _vm.borderBottom }},[_c('v-uni-view',{staticClass:"u-field-inner",class:[_vm.type == 'textarea' ? 'u-textarea-inner' : '', 'u-label-postion-' + _vm.labelPosition]},[_c('v-uni-view',{staticClass:"fild-body"},[_c('v-uni-view',{staticClass:"u-flex-1 u-flex"},[(_vm.type == 'textarea')?_c('v-uni-textarea',{staticClass:"u-flex-1 u-textarea-class",style:([_vm.fieldStyle]),attrs:{"value":_vm.value,"placeholder":_vm.placeholder,"placeholderStyle":_vm.placeholderStyle,"disabled":_vm.disabled,"maxlength":_vm.inputMaxlength,"focus":_vm.focus,"autoHeight":_vm.autoHeight,"fixed":_vm.fixed},on:{"input":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.onInput).apply(void 0, arguments)
},"blur":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.onBlur).apply(void 0, arguments)
},"focus":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.onFocus).apply(void 0, arguments)
},"confirm":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.onConfirm).apply(void 0, arguments)
},"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.fieldClick).apply(void 0, arguments)
}}}):_c('v-uni-input',{staticClass:"u-flex-1 u-field__input-wrap",attrs:{"type":_vm.type,"value":_vm.value,"password":_vm.password || _vm.type === 'password',"placeholder":_vm.placeholder,"placeholderStyle":_vm.placeholderStyle,"disabled":_vm.disabled,"maxlength":_vm.inputMaxlength,"focus":_vm.focus,"confirmType":_vm.confirmType},on:{"focus":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.onFocus).apply(void 0, arguments)
},"blur":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.onBlur).apply(void 0, arguments)
},"input":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.onInput).apply(void 0, arguments)
},"confirm":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.onConfirm).apply(void 0, arguments)
},"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.fieldClick).apply(void 0, arguments)
}}})],1),_c('v-uni-view',{staticClass:"u-clear-icon",style:({opacity: _vm.clearable && _vm.value != '' && _vm.focused ? '1' : '0'}),attrs:{"size":_vm.clearSize},on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.onClear).apply(void 0, arguments)
}}},[_c('v-uni-view',{staticClass:"cuIcon-roundclosefill text-gray"})],1)],1)],1),(_vm.errorMessage !== false && _vm.errorMessage != '')?_c('v-uni-view',{staticClass:"u-error-message"},[_vm._v(_vm._s(_vm.errorMessage))]):_vm._e()],1)}
var recyclableRender = false
var staticRenderFns = []

export { render, staticRenderFns, recyclableRender, components }