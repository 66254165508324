<template>
  <view class="exhibitionTipModal flex justify-center align-center" @click.stop="close">
    <view class="exhibitionDialog bg-white flex justify-center align-center">
      <view class="info">
        <view class="title text-center">{{title}}</view>
        <view class="content text-center">
          {{msg}}
        </view>
        <button class="cu-btn button" @click.stop="close">确定</button>
      </view>
    </view>
  </view>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    msg: {
      type: String,
      default: ''
    }
  },
  methods: {
    close() {
      this.$destroy();
      this.$el.parentNode.removeChild(this.$el);
    }
  }
}
</script>

<style lang="scss" scoped scoped>
.exhibitionTipModal {
  position: fixed;
  left: 0;
  top: 0;
  width: calc(100vw);
  height: calc(100vh);
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;

  .exhibitionDialog {
    border-radius: 15upx;
    width: 500upx;
    padding-top: 48upx;
    .info {
      width: 400upx;
      .title {
        color: black;
        font-weight: bold;
        font-size: 36upx;
      }
      .content {
        margin-top: 40upx;
        font-size: 20upx;
        white-space: pre;
      }
      .button {
        width: 100%;
        height: 50upx;
        font-size: 28upx;
        margin-top: 50upx;
        margin-bottom: 50upx;
        background-color: red;
        color: white;
      }
    }
  }
}
</style>
