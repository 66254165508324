<template><App :keepAliveInclude="keepAliveInclude"/></template><script>
import Vue from "vue";
import { initInteceptor } from "./common/interceptor";
let appLocation = new Proxy(
  { href: "" },
  {
    get: function(target, propKey, receiver) {
      return Reflect.get(target, propKey, receiver);
    },
    set: function(target, propKey, value, receiver) {
      // #ifndef MP-WEIXIN
      window.location.assign(value);
      // #endif
      // #ifdef MP-WEIXIN
      uni.navigateTo({
        url: `/pages/webpage/index?link=${encodeURIComponent(value)}`
      });
      // #endif
      return Reflect.set(target, propKey, value, receiver);
    }
  }
);
export default {
  globalData: {
    appLocation,
    myCardId: ["111", "333"],
    // #ifdef H5
    // 非小程序配置
    platformId: "videopublic", //
    appid: "kbh5app", // 程序 appid
    // #endif
    // #ifdef MP-WEIXIN
    // 小程序配置
    platformId: "miniapp1", //
    appid: "kbmp", // 程序 appid
    // #endif

    code: 52, //  小程序内部版本号，每次发布需加 1

    // 通用配置
    pagesize: 20, // 默认每页返回数据条数
    config: {
      shareTitle: "车后快豹", // 默认分享标题
      shareImageUrl: "https://img.cimpauto.com/i/2019/10/28/upload_ae999f1e9e13fe355cac15fe4aa2a9cf.jpg", // 默认分享图片
      sharePath: "/pages/info" // 默认分享路径
    },
    runningWxmpLogin: 0, // 运行 wxmp_login 时的判断
    loginKey: '',

    // userRegistrationFormList: [], //"已报名"列表
    // myFormList: {}, // 用对象存储的"已报名"项目列表
    // needPayList: {}, // 用对象存储的"待支付"列表
    // payedList: {}, // 用对象存储的"已支付"列表
    // accountInfo: {}, // 用对象存储的"个人信息"列表
    // getTicketList: {}, // 用对象存储的"已获取入场券"项目列表

    // freeTicketExpoIds: {}, // 已获得免费入场券项目ID
    // ticketExpoIds: {}, // 已获得入场券项目ID
    // ticketFormListIds: {}, // 已获得入场券付款报名表ID
    // needPayFormListIds: {}, // 待付款报名表ID

    serviceAgreementZh: "https://static.jiuzhouauto.com/h5/legal.html",
    privacyPolicyZh: "https://static.jiuzhouauto.com/h5/privacy.html",
    serviceAgreementEn: "https://static.jiuzhouauto.com/h5/legal.html",
    privacyPolicyEn: "https://static.jiuzhouauto.com/h5/privacy.html",


    // env: 'test',
    // loginCB: 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx9d8c11744e5d6625&redirect_uri=https%3A%2F%2Fstatic.jiuzhouauto.com%2Foauth.html&response_type=code&scope=snsapi_userinfo&state=test#wechat_redirect',
    // APIUrl: 'http://api.test.jiuzhouauto.com/api/rest',
    // NEWAPIUrl: 'http://test.jiuzhouauto.com/api/hot_words_search',
    // BaseURL: '/app',

    // env: 'production',
    // APIUrl: 'https://api.jiuzhouauto.com/api/rest',
    // NEWAPIUrl: 'https://api.jiuzhouauto.com/api/hot_words_search',
    // BaseURL: '/app',

    scene: null
  },
  onLaunch: async function(options) {
    // 删除session_key
    uni.setStorageSync("session_key", "");
    // 初始化路由拦截
    initInteceptor();
    // console.log('App Launch:', options)
    // console.log('Query:',options.query)
    const globalData = this.globalData || this.$scope.globalData;
    globalData.systemInfo = uni.getSystemInfoSync();
    globalData.env = process.env.NODE_ENV || "development";
    // globalData.env = 'production'
    
    // console.log('ENV:', globalData.env)
    //  q=>.   f=>:
    let cbDomain = "";
    // #ifdef H5
    cbDomain = window.location.host.replace(/\./g, "q").replace(/:/g, "f");
    globalData.systemInfo.windowWidth = document.getElementsByTagName("body")[0].clientWidth;
    // #endif
    console.log(globalData.systemInfo);
    switch (globalData.env) {
      case "development":
        // globalData.loginCB = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx9d8c11744e5d6625&redirect_uri=https%3A%2F%2Fstatic.jiuzhouauto.com%2Foauth.html&response_type=code&scope=snsapi_userinfo&state=local#wechat_redirect'
        globalData.loginCB =
          "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx9d8c11744e5d6625&redirect_uri=https%3A%2F%2Fstatic.jiuzhouauto.com%2Foauth.html&response_type=code&scope=snsapi_userinfo&state=test" +
          cbDomain +
          "#wechat_redirect";
        globalData.APIUrl = "http://api.test.jiuzhouauto.com/api/rest";
        globalData.NEWAPIUrl = "http://test.jiuzhouauto.com/api/hot_words_search";
        globalData.socketUrl = "ws://chat.test.jiuzhouauto.com";
        globalData.NEWSUrl = "http://video.test.jiuzhouauto.com/h/doc-";
        globalData.SCAN_CODE_URL = "https://static.jiuzhouauto.com/wxoauth.html?id={ID}&token_type=9&env=test";
        globalData.AMAP_KEY = "aee3cafdd5932211f7369b6e1408baf1";
        break;
      case "test":
        if (window.location.host === "static.o.jiuzhouauto.com") {
          // globalData.loginCB = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx9d8c11744e5d6625&redirect_uri=https%3A%2F%2Fstatic.jiuzhouauto.com%2Foauth.html&response_type=code&scope=snsapi_userinfo&state=testo#wechat_redirect'
          globalData.loginCB =
            "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx9d8c11744e5d6625&redirect_uri=https%3A%2F%2Fstatic.jiuzhouauto.com%2Foauth.html&response_type=code&scope=snsapi_userinfo&state=test" +
            cbDomain +
            "#wechat_redirect";
          globalData.APIUrl = "//api.o.jiuzhouauto.com/api/rest";
          globalData.NEWAPIUrl = "//test.jiuzhouauto.com/api/hot_words_search";
          globalData.socketUrl = "ws://chat.test.jiuzhouauto.com";
          globalData.NEWSUrl = "http://video.o.jiuzhouauto.com/h/doc-";
          globalData.SCAN_CODE_URL = "https://static.jiuzhouauto.com/wxoauth.html?id={ID}&token_type=9&env=test";
          globalData.AMAP_KEY = "aee3cafdd5932211f7369b6e1408baf1";
        } else {
          // globalData.loginCB = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx9d8c11744e5d6625&redirect_uri=https%3A%2F%2Fstatic.jiuzhouauto.com%2Foauth.html&response_type=code&scope=snsapi_userinfo&state=test#wechat_redirect'
          globalData.loginCB =
            "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx9d8c11744e5d6625&redirect_uri=https%3A%2F%2Fstatic.jiuzhouauto.com%2Foauth.html&response_type=code&scope=snsapi_userinfo&state=test" +
            cbDomain +
            "#wechat_redirect";
          globalData.APIUrl = "//api.test.jiuzhouauto.com/api/rest";
          globalData.NEWAPIUrl = "//test.jiuzhouauto.com/api/hot_words_search";
          globalData.socketUrl = "ws://chat.test.jiuzhouauto.com";
          globalData.NEWSUrl = "http://video.test.jiuzhouauto.com/h/doc-";
          globalData.SCAN_CODE_URL = "https://static.jiuzhouauto.com/wxoauth.html?id={ID}&token_type=9&env=test";
          globalData.AMAP_KEY = "aee3cafdd5932211f7369b6e1408baf1";
        }
        break;
      default:
        globalData.loginCB =
          "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx9d8c11744e5d6625&redirect_uri=https%3A%2F%2Fstatic.jiuzhouauto.com%2Fapp%2F&response_type=code&scope=snsapi_userinfo&state=prod#wechat_redirect";
        globalData.APIUrl = "https://api.jiuzhouauto.com/api/rest";
        globalData.NEWAPIUrl = "http://test.jiuzhouauto.com/api/hot_words_search";
        globalData.socketUrl = "wss://chat.jiuzhouauto.com";
        globalData.NEWSUrl = "https://video.jiuzhouauto.com/h/doc-";
        globalData.SCAN_CODE_URL = "https://static.jiuzhouauto.com/wxoauth.html?id={ID}&token_type=9";
    }

    // console.log('enter')

    //设置全局变量
    // this.$util.getCityFromIp().then(result => {
    //   globalData.CURRENT_ADCODE = result.adcode
    //   globalData.CURRENT_PROVINCE = result.province
    // })

    globalData.scene = options.query?.scene

    this.$util.setAppGlobalData(globalData);
    // 初始化应用
    await this.$util.initApp(options, Vue);
    // #ifdef H5
    // if (location.href.includes("_test_jz")) {
    //   new VConsole();
    // }
    // #endif

    if (this.$util.isWeixnH5() && !options.query.code) {
      console.log("wxregister");
      this.$util.wxRegister();
    }
  },
  onShow: function(options) {
    const globalData = this.globalData || this.$scope.globalData
    if (globalData.scene != options.query?.scene) {
      globalData.scene = options.query?.scene
      this.$util.procScene(options.query?.scene)
    }

    // console.log('App Show')
    // document.getElementById('app').style.display = 'block';
    // #ifdef H5
    let load = document.getElementById("appLoading");
    load.style.display = "none";
    // 修改字体大小，不跟随系统
    (function() {
      if (typeof WeixinJSBridge == "object" && typeof WeixinJSBridge.invoke == "function") {
        handleFontSize();
      } else {
        if (document.addEventListener) {
          document.addEventListener("WeixinJSBridgeReady", handleFontSize, false);
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", handleFontSize);
          document.attachEvent("onWeixinJSBridgeReady", handleFontSize);
        }
      }

      function handleFontSize() {
        // 设置网页字体为默认大小
        WeixinJSBridge.invoke("setFontSizeCallback", { fontSize: 0 });
        // 重写设置网页字体大小的事件
        WeixinJSBridge.on("menu:setfont", function() {
          WeixinJSBridge.invoke("setFontSizeCallback", { fontSize: 0 });
        });
      }
    })();
    // #endif

  },
  onHide: function() {
    // console.log('App Hide')
  }
};
</script>

<style>
/*每个页面公共css */
@import "colorui/main.css";
@import "colorui/icon.css";
@import "colorui/dialog.css";
/* 自定义css */
@import "static/css/comm.css";
</style>