import {api} from '@/common/util';
const FilterMixin = {
  props: {
    formdata: {
      type: Object,
      default: {
        d: {l: [], title: '', type: 1, tel: ''}
      }
    }
  },
  data() {
    return {
      filterDataList: [],
      reqParams: {
        pageIndex: 1,
        pageSize: 10
      }
    }
  },
  methods: {
    getFilterList(reqM, reqD, reqH) {
      return api(reqM, reqD, reqH)
    },
    getSelectData(reqData) {
      return new Promise((resolve, reject) => {
        api('kuaibao.utils.dictByParent', reqData).then(res => {
          if (res && parseInt(res.c) === 200) {
            let list = res.d
            list = list.map(item => {
              item.title = item.l
              item.code = item.v
              delete item.l
              delete item.v
              return item
            })
            resolve(list)
          } else {
            resolve([])
          }
        }).catch(err => {
          reject(err)
        })
      })
    }
  }
}

export default FilterMixin
