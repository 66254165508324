<!--
 * @Descripttion: 
 * @Date: 2024-08-23 15:28:01
 * @LastEditTime: 2024-10-09 14:51:17
-->
<template>
  <view class="advisory">
    <view :class="active && 'advisory-overlay'" @click="close" />
    <view class="advisory-dialog"
          :class="['advisory-dialog-enter', 'advisory-dialog-leave', active && 'active']">
      <view class="advisory-dialog-header">
        <view class="advisory-dialog-header-title" />
        <view class="advisory-dialog-header-close" @click="close" />
      </view>
      <view class="advisory-dialog-content">
        <form>
          <view class="advisory-dialog-content-input">
            <text>姓名</text>
            <input type="text"
                   v-model="formData.name"
                   placeholder="请输入真实姓名" />
          </view>
          <view class="advisory-dialog-content-input">
            <text>手机号码</text>
            <input type="number"
                   v-model="formData.mobile"
                   placeholder="请输入手机号码" />
          </view>
          <view class="advisory-dialog-content-input">
            <text>公司全称</text>
            <input v-model="formData.company"
                   placeholder="请输入公司全称" />
          </view>
          <view class="advisory-dialog-content-input">
            <text>邮箱</text>
            <input v-model="formData.email"
                   placeholder="请输入邮箱号码" />
          </view>
          <view class="advisory-dialog-content-picker">
            <text>意向</text>
            <picker :value="formData.purpose"
                    :range="options"
                    range-key="l"
                    @change="pickerChange">
              <view class="advisory-dialog-content-picker-text">
                <view class="advisory-dialog-content-picker-text-black"
                      v-if="options[formData.purpose]">{{ options[formData.purpose].l }}</view>
                <view class="advisory-dialog-content-picker-text-gray"
                      v-else>请选择咨询意向</view>
                <view class="advisory-dialog-content-picker-text-arrows" />
              </view>
            </picker>
          </view>
          <view class="advisory-dialog-content-input">
            <text>补充内容</text>
            <input v-model="formData.otherPurpose"
                   placeholder="请输入补充内容" />
          </view>
          <view class="advisory-dialog-content-radio">
            <radio-group style="transform:scale(0.5)">
              <radio value="agree"
                     :checked="agree === 'agree'"
                     @click="toggleRadio('agree')" />
            </radio-group>
            <text>已阅读并同意</text>
            <text class="advisory-dialog-content-radio-statement"
                  @click="policy('service')">《服务协议》</text>
            <text class="advisory-dialog-content-radio-statement"
                  @click="policy('privacy')">《隐私协议》</text>
          </view>
          <view class="advisory-dialog-content-button"
                @click="subForm">立即咨询</view>
        </form>
      </view>
    </view>
  </view>
</template>

<script>
export default {
  data () {
    return {
      entId: '',
      options: [],
      active: false,
      agree: '',
      formData: {
        name: '',
        mobile: '',
        company: '',
        email: '',
        purpose: '',
        otherPurpose: ''
      }
    }
  },
  mounted () {
    uni.$on('showAdvisoryDialog', this.showAdvisoryDialog)
  },
  beforeDestroy () {
    uni.$off('showAdvisoryDialog', this.showAdvisoryDialog)
  },
  methods: {
    showAdvisoryDialog (entId) {
      this.active = true
      this.entId = entId
      this.getOptions()
    },
    getOptions () {
      if (this.options.length !== 0) return
      this.$util.api('kuaibao.utils.dictByParent', {
        typecode: 'purpose'
      }).then(res => {
        if (res.c === 200) {
          this.options = res.d
        }
      })
    },
    subForm () {
      if (!this.formData.name) {
        uni.showToast({ title: '请输入姓名', icon: 'none' })
        return
      }
      if (!this.formData.mobile) {
        uni.showToast({ title: '请输入手机号码', icon: 'none' })
        return
      }
      if (this.formData.purpose === '') {
        uni.showToast({ title: '请选择咨询意向', icon: 'none' })
        return
      }
      if (!this.agree) {
        uni.showToast({ title: '请阅读并同意《服务协议》《隐私协议》', icon: 'none' })
        return
      }
      const purpose = this.options[this.formData.purpose].v
      this.$util.api('showroom.entShowroom.saveEntConsulting', {
        entId: this.entId,
        ...this.formData,
        purpose
      }).then(res => {
        if (res.c === 200) {
          this.close()
          uni.showToast({ title: '提交成功' })
        }
      })
    },
    policy (type) {
      const policies = {
        service: {
          url: getApp().globalData.serviceAgreementZh,
          title: '服务协议'
        },
        privacy: {
          url: getApp().globalData.privacyPolicyZh,
          title: '隐私协议'
        }
      }
      const policy = policies[type]
      uni.navigateTo({
        url: `/pages/url/url?url=${encodeURIComponent(policy.url)}&title=${encodeURIComponent(policy.title)}`
      })
    },
    pickerChange (e) {
      this.formData.purpose = e.detail.value
    },
    toggleRadio (value) {
      this.agree = this.agree === value ? '' : value
    },
    close () {
      this.active = false
      this.restForm()
    },
    restForm () {
      this.agree = ''
      for (let i in this.formData) {
        this.formData[i] = ''
      }
    }
  }
}
</script>

<style lang="scss" scoped scoped>
.advisory {
  position: fixed;
  z-index: 999;
  &-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    max-width: 600px;
    margin: 0 auto;
  }
  &-dialog {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    max-width: 600px;
    margin: 0 auto;
    background: #fff;
    border-radius: 10px 10px 0px 0px;
    transition: all 0.5s ease;
    &-enter.active {
      transform: translateY(0);
    }
    &-leave {
      transform: translateY(100%);
    }
    &-header {
      display: flex;
      justify-content: space-between;
      padding: 35rpx 42rpx 45rpx 0rpx;
      background: linear-gradient(180deg, #cfdbfb, #fefeff);
      border-radius: 10px 10px 0px 0px;
      &-title {
        width: 235rpx;
        height: 55rpx;
        background: url("https://static.jiuzhouauto.com/h5_image/showroom/advisory.png")
          no-repeat;
        background-size: 100% 100%;
        margin-top: 10rpx;
      }
      &-close {
        width: 20rpx;
        height: 20rpx;
        background: url("https://static.jiuzhouauto.com/h5_image/showroom/right.png")
          no-repeat;
        background-size: 100% 100%;
      }
    }
    &-content {
      padding: 30rpx;
      &-input {
        display: flex;
        justify-content: space-between;
        background: #f2f2f2;
        padding: 25rpx;
        margin-bottom: 15rpx;
        border-radius: 5px;
        text {
          font-size: 28rpx;
          color: #000000;
          font-weight: 600;
        }
        input {
          text-align: right;
        }
        .uni-input-placeholder {
          text-align: right;
        }
      }
      &-picker {
        display: flex;
        justify-content: space-between;
        background: #f2f2f2;
        padding: 25rpx;
        margin-bottom: 15rpx;
        border-radius: 5px;
        text {
          font-size: 28rpx;
          color: #000000;
          font-weight: 600;
        }
        &-text {
          display: flex;
          align-items: center;
          &-black {
            font-size: 29rpx;
            color: #181818;
          }
          &-gray {
            font-size: 29rpx;
            color: grey;
          }
          &-arrows {
            width: 18rpx;
            height: 18rpx;
            background: url("https://static.jiuzhouauto.com/h5_image/showroom/arrows.png")
              no-repeat;
            background-size: 100% 100%;
            margin-left: 15rpx;
            margin-top: 7rpx;
          }
        }
      }
      &-radio {
        display: flex;
        align-items: center;
        margin-top: 50rpx;
        text {
          font-size: 22rpx;
          line-height: 22rpx;
        }
        &-statement {
          color: #5f88f2;
        }
      }
      &-button {
        font-size: 30rpx;
        width: 100%;
        height: 85rpx;
        line-height: 85rpx;
        margin-top: 7rpx;
        margin-bottom: 30rpx;
        background: #376aee;
        text-align: center;
        color: #fff;
        border-radius: 45rpx;
      }
      uni-input {
        font-size: 29rpx;
      }
    }
  }
}
</style>