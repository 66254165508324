<template>
  <view class="purchase-filter-wrap">
    <view class="header app-flex app-flex-sb-c">
      <view class="select app-flex app-flex-fs-c">
        <view class="select-item text-bold" @click="showPopup('product')">{{
          product.title
        }}</view>
        <view class="select-item text-bold" @click="showPopup('region')">{{
          region.title
        }}</view>
      </view>
      <view class="release-btn text-white text-center" @click="publish"
        >+发布</view
      >
    </view>
    <view class="content">
      <type-purchase
        v-for="(item, index) in filterDataList"
        :class="{ active: index > 0 }"
        show-bottom-bar
        :key="index"
        :purchaseData="item.d"
      ></type-purchase>
    </view>
    <c-popup
      v-model="showRegionPopup"
      safe-area-inset-bottom
      mode="bottom"
      border-radius="20"
      @close="closePopup('region')"
    >
      <view class="popup-head province"
        >选择所在省份<image
          class="close-btn"
          src="http://static.jiuzhouauto.com/h5_image/agent/close.png"
          @click="closePopup('region')"
        ></image
      ></view>
      <scroll-view class="popup-content province" scroll-y>
        <view
          class="popup-item-warp province"
          v-for="(item, index) in provinceList"
          :key="index"
        >
          <view
            class="popup-item"
            :class="[index === provinceActiveIndex ? 'active' : '']"
            @click="selectPopupItem('region', index)"
            >{{ item.title }}</view
          >
        </view>
      </scroll-view>
    </c-popup>
    <c-popup
      v-model="showProductPopup"
      safe-area-inset-bottom
      mode="bottom"
      border-radius="20"
      @close="closePopup('product')"
    >
      <view class="popup-head product"
        >选择产品分类<image
          class="close-btn"
          src="http://static.jiuzhouauto.com/h5_image/agent/close.png"
          @click="closePopup('product')"
        ></image
      ></view>
      <scroll-view class="popup-content product" scroll-y>
        <view
          class="popup-item-warp product"
          v-for="(item, index) in productList"
          :key="index"
        >
          <view
            class="popup-item"
            :class="[index === productActiveIndex ? 'active' : '']"
            @click="selectPopupItem('product', index)"
            >{{ item.title }}</view
          >
        </view>
      </scroll-view>
    </c-popup>
  </view>
</template>

<script>
import popup from "@/components/common/popup/index";
import purchase from "@/components/dataType/type-purchase/index";
import FilterMixin from "@/mixins/filter-component";
// import { getAMap } from "@/common/sdk";

export default {
  mixins: [FilterMixin],
  components: {
    "c-popup": popup,
    "type-purchase": purchase,
  },
  props: {
    pageId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      noneData: false,
      showRegionPopup: false,
      showProductPopup: false,
      region: { code: "", title: "全国" },
      product: { code: "", title: "全部" },
      provinceActiveIndex: 0,
      provinceList: [],
      productActiveIndex: 0,
      productList: [],
    };
  },
  methods: {
    async initPage() {
      this.region.code = '';
      this.region.title = '全国';
      this.getPurchaseList();
      if (this.pageId) {
        uni.$on(`purchaseFilter_${this.pageId}`, () => {
          this.getPurchaseList(true);
        });
      }
      // if (await getAMap()) {
      //   let adCode = sessionStorage.getItem('CURRENT_ADCODE')
      //   if (adCode) adCode = adCode.replace(/(\d{2})\d{2}(\d{2})/g, "$10000")
      //   this.region.code = adCode
      //   this.region.title = sessionStorage.getItem('CURRENT_PROVINCE')
      //   this.getPurchaseList()
      //   if (this.pageId) {
      //     uni.$on(`purchaseFilter_${this.pageId}`, () => {
      //       this.getPurchaseList(true)
      //     })
      //   }
      // }
    },
    getPurchaseList(add) {
      if (add && this.noneData) return false;
      if (this.loading) return false;
      this.loading = true;
      let reqD = {
        pageIndex: this.reqParams.pageIndex,
        pageSize: this.reqParams.pageSize,
      };
      if (this.region.code) {
        reqD.regionCode = this.region.code;
      }
      if (this.product.code) {
        reqD.prodCatCode = this.product.code;
      }
      if (add) reqD.pageIndex += 1;
      this.getFilterList("kuaibao.purchase.getPurchaseList", reqD)
        .then((res) => {
          if (res && parseInt(res.c) === 200) {
            if (add) {
              if (res.d.l.length) {
                this.reqParams.pageIndex += 1;
                this.filterDataList = [...this.filterDataList, ...res.d.l];
              } else {
                this.noneData = true;
              }
            } else {
              this.filterDataList = res.d.l;
            }
          }
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false;
          }, 200);
        });
    },
    getProvinceList() {
      let provinceList = [];
      this.getSelectData({ typecode: "province", parent: "", level: 1 }).then(
        (list) => {
          provinceList = list;
          provinceList.unshift({ title: "全国", code: "" });
          this.provinceList = provinceList;
          // this.provinceActiveIndex = provinceList.findIndex(item => item.code === this.region.code[0])
        }
      );
    },
    getProductList() {
      let productList = [];
      this.getSelectData({ typecode: "prodCats", parent: "", level: 1 }).then(
        (list) => {
          productList = list;
          productList.unshift({ title: "全部", code: "" });
          this.productList = productList;
          // this.productActiveIndex = productList.findIndex(item => item.code === this.product.code[0])
        }
      );
    },
    showPopup(action) {
      if (action === "region") {
        if (!this.provinceList.length) {
          this.getProvinceList();
        }
        this.showRegionPopup = true;
      } else if (action === "product") {
        if (!this.productList.length) {
          this.getProductList();
        }
        this.showProductPopup = true;
      }
      uni.hideTabBar({ animation: true });
    },
    closePopup(action) {
      if (action === "region") {
        this.showRegionPopup = false;
      } else if (action === "product") {
        this.showProductPopup = false;
      }
      uni.showTabBar({ animation: true });
    },
    selectPopupItem(action, index) {
      this.reqParams.pageIndex = 1;
      if (action === "product") {
        this.productActiveIndex = index;
        this.product.code = this.productList[index].code;
        this.product.title = this.productList[index].title;
        setTimeout(() => {
          this.getPurchaseList();
        }, 100);
        this.showProductPopup = false;
      } else if (action === "region") {
        this.provinceActiveIndex = index;
        this.region.code = this.provinceList[index].code;
        let title = this.provinceList[index].title;
        this.region.title =
          title.indexOf("省") >= 0
            ? this.provinceList[index].title
            : title === "全国"
            ? this.provinceList[index].title
            : this.provinceList[index].title + "省";
        setTimeout(() => {
          this.getPurchaseList();
        }, 100);
        this.showRegionPopup = false;
      }
    },
    publish() {
      uni.navigateTo({ url: "/pages/purchase/publish-purchase" });
    },
  },
  beforeDestroy() {
    if (this.pageId) {
      uni.$off(`purchaseFilter_${this.pageId}`);
    }
  },
  created() {
    this.initPage();
  },
};
</script>

<style lang="scss" scoped scoped>
.purchase-filter-wrap {
  .header {
    padding: 27upx 30upx;
    .select-item {
      position: relative;
      font-size: 26upx;
      background: #f2f2f2;
      border-radius: 5upx;
      padding: 8upx 52.5upx 8upx 10.8upx;
      &:last-child {
        margin-left: 15.9upx;
      }
      &:before {
        content: "";
        position: absolute;
        right: 13.6upx;
        top: 20upx;
        width: 0;
        height: 0;
        border-right: 12upx solid transparent;
        border-left: 12upx solid transparent;
        border-top: 14upx solid #8a8a8a;
      }
    }
    .release-btn {
      background-color: #ff0000;
      border-radius: 24upx;
      line-height: 48upx;
      width: 170upx;
      height: 48upx;
      font-size: 26upx;
    }
  }
}
.popup-head {
  padding: 36upx 36upx 0;
  line-height: 100upx;
  font-weight: bold;
  font-size: 40upx;
  position: relative;
  .close-btn {
    position: absolute;
    right: 36upx;
    top: 30upx;
    width: 47upx;
    height: 47upx;
  }
}
.popup-content {
  padding: 0 29upx 26upx;
  max-height: 40vh;
  .popup-item-warp {
    display: inline-block;
    width: calc(100% / 6);
    padding: 9upx;
    &.product {
      width: calc(100% / 3);
    }
    .popup-item {
      height: 59upx;
      font-size: 24upx;
      font-weight: 400;
      color: #8a8a8a;
      line-height: 62upx;
      text-align: center;
      border-radius: 10upx;
      border: 2upx solid #8a8a8a;
      &.active {
        transition: all 100ms;
        background-color: #5ca722;
        border-color: #5ca722;
        color: #ffffff;
      }
    }
  }
}
</style>
