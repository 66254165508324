var components;
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-uni-view',[_c('v-uni-view',{staticClass:"scroll-bar",style:(_vm.outBoxStyle)},[(_vm.isPc)?_c('v-uni-view',{staticClass:"scroll-box",style:(_vm.innerStyle),attrs:{"id":_vm.girdId + 'box',"scroll-x":true},on:{"mouseleave":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.handleUp).apply(void 0, arguments)
},"mousedown":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.handleDown).apply(void 0, arguments)
},"mousemove":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.handleMove).apply(void 0, arguments)
},"mouseup":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.handleUp).apply(void 0, arguments)
},"scroll":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.handleScroll).apply(void 0, arguments)
}}},[_vm._l((_vm.renderList),function(page,idx){return [_c('v-uni-view',{key:("page-" + idx),staticClass:"item-box",class:'gird-' + _vm.girdId},[_c('v-uni-view',{staticClass:"scroll-item"},[_vm._l((page),function(col,colIdx){return [_c('v-uni-view',{key:("col-" + colIdx + "-" + idx),staticClass:"scroll-col"},[_vm._l((col),function(row,rowIdx){return [_c('v-uni-view',{key:("row-" + colIdx + "-" + idx + "-" + rowIdx),staticClass:"scroll-row",on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(function () { return _vm.gridTo(row, rowIdx); }).apply(void 0, arguments)
}}},[_c('v-uni-view',{staticClass:"row-img-box",style:('background-image:url(' + row.img + ')')})],1)]})],2)]})],2)],1)]})],2):_c('v-uni-scroll-view',{staticClass:"scroll-box",style:(_vm.innerStyle),attrs:{"id":_vm.girdId + 'box',"scroll-x":true},on:{"scroll":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.handleScroll).apply(void 0, arguments)
}}},[_vm._l((_vm.renderList),function(page,idx){return [_c('v-uni-view',{key:("page-" + idx),staticClass:"item-box",class:'gird-' + _vm.girdId},[_c('v-uni-view',{staticClass:"scroll-item"},[_vm._l((page),function(col,colIdx){return [_c('v-uni-view',{key:("col-" + colIdx + "-" + idx),staticClass:"scroll-col"},[_vm._l((col),function(row,rowIdx){return [_c('v-uni-view',{key:("row-" + colIdx + "-" + idx + "-" + rowIdx),staticClass:"scroll-row",on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(function () { return _vm.gridTo(row, rowIdx); }).apply(void 0, arguments)
}}},[_c('v-uni-view',{staticClass:"row-img-box",style:('background-image:url(' + row.img + ')')})],1)]})],2)]})],2)],1)]})],2),(_vm.renderList.length > 1)?_c('v-uni-view',{staticClass:"scroll-tips-bar"},[_c('v-uni-view',{staticClass:"tips-bar"},[_c('v-uni-view',{staticClass:"tips-bar-inner",style:([{ width: _vm.barWidth, left: _vm.barLeft }])})],1)],1):_vm._e()],1)],1)}
var recyclableRender = false
var staticRenderFns = []

export { render, staticRenderFns, recyclableRender, components }