<template>
  <view v-if="swiperList.s.height"
        :id="carouselId"
        :style="[{
        'background-image': 'url(' + swiperList.s.background + ')',
        'background-size': 'cover',
        'background-repeat': 'no-repeat',
        'height': carouselH + 'px'}]">
    <swiper :id="videoId"
            :class="dotStyle ? 'square-dot' : 'round-dot'"
            :indicator-dots="swiperList.d.item && swiperList.d.item.length > 1"
            :circular="true"
            :autoplay="autoplay"
            :interval="swiperList.s.interval||5000"
            duration="500"
            :style="[{ height: carouselH + 'px' , '--indicator-bottom': indicatorBottom + 'px' }]"
            @animationfinish="finishSwipe"
            @change="changeSwipe"
            @transition="transitionAction">
      <swiper-item v-for="(item, index) in swiperList.d.item"
                   :key="index"
                   @click="carouselTo(item, index)">
        <view class="warp"
              :style="{
                width: '100%',
                height: '100%'}">
          <template v-if="item.video">
            <view class="play"
                  v-if="playingId !== index"></view>
            <view class="muted"
                  :class="{ noMuted: isMuted }"
                  @click.stop="handleToggleMuted" />
            <image v-if="autoplay"
                   :src="item.img"
                   mode="aspectFit"
                   style="width: 100%;height: 100%"></image>
            <video :id="videoId + index" 
                   :key="videoKey"
                   :controls="false"
                   :autoplay="false"
                   :muted="isMuted"
                   @play="onPlay(index)"
                   @pause="onPause(index)"
                   :show-center-play-btn="false"
                   :src="item.video" 
                   style="width: 100%;height: 100%"></video>
          </template>
          <template v-else>
            <image v-if="!item.error" 
                   :key="refreshKey"
                   :src="item.isShow ? item.img : 'http://static.jiuzhouauto.com/h5_image/loading_hori.jpg'"
                   mode="aspectFit"
                   style="width: 100%;height: 100%"></image>
            <image v-else
                   src="http://static.jiuzhouauto.com/h5_image/loading_hori.jpg"
                   mode="aspectFit"
                   style="width: 100%;height: 100%"></image>
          </template>
        </view>
      </swiper-item>
    </swiper>
  </view>
</template>

<script>
import { isPcBroswer } from "@/common/tools/system";
import { uuid } from "@/common/util";

export default {
  behaviors: ["uni://form-field"],
  props: {
    formdata: {
      type: Object,
      default: {}
    }
  },
  data () {
    return {
      carouselH: 0,
      carouselW: 0,
      activeIdx: 0,
      isMuted: true,
      // 轮播用
      swiperList: { d: { item: [] }, s: {}, t: "stdCarousel" },
      dotStyle: false,
      isSwiping: false,
      domOpacity: {},
      videoId: uuid(),
      videoContext: {},
      playingId: '',
      autoPause: false,
      autoplay: true,
      refreshKey: 1,
      playingIndex: null,
      videoKey: 1,
      carouselId: uuid()
    };
  },
  computed: {
    indicatorBottom () {
      return this.formdata.s['indicator-bottom'] || 40
    }
  },
  watch: {
    activeIdx (idx) {
      // 如果有正在播放
      if (this.playingId) {
        // 正在播放的不是当前的item则停止正在播放的
        if (idx !== this.playingId) {
          this.videoContext[this.videoId + this.playingId].pause();
          this.playingId = "";
          this.autoPause = true;
          // if (this.videoContext[this.videoId + idx]) {
          //   this.videoContext[this.videoId + idx].play();
          //   this.playingId = idx;
          // }
        }
      } else {
        // 如果是因为滑动停止的则开启播放
        // if (this.autoPause) {
        //   if (this.videoContext[this.videoId + idx]) {
        //     this.videoContext[this.videoId + idx].play();
        //     this.playingId = idx;
        //     this.autoPause = false;
        //   }
        // }
      }
    }
  },
  methods: {
    handleToggleMuted () {
      this.isMuted = !this.isMuted;
      uni.setStorageSync("isMuted", this.isMuted);
    },
    onPlay (idx) {
      console.log('2222')
      this.autoplay = false
      this.playingIndex = idx
      // this.playingId = idx;
    },
    onPause (idx) {
      // this.playingId = "";
    },
    handleTogglePlay (idx) {
      console.log('111')
      if (this.playingId === idx) {
        this.videoContext[this.videoId + idx].pause();
        this.autoPause = false;
        this.playingId = "";
      } else {
        this.videoContext[this.videoId + idx].play();
        this.playingId = idx;
      }
    },
    transitionAction () {
      if (isPcBroswer()) {
        this.isSwiping = true;
      }
    },
    changeSwipe (event) {
      this.activeIdx = event.target.current;
      let index = event.target.current;
      this.pauseVideo()
      if (!this.swiperList.d.item[index].isShow) {
        this.$set(this.swiperList.d.item[index], "isShow", true);
        this.refreshKey++
      }
    },
    pauseVideo () {
      if (!this.autoplay) {
        this.playingId = ''
        this.videoContext[this.videoId + this.playingIndex].pause()
      } 
    },
    finishSwipe (event) {
      if (this.isPc) {
        this.isSwiping = false;
      }
    },
    imgLoaded (index) {
      if (this.swiperList.d.item[index].isShow && !this.swiperList.d.item[index].loaded) {
        this.$set(this.swiperList.d.item[index], "loaded", true);
      }
    },
    loadError (index) {
      if (!this.swiperList.d.item[index].isShow) {
        this.$set(this.swiperList.d.item[index], "error", true);
      }
    },
    carouselTo (e, idx) {
      if (e.video) {
        this.handleTogglePlay(idx);
        return;
      }
      if (e.app) {
        this.toGetPage(e.app);
      } else if (e.h5) {
        getApp().globalData.appLocation.href = e.h5;
      } else {
        console.log("啥都没有的 不做操作");
      }
    },
    // 获取jiuzhouauto
    async toGetPage (obj) {
      if (this.isSwiping) return false;
      let a = await this.$util.gotoApp({ type: 1, app: obj });
      console.log("a", a);
      if (a) {
        await this.$util.gotoPage(a);
      } else {
        // #ifdef MP-WEIXIN
        uni.navigateTo({
          url: `/pages/url/url?url=${encodeURIComponent(obj)}`
        });
        // #endif
        // #ifndef MP-WEIXIN
        // getApp().globalData.appLocation.href = obj;
        // #endif
      }
    },
    initVideo (list) {
      list.forEach((item, idx) => {
        if (item.video) {
          item.id = this.videoId
          this.videoContext[this.videoId + idx] = uni.createVideoContext(this.videoId + idx, this);
        }
      });

      uni
        .createIntersectionObserver(this, { observeAll: true })
        .relativeToViewport({ top: -50, bottom: -50 })
        .observe(`#${this.carouselId}`, res => {
          if (this.playingId || this.playingId === 0) {
            this.playingId = ""
            this.videoContext[this.videoId + this.playingId].pause()
            this.autoPause = false
          }
        })
      
    }
  },
  
  created () {
    this.isPc = isPcBroswer();
    this.swiperList = this.formdata;
    this.swiperList.d.item.forEach(item => item.isShow = false)
    this.swiperList.d.item[0].isShow = true;
    this.carouselH = getApp().globalData.systemInfo.windowWidth * this.formdata.s.height;
    this.isMuted = uni.getStorageSync("isMuted") === "" ? true : uni.getStorageSync("isMuted");
  },
  mounted () {
    this.$nextTick(() => {
      this.initVideo(this.swiperList.d.item);
    })
  },
  pageLifetimes: {
    hide () {
      if (this.playingId) {
        this.videoContext[this.videoId + this.playingId].pause();
        this.playingId = "";
        this.autoPause = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped scoped>
::v-deep .uni-swiper-dots-horizontal {
  bottom: var(--indicator-bottom);
}
::v-deep .wx-swiper-dots-horizontal {
  bottom: var(--indicator-bottom);
}

.warp {
  position: relative;
  .play {
    width: 80rpx;
    height: 80rpx;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, -50%);
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("https://static.jiuzhouauto.com/h5_image/video_log.png");
  }
  .muted {
    width: 60rpx;
    height: 60rpx;
    position: absolute;
    right: 20rpx;
    bottom: 20rpx;
    z-index: 2;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("http://img.jiuzhouauto.com/i/2024/05/29/1716950585254-69078.png");
  }
  .noMuted {
    background-image: url("https://img.jiuzhouauto.com/i/2024/05/29/1716954222335-96128.png");
  }
}
</style>
