
/**
 * 四则运算，防止精度丢失
 * @param {Object} arg1
 * @param {Object} arg2
 * @param {Object} way 'add','minus','multiple','divide'
 * @return {number}
 */
const Arithmetic = function (arg1, arg2, way) {
  let r1, r2, m;
  try {
    r1 = arg1.toString().split(".")[1].length
  } catch (e) {
    r1 = 0
  }
  try {
    r2 = arg2.toString().split(".")[1].length
  } catch (e) {
    r2 = 0
  }
  m = Math.pow(1000, Math.max(r1, r2))
  if (way === 'add') {
    return (arg1 * m + arg2 * m) / m
  } else if (way === 'minus') {
    return (arg1 * m - arg2 * m) / m
  } else if (way === 'multiple') {
    return (Math.round(arg1 * 100) * Math.round(arg2 * 100)) / 10000
  } else if (way === 'divide') {
    let n1 = Number(arg1.toString().replace(".", ""))
    let n2 = Number(arg2.toString().replace(".", ""))
    let d1, d2 = 0;
    try {
      d1 = arg1.toString().split(".")[1].length
    } catch (e) {
      d1 = 0;
    }
    try {
      d2 = arg2.toString().split(".")[1].length
    } catch (e) {
      d2 = 0;
    }
    return toFixedNum((n1 / n2) * Math.pow(10, d2 - d1), 2)
  }
};

/**
 * 重写toFixed方法，四舍五入
 * @param {Object} arg
 * @param {Object} n
 */
const toFixedNum = (arg, n) => {
  if (n == 0) {
    return Math.round(arg)
  }
  try {
    let d, carryD,
      ds = arg.toString().split('.'),
      len = ds[1].length,
      b0 = '',
      k = 0
    if (len > n) {
      while (k < n && ds[1].substring(0, ++k) == '0') {
        b0 += '0'
      }
      d = Number(ds[1].substring(0, n))
      carryD = Math.round(Number('0.' + ds[1].substring(n, len)))
      len = (d + carryD).toString().length
      if (len > n) {
        return Number(ds[0]) + 1
      } else if (len == n) {
        return Number(ds[0] + '.' + (d + carryD))
      }
      return Number(ds[0] + '.' + b0 + (d + carryD))
    }
  } catch (e) {
    return false
  }
  return arg
}

export {
  Arithmetic
}
