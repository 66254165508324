var components;
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-uni-view',{staticClass:"wrap"},[(!_vm.isPc)?_c('v-uni-scroll-view',{attrs:{"scroll-x":true,"show-scrollbar":true}},[_c('v-uni-view',{staticClass:"imgContainer",style:({height:_vm.imgStyle.height * 2 + 'px'})},[_vm._l((_vm.imgType1List),function(item,index){return _c('v-uni-image',{key:index,staticClass:"item",style:({width:_vm.imgStyle.width * 2 + 'px', height: _vm.imgStyle.height* 2 + 'px'}),attrs:{"src":item.poster,"mode":"scaleToFill"},on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.previewImage(index)
}}})})],2)],1):_c('PcScrollBar',{style:({height:_vm.imgStyle.height * 2 + 'px'})},_vm._l((_vm.imgType1List),function(item,index){return _c('PcScrollBarItem',{key:index},[_c('v-uni-image',{style:({width: _vm.imgStyle.width * 2 + 'px', height: _vm.imgStyle.height * 2 + 'px'}),attrs:{"lazy-load":true,"src":item.poster,"mode":"scaleToFill"},on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.previewImage(index)
}}})],1)}),1)],1)}
var recyclableRender = false
var staticRenderFns = []

export { render, staticRenderFns, recyclableRender, components }