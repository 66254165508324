import Vue from 'vue';
import Main from "./main";

let ModalConstructor = Vue.extend(Main);
let curModal, instance;
let modalQueue = [];

const defaultCallback = (action) => {
  if (curModal) {
    let callback = curModal.callback;
    if (typeof callback === 'function') {
      callback(action)
    }
    if (curModal.resolve) {
      if (action === 'confirm') {
        curModal.resolve(action)
      } else if (curModal.reject && (action === 'cancel' || action === 'close')) {
        curModal.reject(action)
      }
    }
  }
}

const showModal = () => {
  instance = new ModalConstructor();
  instance.callback = defaultCallback
  instance.action = ''
  if (modalQueue.length) {
    curModal = modalQueue.shift()
    let options = curModal.options
    for(let prop in options) {
      if (options.hasOwnProperty(prop)) {
        instance[prop] = options[prop]
      }
    }
    if (options.callback === undefined) {
      instance.callback = defaultCallback
    }
    let oldCb = instance.callback
    instance.callback = (action, instance) => {
      oldCb(action, instance)
      showModal()
    }
    instance.$mount()
    document.body.appendChild(instance.$el);
    instance.visible = true;
  }
}

const MessageModal = function (options, callback) {
  if (typeof Promise !== 'undefined') {
    return new Promise((resolve, reject) => {
      modalQueue.push({
        options: options,
        callback: callback,
        resolve: resolve,
        reject: reject
      })
      showModal()
    })
  } else {
    modalQueue.push({
      options: options,
      callback: callback
    })
  }
}

MessageModal.confirm = function (title, content, options) {
  return MessageModal({
    title: title,
    content: content,
    options: options,
    $type: 'confirm'
  })
}

MessageModal.alert = function (title, content, options) {
  return MessageModal({
    title: title,
    content: content,
    options: options,
    $type: 'alert'
  })
}

MessageModal.clickAction = function (action) {

}

export default MessageModal
