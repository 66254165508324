var components;
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.visible)?_c('v-uni-view',{staticClass:"openWeappModal",on:{"touchmove":function($event){$event.stopPropagation();$event.preventDefault();
arguments[0] = $event = _vm.$handleEvent($event);

}}},[_c('v-uni-view',{staticClass:"body"},[_c('v-uni-view',{staticClass:"closeBtn",on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.close).apply(void 0, arguments)
}}},[_c('v-uni-image',{staticClass:"close",attrs:{"src":"//img.jiuzhouauto.com/i/2020/11/02/1604298746486-98746.png","mode":"''"}})],1),_c('v-uni-image',{staticClass:"tipImg",attrs:{"src":_vm.imageUrl,"mode":"widthFix"}}),_c('v-uni-view',{domProps:{"innerHTML":_vm._s(_vm.htmlStr)}})],1)],1):_vm._e()}
var recyclableRender = false
var staticRenderFns = []

export { render, staticRenderFns, recyclableRender, components }