var components;
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.visibleSync)?_c('v-uni-view',{staticClass:"u-drawer",style:([
    _vm.customStyle,
    {
      zIndex: _vm.uZindex - 1,
    } ]),attrs:{"hover-stop-propagation":true}},[_c('c-mask',{attrs:{"duration":_vm.duration,"custom-style":_vm.maskCustomStyle,"maskClickAble":_vm.maskCloseAble,"z-index":_vm.uZindex - 2,"show":_vm.showDrawer && _vm.mask},on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.maskClick).apply(void 0, arguments)
}}}),_c('v-uni-view',{staticClass:"u-drawer-content",class:[
      _vm.safeAreaInsetBottom ? 'safe-area-inset-bottom' : '',
      'u-drawer-' + _vm.mode,
      _vm.showDrawer ? 'u-drawer-content-visible' : '',
      _vm.zoom && _vm.mode == 'center' ? 'u-animation-zoom' : '' ],style:([_vm.style]),on:{"touchmove":function($event){$event.stopPropagation();$event.preventDefault();
arguments[0] = $event = _vm.$handleEvent($event);

},"click":[function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.modeCenterClose(_vm.mode)
},function($event){$event.stopPropagation();$event.preventDefault();
arguments[0] = $event = _vm.$handleEvent($event);

}]}},[(_vm.mode == 'center')?_c('v-uni-view',{staticClass:"u-mode-center-box",style:([_vm.centerStyle]),on:{"touchmove":function($event){$event.stopPropagation();$event.preventDefault();
arguments[0] = $event = _vm.$handleEvent($event);

},"click":function($event){$event.stopPropagation();$event.preventDefault();
arguments[0] = $event = _vm.$handleEvent($event);

}}},[(_vm.closeable)?_c('u-icon',{staticClass:"u-close",class:['u-close--' + _vm.closeIconPos],attrs:{"name":_vm.closeIcon,"color":_vm.closeIconColor,"size":_vm.closeIconSize},on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.close).apply(void 0, arguments)
}}}):_vm._e(),_c('v-uni-scroll-view',{staticClass:"u-drawer__scroll-view",attrs:{"scroll-y":"true"}},[_vm._t("default")],2)],1):_c('v-uni-scroll-view',{staticClass:"u-drawer__scroll-view",attrs:{"scroll-y":"true"}},[_vm._t("default")],2),_c('v-uni-view',{staticClass:"u-close",class:['u-close--' + _vm.closeIconPos],on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.close).apply(void 0, arguments)
}}},[(_vm.mode != 'center' && _vm.closeable)?_c('u-icon',{attrs:{"name":_vm.closeIcon,"color":_vm.closeIconColor,"size":_vm.closeIconSize}}):_vm._e()],1)],1)],1):_vm._e()}
var recyclableRender = false
var staticRenderFns = []

export { render, staticRenderFns, recyclableRender, components }