import 'uni-pages';import 'uni-h5';import '@dcloudio/uni-stat/dist/uni-stat.es.js';import Vue from "vue";
import App from "./App";
// vuex状态管理
import store from "./store";
// i18n
import i18n from "@/common/i18n/index";
// 错误监控


import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";
// 线上环境才启用sentry
if (process.env.NODE_ENV === "production" || process.env.NODE_ENV === "test") {
  Sentry.init({
    dsn: "https://9402a660d35243559fd4b6822c97fe09@track1.jiuzhouauto.com/2",
    integrations: [
      new Integrations.Vue({
        Vue,
        attachProps: true
      })
    ],
    ignoreErrors: ["/Not Found：Page\\[(\\d)+\\]/"],
    release: process.env.VUE_APP_SENTRYRELEASE,
    environment: process.env.NODE_ENV,
    logErrors: true,
    debug: process.env.NODE_ENV !== "production"
  });
}


// import cuCustom from 'colorui/components/cu-custom.vue'
// import type
import typeList from "components/dataType/type-list/index.vue";
import typeCarousel from "components/dataType/type-carousel/index.vue";
import typeEntNewFavorite from "components/dataType/type-entNewFavorite/index.vue";
import typeGrid from "components/dataType/type-grid/index.vue";
import typeBignews from "components/dataType/type-bignews/index.vue";
import typePic from "components/dataType/type-pic/index.vue";
import typeHalfImg from "components/dataType/type-halfImg/index.vue";
import typeHalfImgList from "components/dataType/type-halfImgList/index";
import typeChatInfo from "components/dataType/type-chatInfo/index.vue";
import typeChatMsg from "components/dataType/type-chatMsg/index.vue";
import typeEntFavorite from "components/dataType/type-entFavorite/index.vue";
import typeBoothFavorite from "components/dataType/type-boothFavorite/index.vue";
import typeVideoBlock from "components/dataType/type-videoBlock/index.vue";
import typeItemList from "components/dataType/type-itemList/index.vue";
import typeBusinessCard from "components/dataType/type-busCard/index";
import typeBoothCarousel from "components/dataType/type-boothCarousel/index";
import typeStdCarousel from "components/dataType/type-stdCarousel/index";
import typeTextBox from "components/dataType/type-textBox/index";
import typeAgent from "components/dataType/type-agents/index";
import typePurchaseFilter from "components/dataType/type-purchaseFilter/index";
import typePurchase from "components/dataType/type-purchase/index";
import typePurchaseQuote from "components/dataType/type-purchaseQuote/index";
import typeCounter from "components/dataType/type-counter/index";
import typeGridScroll from "components/dataType/type-gridScroll/index"
import typeAdvisoryDialog from "@/components/dataType/type-advisoryDialog"
// import type end

// import form
// import formCard from 'components/form/form-card/index.vue'
// import formName from 'components/form/form-name/index.vue'
// import formSex from 'components/form/form-sex/index.vue'
// import formMobile from 'components/form/form-mobile/index.vue'
// import formRegion from 'components/form/form-region/index.vue'
// import formJob from 'components/form/form-job/index.vue'
// import formCompany from 'components/form/form-company/index.vue'
// import formIndustry from 'components/form/form-industry/index.vue'
// import formAddress from 'components/form/form-address/index.vue'
// import formEmail from 'components/form/form-email/index.vue'
// import form end

// type
Vue.component("type-list", typeList); //公用自定义组件列表
Vue.component("type-carousel", typeCarousel);
Vue.component("type-entNewFavorite", typeEntNewFavorite);
Vue.component("type-grid", typeGrid);
Vue.component("type-bignews", typeBignews);
Vue.component("type-pic", typePic);
Vue.component("type-halfImg", typeHalfImg);
Vue.component("type-halfImgList", typeHalfImgList);
Vue.component("type-chatInfo", typeChatInfo);
Vue.component("type-chatMsg", typeChatMsg);
Vue.component("type-entFavorite", typeEntFavorite);
Vue.component("type-boothFavorite", typeBoothFavorite);
Vue.component("type-videoBlock", typeVideoBlock);
Vue.component("type-itemList", typeItemList);
Vue.component("typeBusinessCard", typeBusinessCard);
Vue.component("typeBoothCarousel", typeBoothCarousel);
Vue.component("typeStdCarousel", typeStdCarousel);
Vue.component("typeTextBox", typeTextBox);
Vue.component("type-agent", typeAgent);
Vue.component("type-purchaseFilter", typePurchaseFilter);
Vue.component("type-purchase", typePurchase);
Vue.component("type-counter", typeCounter);
Vue.component("type-purchaseQuote", typePurchaseQuote);
Vue.component("type-gridScroll",typeGridScroll)
Vue.component("type-advisory-dialog",typeAdvisoryDialog)

// type end

// form
// Vue.component('form-card', formCard)
// Vue.component('form-name', formName)
// Vue.component('form-sex', formSex)
// Vue.component('form-mobile', formMobile)
// Vue.component('form-region', formRegion)
// Vue.component('form-job', formJob)
// Vue.component('form-company', formCompany)
// Vue.component('form-industry', formIndustry)
// Vue.component('form-address', formAddress)
// Vue.component('form-email', formEmail)
//     // form end


import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);
// vue lazyload
import VueLazyload from "vue-lazyload";
if (isPcBroswer()) {
  Vue.use(VueLazyload);
}


// 瀑布流插件

// import { VueMasonryPlugin } from "vue-masonry";
// Vue.use(VueMasonryPlugin)






// import customWaterfallsFlow from "@/components/custom-waterfalls-flow/custom-waterfalls-flow.vue"
// Vue.component('custom-waterfalls-flow', customWaterfallsFlow)

// 通用函数
import * as util from "common/util.js";
import { isPcBroswer } from "@/common/tools/system";

import modal from "@/components/common/modal";
Vue.prototype.$modal = modal;

Vue.prototype.$util = util;
Vue.config.productionTip = false;

// 开启性能追踪
Vue.config.performance = process.env.NODE_ENV !== "production";

App.mpType = "app";

const app = new Vue({
  i18n,
  store,
  ...App
});
app.$mount();