var components;
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-uni-view',{staticClass:"box"},[_c('v-uni-view',{staticClass:"box-content"},[(_vm.showTopBar)?[(_vm.showBack)?_c('v-uni-view',{staticClass:"topLift forPC",style:([{ opacity: '' + _vm.op + '' }]),on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.BackPage).apply(void 0, arguments)
}}},[_c('v-uni-image',{staticClass:"topimage",attrs:{"src":"https://static.jiuzhouauto.com/h5_image/business_back1.png","mode":""}})],1):_vm._e(),(_vm.showfav)?_c('v-uni-view',{staticClass:"topRight",style:([{ opacity: '' + _vm.op + '' }]),on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.changeFavorite).apply(void 0, arguments)
}}},[_c('v-uni-image',{staticClass:"topimage",attrs:{"src":"http://static.jiuzhouauto.com/h5_image/menu_white.png","mode":""}})],1):_vm._e(),(_vm.showBack)?_c('v-uni-view',{staticClass:"topLift forPC",style:([{ opacity: '' + 1 - _vm.op + '' }]),on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.BackPage).apply(void 0, arguments)
}}},[_c('v-uni-image',{staticClass:"topimage",attrs:{"src":"http://static.jiuzhouauto.com/h5_image/business_back2.png","mode":""}})],1):_vm._e(),(_vm.showfav)?_c('v-uni-view',{staticClass:"topRight",style:([{ opacity: '' + 1 - _vm.op + '' }]),on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.changeFavorite).apply(void 0, arguments)
}}},[_c('v-uni-image',{staticClass:"topimage",attrs:{"src":"http://static.jiuzhouauto.com/h5_image/menu_black.png","mode":""}})],1):_vm._e(),(!_vm.topmenuData.length > 0)?_c('v-uni-view',{staticClass:"topFix top2",style:([{ opacity: '' + 1 - _vm.op + '' }])},[_c('v-uni-view',{staticClass:"pageTitle text-cut"},[_vm._v(_vm._s(_vm.pageTitle))])],1):_vm._e()]:_vm._e(),(_vm.showFavorite)?_c('v-uni-view',{staticClass:"showFavorite text-sm",staticStyle:{"line-height":"40upx"},on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.changeFavorite).apply(void 0, arguments)
}}},[_c('v-uni-view',{staticClass:"showFavoriteMain",on:{"click":function($event){$event.stopPropagation();
arguments[0] = $event = _vm.$handleEvent($event);

}}},[(_vm.showfavType)?_c('v-uni-view',{staticClass:"FavorItem",on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.toSaveFavorite).apply(void 0, arguments)
}}},[_c('v-uni-image',{staticClass:"iconImg",attrs:{"src":"http://static.jiuzhouauto.com/h5_image/icon_follow.png","mode":""}}),_vm._v(_vm._s(_vm.$t("ent.关注")))],1):_c('v-uni-view',{staticClass:"FavorItem",on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.toSaveFavorite).apply(void 0, arguments)
}}},[_c('v-uni-image',{staticClass:"iconImg",attrs:{"src":"http://static.jiuzhouauto.com/h5_image/icon_collect.png","mode":""}}),_vm._v(_vm._s(_vm.$t("ent.收藏")))],1),(_vm.showBusiness)?_c('v-uni-view',{staticClass:"FavorItem",on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.toEntCardList).apply(void 0, arguments)
}}},[_c('v-uni-image',{staticClass:"iconImg",attrs:{"src":"http://static.jiuzhouauto.com/h5_image/icon_business.png","mode":""}}),_vm._v(_vm._s(_vm.$t("ent.企业名片")))],1):_vm._e(),(_vm.showChat)?_c('v-uni-view',{staticClass:"FavorItem",on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.toEntChat).apply(void 0, arguments)
}}},[_c('v-uni-image',{staticClass:"iconImg",attrs:{"src":"http://static.jiuzhouauto.com/h5_image/icon_chat.png","mode":""}}),_vm._v(_vm._s(_vm.$t("ent.企业咨询")))],1):_vm._e(),(_vm.language.show)?_c('v-uni-view',{staticClass:"FavorItem",on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.changeLan).apply(void 0, arguments)
}}},[_c('v-uni-image',{staticClass:"iconImg",attrs:{"src":_vm.$i18n.locale && _vm.$i18n.locale === 'en'
                  ? 'http://static.jiuzhouauto.com/h5_image/lang_cn.png'
                  : 'http://static.jiuzhouauto.com/h5_image/lang_en.png',"mode":""}}),_vm._v(_vm._s(_vm.$t("common.切换语言")))],1):_vm._e()],1)],1):_vm._e(),(_vm.barData.t && _vm.barData.t == 'grid')?_c('v-uni-view',{staticClass:"barList forPC",style:([{ background: '' + _vm.rgbaOP + '' }])},[_c('type-grid',{attrs:{"formdata":_vm.barData}})],1):_vm._e(),(!_vm.isPc && _vm.topmenuData.length > 0)?_c('v-uni-scroll-view',{staticClass:"nav fixed topNav forPC",class:_vm.op == 0 ? 'text-black' : ' text-white',style:([{ top: _vm.topNavTop + 'px', background: _vm.rgbaOP }]),attrs:{"scroll-x":true,"scroll-with-animation":true,"scroll-left":_vm.scrollLeft}},_vm._l((_vm.topmenuData),function(item,index){return _c('v-uni-view',{key:index,staticClass:"cu-item",class:index == _vm.TabCur ? 'cur' : '',attrs:{"data-id":index},on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.tabSelect(item, index)
}}},[_vm._v(_vm._s(item.title))])}),1):(_vm.isPc && _vm.topmenuData.length > 0)?_c('PcScrollBar',{staticClass:"nav fixed topNav forPC",class:_vm.op == 0 ? 'text-black' : ' text-white',style:([{ top: _vm.topNavTop + 'px', background: _vm.rgbaOP }]),attrs:{"cur-index":_vm.TabCur}},_vm._l((_vm.topmenuData),function(item,index){return _c('PcScrollBarItem',{key:index},[_c('v-uni-view',{staticClass:"cu-item",class:index == _vm.TabCur ? 'cur' : '',on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.tabSelect(item, index)
}}},[_vm._v(_vm._s(item.title))])],1)}),1):_vm._e(),_c('type-component-list',{attrs:{"main-data":_vm.mainData,"page-id":_vm.pageId,"mainBottom":_vm.mainBottom || 0,"mainPaddingBottom":_vm.mainPaddingBottom,"mainTop":_vm.formdata.mainTop || 0,"mainBgColor":_vm.mainBgColor,"height":_vm.menuData.t ? '100%' : _vm.height,"isIndex":_vm.isIndex},on:{"refresh":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.refresh).apply(void 0, arguments)
},"upper":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.upper).apply(void 0, arguments)
},"lower":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.lower).apply(void 0, arguments)
},"scroll":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.scroll).apply(void 0, arguments)
}}}),(_vm.showTypeChat)?_c('type-chatInfo',{staticClass:"typeChatInfo",style:([_vm.menuData.t ? { bottom: _vm.typeChatBottom + 'px' } : {}]),attrs:{"formdata":_vm.typeChatData,"page-id":_vm.pageId}}):_vm._e()],2),(_vm.menuData.t)?_c('v-uni-view',{staticClass:"footerNav"},[_c('type-grid',{attrs:{"formdata":_vm.menuData},on:{"clickBottomNav":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.checkoutNav).apply(void 0, arguments)
}}})],1):_vm._e()],1)}
var recyclableRender = false
var staticRenderFns = []

export { render, staticRenderFns, recyclableRender, components }