<template>
	<view class="padding-lr text-black" @tap="toPage(picData)">
		<view class="border borderR5 margin-top-xs padding-lr-sm flex">
			<img v-if="picData.d.img" :src="picData.d.img" mode="" style="width:100upx;height: 100upx;"></img>
			<img v-else src="http://static.jiuzhouauto.com/h5_image/info.png" mode="" style="width:100upx;height: 100upx;"></img>
			<view class="text-justify align-center text-sm flex-sub flex margin-lr-sm">{{picData.d.title}}</view>
			<view v-if="picData.d.redta" class="align-center flex"><text class="btn">{{picData.d.redtag}}</text></view>
		</view>
	</view>
</template>

<script>
	export default {
	  name: 'type-itemList',
		behaviors:['uni://form-field'],
		props: {
			formdata: {
				type: Object,
				default: {}
			}
		},
		data () {
			return {
				picData:[]
			}
		},
		methods:{
			toPage(obj){
				if(obj.d.app){
					this.toGetPage(obj.d.app)
				}
			},
			// 获取jiuzhouauto
			async toGetPage(obj){
				let a = await this.$util.gotoApp({type:1,app:obj})
				if(a){
					await this.$util.gotoPage(a)
				}else{
					getApp().globalData.appLocation.href = obj
				}
			},
		},
		created() {
			this.picData = this.formdata
		}
	}
</script>

<style scoped scoped>
	.border{
		border: 1px solid #e1e1e1;
	}
	.btn{
		border-radius: 15px;
		background: #f00;
		padding: 3px 10px;
		color: #fff;
	}
</style>
