var components;
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.type === 'default')?_c('v-uni-view',{staticClass:"card",on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.goToVideo(_vm.info._id)
}}},[_c('lazy-load',{attrs:{"loading-img":_vm.info.frontCoverType === 1 ? _vm.loadingVer : _vm.loadingHori,"error-img":_vm.info.frontCoverType === 1 ? _vm.loadingVer : _vm.loadingHori,"borderTopRadius":"8","img-mode":"aspectFill","height":_vm.info.frontCoverType === 1 ? 380 : 186,"image":("" + (_vm.info.frontCoverView || _vm.info.view) + (_vm.webp ? '!w300p' : '!w300'))}}),_c('v-uni-view',{staticClass:"bg-white content-wrap"},[_c('v-uni-view',{staticClass:"text-xl text-cut title"},[_vm._v(_vm._s(_vm.info.title))])],1)],1):_c('v-uni-view',{staticClass:"card-full",on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.goToVideo(_vm.info._id)
}}},[_c('lazy-load',{attrs:{"image":_vm.info.frontCoverView ? ("" + (_vm.info.frontCoverView) + (_vm.webp ? '!w750p' : '!w750')) : _vm.info.view,"height":_vm.info.frontCoverViewRatio ? _vm.bookListWidth * _vm.info.frontCoverViewRatio : 230,"borderTopRadius":"8","borderBottomRadius":"8","loading-img":_vm.loadingVer,"error-img":_vm.loadingHoriError,"img-mode":"aspectFill"}})],1)}
var recyclableRender = false
var staticRenderFns = []

export { render, staticRenderFns, recyclableRender, components }