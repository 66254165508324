<template>
  <view class="item bg-white" @click="viewTo(newsList.d)">
    <view class="itemView posR" :style="itemViewStyle">
      <!--			<view v-if="!playVideo" class="itemImg bg-img posA" :style="[{'background-image': 'url('+newsList.d.view+')'}]"></view>-->
      <template>
        <lazy-load
          v-if="!playVideo && !isPc"
          class="itemImg"
          loading-img="http://static.jiuzhouauto.com/h5_image/loading_hori.jpg"
          error-img="http://static.jiuzhouauto.com/h5_image/loading_failed_hori.jpg"
          img-mode="aspectFill"
          :height="bignewsH + 'PX'"
          :image="newsList.d.view"
        >
        </lazy-load>
        <image v-else-if="!playVideo && isPc" mode="aspectFill" :style="imgH" :src="newsList.d.view"></image>
      </template>
      <video
        @ended="handleEnd"
        @pause="handlepause"
        @waiting="handleWait"
        @error="handleError"
        @loadedmetadata="handleLoad"
        v-if="playVideo"
        :autoplay="true"
        :src="videoUrl"
        :title="newsList.d.title"
        :poster="newsList.d.view"
        :id="'myVideo' + newsList.d.id"
        style="width: 100%; height: 100%"
        @tap.stop="toClickVideo"
      ></video>
      <!-- 点击视频按钮 -->
      <view v-if="newsList.d.media && !playVideo" class="videoBtn" @tap.stop="toPlay">
        <img src="http://static.jiuzhouauto.com/h5_image/video_log.png" alt="" />
        <view v-if="newsList.d.media && newsList.d.live == '1' && videoError" class="error-text"
          >直播已暂停，请稍后回来</view
        >
      </view>

      <!-- 非直播时 时间栏 -->
      <view v-if="newsList.d.media && newsList.d.live != '1' && !playVideo" class="videoTime text-xs">
        <img src="http://static.jiuzhouauto.com/h5_image/video_time.png" alt="" />
        <text>{{ formatSeconds(newsList.d.duration) }}</text>
      </view>
      <!-- 直播时 直播标签 -->
      <view v-if="newsList.d.media && newsList.d.live == '1' && !playVideo" class="videoLive">
        <img class="video-live-img" src="http://static.jiuzhouauto.com/h5_image/living.png" alt="" />
      </view>
    </view>
    <view class="flex padding-tb-sm border_b">
      <!--      <lazy-load  v-if ="newsList.d.vlogo" class="itemVlogo bg-img" :image="newsList.d.vlogo"></lazy-load>-->
      <image v-if="newsList.d.vlogo" class="itemVlogo bg-img" :src="newsList.d.vlogo" mode="widthFix"></image>
      <image
        v-else
        class="itemVlogo bg-img"
        src="http://static.jiuzhouauto.com/h5_image/info.png"
        mode="widthFix"
      ></image>
      <view class="padding-left-sm flex-sub">
        <view class="itemTitle">{{ newsList.d.title }}</view>
        <view class="text-xs text-gray padding-top-xs"
          >{{ newsList.d.auth }}&nbsp;&nbsp;阅读{{ newsList.d.pv }}&nbsp;&nbsp;{{ newsList.d.createdOn }}</view
        >
      </view>
    </view>
  </view>
</template>

<script>
import LazyLoad from "@/components/common/LazyLoad";
import { isPcBroswer } from "@/common/tools/system";
import { getQiNiuWebPlayer } from "@/common/sdk";

export default {
  behaviors: ["uni://form-field"],
  props: {
    formdata: {
      type: Object,
      default: {}
    }
  },
  components: {
    "lazy-load": LazyLoad
  },
  data() {
    return {
      videoError: false,
      videoUrl: "",
      playVideo: false,
      newsList: [],
      isPc: isPcBroswer()
    };
  },
  computed: {
    bignewsH() {
      if (this.formdata.s && this.formdata.s.height) {
        return (getApp().globalData.systemInfo.windowWidth - 30) * parseFloat(this.formdata.s.height);
      } else {
        return 0;
      }
    },
    itemViewStyle() {
      return this.playVideo ? `height:${this.bignewsH}PX` : "";
    },
    imgH() {
      return `width:100%;height:${this.bignewsH}PX`;
    }
  },
  methods: {
    toPlay() {
      // 点击播放视频
      let that = this;
      this.playVideo = true;
      this.videoError = false;
      // let video = uni.createVideoContext('myVideo' + this.newsList.d.id);
      // video.play()
      this.$nextTick(() => {
        this.qiniuVideo();
      });
    },
    handleEnd() {
      console.log("播放到末尾");
    },
    handlepause() {
      console.log("播放暂停");
    },
    handleWait() {
      console.log("播放缓冲");
    },
    handleError() {
      console.log("播放错误", this.newsList.d.id);
      this.playVideo = false;
      this.videoError = true;
      this.videoContext.stop();
    },
    handleLoad() {
      console.log("视频加载完成");
    },
    toClickVideo() {
      console.log("点击视频区域,不做操作，防止点击跳转详情页");
    },
    viewTo(e) {
      let obj = getApp().globalData.NEWSUrl + e.id + ".html";
      // #ifndef MP-WEIXIN
      getApp().globalData.appLocation.href = obj;
      // #endif
      // #ifdef MP-WEIXIN
      const unionid = uni.getStorageSync("unionid");
      obj = obj + `?h5id=${unionid}`;
      uni.navigateTo({
        url: `/pages/webpage/index?link=${encodeURIComponent(obj)}`,
        fail(e) {
          console.log(e);
        },
        complete(e) {
          console.log(e);
        }
      });
      // #endif
    },
    formatSeconds(duration) {
      let secondTime = parseInt(duration); // 秒
      let minuteTime = 0; // 分
      let hourTime = 0; // 小时
      if (secondTime > 60) {
        // 如果秒数大于60，将秒数转换成整数
        // 获取分钟，除以60取整数，得到整数分钟
        minuteTime = parseInt(secondTime / 60);
        // 获取秒数，秒数取佘，得到整数秒数
        secondTime = parseInt(secondTime % 60);
        // 如果分钟大于60，将分钟转换成小时
        if (minuteTime > 60) {
          // 获取小时，获取分钟除以60，得到整数小时
          hourTime = parseInt(minuteTime / 60);
          // 获取小时后取佘的分，获取分钟除以60取佘的分
          minuteTime = parseInt(minuteTime % 60);
        }
      }
      let result = secondTime > 9 ? secondTime : "0" + secondTime;
      if (minuteTime > 0) {
        result = (minuteTime > 9 ? minuteTime : "0" + minuteTime) + ":" + result;
      } else {
        result = "00" + ":" + result;
      }
      if (hourTime > 0) {
        result = (hourTime > 9 ? hourTime : "0" + hourTime) + ":" + result;
      } else {
        result = "00" + ":" + result;
      }
      return result;
    },
    async qiniuVideo() {
      // #ifndef MP-WEIXIN
      let currentUrl = window.location.href;
      // #endif
      // #ifdef MP-WEIXIN
      let currentUrl = "https://";
      // #endif
      let videoUrl = this.newsList.d.media;
      if (currentUrl.indexOf("http://") >= 0) {
        videoUrl = videoUrl.replace("https://", "http://");
      } else if (currentUrl.indexOf("https://") >= 0) {
        videoUrl = videoUrl.replace("http://", "https://");
      } else {
        console.log("视频使用了其他协议的地址");
      }
      try {
        // #ifndef MP-WEIXIN
        let res = await getQiNiuWebPlayer();
        if (res) {
          const player = new QPlayer({
            url: videoUrl,
            poster: this.newsList.d.view,
            autoplay: true,
            controls: true,
            container: document.getElementById("myVideo" + this.newsList.d.id),
            logger: 3
          });
          console.log(player);
          // 播放器准备完毕，可以播放
          player.on("ready", function(e) {
            console.log("ready11111:", e);
          });
          // 播放器暂停
          player.on("pause", function(e) {
            console.log("pause222222:", e);
          });
          // 播放过程中出现错误
          player.on("error", function(e) {
            switch (e.code) {
              case 10002:
                console.log("error3333332224444444:", e.code);
                break;
              default:
            }
            console.log("error333333:", e);
            console.log("error333333222:", e.code);
          });
          // 播放结束
          player.on("ended", function(e) {
            console.log("ended444444:", e);
          });
        }
        // #endif
        // #ifdef MP-WEIXIN
        this.videoUrl = videoUrl;
        this.$nextTick(() => {
          this.videoContext.play();
        });
        // #endif
      } catch (e) {
        console.log("catch:", e);
      }
    }
  },
  mounted() {
    this.videoContext = uni.createVideoContext("myVideo" + this.newsList.d.id);
  },
  created() {
    this.newsList = this.formdata;
    this.newsList.d.createdOn = new Date(this.newsList.d.createdOn).format("yyyy-MM-dd");
    Object.freeze(this.newsList);
  }
};
</script>

<style scoped>
.item {
  padding: 10px 15px 0;
}
.itemView {
  border-radius: 5px;
  overflow: hidden;
}
.itemImg {
  width: 100%;
  z-index: 2;
}
.itemVideo {
}
.videoBtn {
  z-index: 3;
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  border-radius: 100px;
}

.videoTime {
  position: absolute;
  z-index: 3;
  background: rgba(0, 0, 0, 0.3);
  right: 10px;
  bottom: 10px;
  padding: 5px 8px;
  border-radius: 3px;
  color: #fff;
}
.videoTime img {
  display: inline-block;
  height: 20upx;
  width: 20rpx;
  line-height: 20upx;
  margin-right: 10upx;
}
.videoLive {
  position: absolute;
  z-index: 3;
  right: 10px;
  top: 10px;
  width: 70px;
}
.videoBtn img,
.videoLive img {
  width: 100%;
}
.video-live-img {
  height: 50rpx;
}
.videoBtn ._img {
  height: 100%;
}
.itemTitle {
  overflow: hidden;
  height: 1em;
  line-height: 1em;
}
.itemVlogo {
  width: 20px;
  height: 20px;
  border-radius: 30px;
  overflow: hidden;
  margin-top: 6upx;
}
.error-text {
  position: absolute;
  bottom: -40rpx;
  left: 50%;
  transform: translate(-50%, 0);
  white-space: nowrap;
  color: #fff;
  text-align: center;
}
</style>
