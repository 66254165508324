import VueI18n from "vue-i18n";
import Vue from 'vue';
Vue.use(VueI18n)
import { isZh } from '@/common/validate/language';
import messages from "@/common/i18n/messages";

const supportLanguage = ['en', 'zh']
    // 获取是否有手动修改过语言
let storageLanguage = uni.getStorageSync('__language')
let currentLanguage
if (storageLanguage && storageLanguage !== '' && supportLanguage.includes(storageLanguage)) {
    currentLanguage = storageLanguage
} else {
    // 获取系统的语言
    let sysLanguage = uni.getSystemInfoSync().language
    console.log('当前系统语言', sysLanguage)
    currentLanguage = isZh(sysLanguage) ? 'zh' : 'en'
}

export default new VueI18n({
    formatFallbackMessages: true, // 开启回退本地化
    fallbackLocale: 'zh', // 回退本地化，当英文占不到是回退到中文
    locale: currentLanguage,
    messages
})
