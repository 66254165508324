import { isPcBroswer } from "@/common/tools/system";
import { componentList, componentParams } from '@/config/filter-component'
const TypeListMixin = {
    data() {
        return {
            isPc: isPcBroswer(), // 是否是pc环境
            // 重新定义请求数据,用于下拉刷新,主要用于切换头部导航后修改参数
            newsParam: {},
            pageTitle: '',
            loading: false,
            noneData: false,
            searchShow: false,
            filterComponents: componentList, // 过滤组件
            nextPageParams: {}, // 加载下一页请求参数
            // 头部栏区域
            barData: {},
            // 头部菜单栏区域
            topmenuData: [],
            // 底部导航
            menuData: {},
            // 底部导航道当前下标
            menuIndex: 1,
            // 主体内容
            mainData: [],
            // 页面宽度
            clientWidth: '',

            // topNav区域高度
            topNavTop: '',
            // main区域高度
            mainBgColor: '#fff',
            mainTop: 0,
            mainBottom: 0,
            mainPaddingBottom: 0,
            mainPaddingTop: 0,

            // 导航
            TabCur: 0,
            scrollLeft: 0,

            // 顶部固定导航栏
            op: 1, //透明度
            rgbaOP: 1,

            // 聊天组件
            showTypeChat: false,
            typeChatBottom: 0,
            typeChatData: {},

            showFavorite: false,
            showfavType: false, //false收藏 true关注
            showfav: false, //顶部的 收藏/关注
            showBack: true, //顶部的 收藏/关注
            showBusiness: false, //顶部的 查看企业
            businessEntId: '',
            showChat: false, //顶部的 企业咨询
            chatEntId: '', // 企业咨询的企业ID
            followBooth: false,
            language: { // 语言
                show: false,
                id: '',
            },
            locale: '',
            theFav: [],
            tabApp: ''
        }
    },
    props: {
        sharePageId: {
            type: String,
            default: ''
        }
    },
    watch: {
        // 聊天组件
        showTypeChat() {
            this.$nextTick(() => {
                if (this.showTypeChat) {
                    this.setBottomPadding()
                } else {
                    this.setBottomPadding()
                }
            })
        }
    },
    methods: {
        // 整理数据
        assemblyData(data, setLan) {
            if (data.d && data.d.item && data.d.item.length) {
                data.d.item.map(i => {
                        if (i.app && i.app !== '') {
                            if (/^jiuzhouapp:\/\/([^?]+)[?]?(.*)/.test(i.app)) {
                                if (i.app.indexOf('?') >= 0) {
                                    i.app = `${i.app}&pageId=${this.pageId}${setLan ? '&lan='+ this.locale : ''}`
                                } else {
                                    i.app = `${i.app}?lan=${this.locale}&pageId=${this.pageId}`
                                }
                                // console.log(i.app)
                            }
                        }
                        return i
                    })
                    // console.log('action 1', data)
                return data
            } else if (data.d && data.d.app && data.d.app !== '') {
                if (/^jiuzhouapp:\/\/([^?]+)[?]?(.*)/.test(data.d.app)) {
                    if (data.d.app.indexOf('?') >= 0) {
                        data.d.app = `${data.d.app}&pageId=${this.pageId}${setLan ? '&lan='+ this.locale : ''}`
                    } else {
                        data.d.app = `${data.d.app}?pageId=${this.pageId}${setLan ? '&lan='+ this.locale : ''}`
                            // console.log(`${data.d.app}`)
                    }
                }
                // console.log('action 2', data)
                return data
            } else {
                // console.log('action 3', data)
                return data
            }
        },
        async getListData(res, actionType) {
            if (parseInt(res.c) === 200) {
                // 顶部初始化透明，翻页时忽略
                if (this.newsParam.d.pageIndex && this.newsParam.d.pageIndex === 1) {
                    this.op = 1
                }
                // 判断是否添加关注/收藏按钮
                if (actionType && actionType !== '' && this.newsParam.d.pageIndex === 1) {

                    // 设置页面埋点跟踪
                    this.$util.setMatomo(res)

                    if (res.d.fav) {
                        this.theFav = res.d.fav
                        this.showfav = true
                        if (parseInt(res.d.fav.favType) === 3) {
                            this.showfavType = true
                        } else {
                            this.showfavType = false
                        }
                    } else {
                        this.showfav = false
                    }
                    // 右上角显示企业名片
                    if (res.d.entCard && res.d.entCard.entId !== '') {
                        this.showBusiness = true
                        this.businessEntId = res.d.entCard.entId
                    }
                    // 右上角显示企业咨询
                    if (res.d.chat && res.d.chat.entId && res.d.entId !== '') {
                        this.showChat = true
                        this.chatEntId = res.d.chat.entId
                    }
                    // 设置微信分享
                    if (res.d.share && res.d.share.shareUrl) {
                        this.$util.jweixinShare(res.d.share.title, res.d.share.shareUrl, res.d.share.imgUrl, res.d.share.desc, this.sharePageId)
                    }
                    // 判断是否添加返回按钮
                    if (res.d.hideBack && parseInt(res.d.hideBack) === 1) {
                        this.showBack = false
                    } else {
                        this.showBack = true
                    }
                    // 页面标题
                    if (res.d.title) {
                        uni.setNavigationBarTitle({
                            title: res.d.title
                        });
                        this.pageTitle = res.d.title
                    }

                    // 当前页面的语言
                    // 通过接口返回lan，如果没有则拿浏览器环境的语言
                    if (res.d.lan && (parseInt(res.d.lan) === 1 || parseInt(res.d.lan) === 2)) {
                        // 如果页面没有传lan参数，则默认取请求返回的lan
                        if (!this.language.$i18n) {
                            this.setLan(parseInt(res.d.lan) === 1 ? 'zh' : 'en')
                        }
                    } else {
                        this.setLan(this.$i18n.locale)
                    }
                    // if (res.d.lan && (parseInt(res.d.lan) === 1 || parseInt(res.d.lan) === 2)) {
                    // 	let curLan = parseInt(res.d.lan) === 1 ? 'zh' : 'en'
                    // 	if (this.$i18n.locale !== curLan) {
                    // 		this.$util.changeLanguage()
                    // 	}
                    // }

                    // 切换语言按钮，目前只有产品详情页和展位详情页展示
                    if ((res.d.prodLan && res.d.prodLan.id && res.d.prodLan.id !== '')) {
                        this.language.show = true
                        this.language.id = res.d.prodLan.id
                    }
                    // else if (res.d.boothLan && res.d.boothLan.entId && res.d.boothLan.entId !== '') {
                    //   this.language.show = true
                    //   this.language.id = res.d.boothLan.entId
                    // }

                }
                // menu底部导航
                if (res.d.menu) {
                    // console.log('底部导航 黄色的:', res.d.menu)
                    let menuData = res.d.menu
                        // console.log('menuData', menuData)
                    if (menuData.d.item && menuData.d.item.length) {
                        menuData.d.item.map(m => {
                            if (m.app && m.app !== '') {
                                if (/^jiuzhouapp:\/\/([^?]+)[?]?(.*)/.test(m.app)) {
                                    m.app = `${m.app}${m.app.indexOf('?') >= 0 ? '&' : '?'}lan=${this.locale}`
                                }
                            }
                        })
                    }
                    this.menuData = menuData
                    this.mainBottom = this.clientWidth * this.menuData.s.height
                    this.menuData.footerType = 1
                } else {
                    if (actionType === 'bottom') {
                        this.menuData = {}
                    }
                }
                // topmenu导航栏
                if (res.d.topmenu) {
                    // console.log('导航栏 绿色的:', res.d.topmenu)
                    this.topmenuData = res.d.topmenu
                        // 有导航栏，这里再通过导航栏请求l部分数据
                    this.tabApp = res.d.topmenu[0].app
                    if (this.tabApp) {
                        this.toGetPage(this.tabApp)
                    }
                } else {
                    if (actionType === 'bottom') {
                        this.topmenuData = {}
                    }
                    // console.log(res.d.lan)
                    // console.log('没有头部导航栏，操作L内容')
                    // 主体内容
                    if (res.d.l) {
                        if (res.d.l.length > 0) {
                            let outputList = this.handleFilterComponent(res.d.l)
                                // console.log('主体内容 蓝色的:', res.d.l)
                            let dataList = JSON.parse(JSON.stringify(this.mainData))
                                // 为每个app跳转动作加上语言和pageId
                            for (let i of outputList) {
                                let outputData = this.assemblyData(i, res.d.lan)
                                if (outputData.t === 'stdCarousel') {
                                    outputData.d.item.map(item => {
                                        item.img = item.img.replace(/!w600/g, '!w300')
                                        return item
                                    })
                                }
                                dataList.push(outputData)
                            }
                            // 将半图组件组装成数组
                            dataList = this.initMasonry(dataList)
                            this.mainData = dataList
                            if (this.searchShow) {
                                this.$emit('change', false)
                            }
                            // 聊天组件，如果有多个只设置一个
                            let chatInfoIndex = dataList.findIndex(item => item.t === 'chatInfo')
                            if (chatInfoIndex >= 0) {
                                this.$off(`addChatMessage_${this.pageId}`)
                                    // 设置留言组件顶部的距离
                                if (this.menuData.t) {
                                    this.mainBgColor = '#f1f1f1'
                                    this.typeChatBottom = getApp().globalData.systemInfo.windowWidth * this.menuData.s.height
                                }
                                this.showTypeChat = true
                                this.typeChatData = dataList[chatInfoIndex]
                                uni.$on(`addChatMessage_${this.pageId}`, ChatMsg => {
                                    let mainData = this.mainData
                                    let firstChatMsgIndex = mainData.findIndex(item => item.t === 'chatMsg')
                                    if (firstChatMsgIndex >= 0) {
                                        mainData.splice(firstChatMsgIndex, 0, ChatMsg)
                                        this.mainData = mainData
                                    } else {
                                        this.mainData.push(ChatMsg)
                                    }
                                })
                            } else {
                                this.showTypeChat = false
                            }
                        } else {
                            this.noneData = true
                        }
                    }
                }
                // topbar头部搜索区域
                if (res.d.topbar) {
                    // console.log('头部搜索区域 红色的:', res.d.topbar)
                    this.barData = res.d.topbar
                    this.topNavTop = this.clientWidth * this.barData.s.height
                        // 换算成'upx' 为了配合首页
                        // this.mainTop = this.topNavTop *750/this.clientWidth + 90
                } else {
                    if (actionType === 'bottom') {
                        this.barData = {}
                    }
                }
            }
        },
        async getNewsList(formdata, type) {
            let that = this
            that.loading = true
            if (type && type === 'up' && formdata.d && formdata.d.pageIndex) {
                formdata.d.pageIndex = 1
                this.rgbaOP = 'transparent'
            }
            if (formdata && formdata.d && formdata.d.pageSize) {
                formdata.d.pageSize = 20
            }
            let req_h = {}
            if (this.locale && this.locale !== '') {
                req_h.lan = this.locale
            }
            let res = await this.$util.api(formdata.m, formdata.d, req_h);
            that.loading = false
            if ((type && type === 'up') || (type && type === 'bottom')) {
                that.mainData = []
                setTimeout(function() {
                    that.getListData(res, type)
                }, 0);
            } else {
                that.getListData(res)
            }
        },
        // 返回上一级
        BackPage() {
            this.$util.goBack()
        },
        // 处理列表里的过滤组件
        handleFilterComponent(dataList = []) {
            let outputList = []
            for (let i = 0; i < dataList.length; i++) {
                if (this.filterComponents.some(item1 => item1 === dataList[i].t)) {
                    outputList.push(dataList[i])
                    this.nextPageParams = componentParams[dataList[i].t]
                    break;
                } else {
                    outputList.push(dataList[i])
                }
            }
            return outputList
        },
        // 在上拉,加载下一页
        lower: function(e) {
            // console.log('下一页:', e, this.newsParam)
            if (!this.noneData && !this.loading) {
                // 判断是否有过滤组件
                if (this.nextPageParams.m) {
                    uni.$emit(`${this.nextPageParams.t}_${this.pageId}`)
                    return false
                }
                this.newsParam.d.pageIndex++;
                this.getNewsList(this.newsParam, 'down');
            }
        },
        // 准备下拉,更新第一页
        upper: function(e) {
            // console.log('第一页:', e)
        }
    }
}

export default TypeListMixin