var components;
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-uni-view',{staticClass:"posR padding-sm",staticStyle:{"background-color":"#ebebeb"}},[_c('v-uni-view',{staticClass:"bg-white padding-sm borderR5 flex"},[(_vm.newData.d.logo)?_c('v-uni-image',{staticClass:"logo bg-img margin-right-sm",attrs:{"src":_vm.newData.d.logo,"mode":"widthFix"}}):_c('v-uni-image',{staticClass:"logo bg-img margin-right-sm",attrs:{"src":"http://static.jiuzhouauto.com/h5_image/info.png","mode":"widthFix"}}),_c('v-uni-view',{staticClass:"flex-sub"},[_c('v-uni-view',{staticClass:"text-black margin-top"},[_vm._v(_vm._s(_vm.newData.d.shortName))]),_c('v-uni-view',{staticClass:"text-gray text-xs"},[_vm._v(_vm._s(_vm.$t('entFavour.粉丝数'))+_vm._s(_vm.newData.d.favNum))])],1),_c('v-uni-view',[(!_vm.thegz)?_c('v-uni-text',{staticClass:"margin-top gzBtn",on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.toPage()
}}},[_vm._v("+"+_vm._s(_vm.$t('entFavour.关注')))]):_c('v-uni-text',{staticClass:"margin-top gzBtn btn2",on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.toPage('del')
}}},[_vm._v(_vm._s(_vm.$t('entFavour.已关注')))])],1)],1)],1)}
var recyclableRender = false
var staticRenderFns = []

export { render, staticRenderFns, recyclableRender, components }