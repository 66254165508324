const share = {
    namespace: true,
    state: {
        shareInfo: {

        },
        defaultShareInfo:{}
    },
    mutations: {
        SET_SHARE_INFO(state, { info, pageId }) {
            state.shareInfo[pageId] = info
        },
        CLEAR_SHARE_INFO(state) {
            state.shareInfo = {}
        },
        SET_DEFAULT_SHAREINFO(state,info) {
            state.defaultShareInfo = info
        }
    },
    getters: {
        getShareInfo: (state) => (pageId) => state.shareInfo[pageId]
    },
    actions: {
        setShareInfo({ commit }, { info, pageId }) {
            commit('SET_SHARE_INFO', { info, pageId })
        },
        setDefaultShareInfo({commit},info) {
            commit("SET_DEFAULT_SHAREINFO",info)
        },
        clearShareInfo({ commit }) {
            commit('CLEAR_SHARE_INFO')
        }
    }
}

export default share