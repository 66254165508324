<template>
  <view class="agents-block">
    <view class="head app-flex app-flex-sb-c">
      <view class="title text-black">{{formdata.d.title}}</view>
      <view v-if="formdata.d.type === 2" class="location app-flex app-flex-fs-c">
        <image src="http://static.jiuzhouauto.com/h5_image/agent/location.png" style="width: 21upx;height: 29upx"></image>
        <text class="margin-left-xs" @click="switchProvince">{{region.city[0] || ''}}</text>
      </view>
    </view>
    <view class="content" :class="[formdata.d.type === 1 ? 'no-data':'']">
      <template v-if="formdata.d.type === 1">
        <view class="agent-item">
          <view class="company text-df">我是经销商，我想展示…</view>
          <view class="phone-btn text-center" @click="call(formdata.d.tel)">电话咨询</view>
        </view>
      </template>
      <template v-if="formdata.d.type === 2">
        <view v-for="item in itemList" :key="item.id" class="agent-item app-flex app-flex-sb-c">
          <view class="company">{{item.text}}</view>
          <view class="phone-btn text-center" @click="call(item.tel)">电话咨询</view>
        </view>
      </template>
    </view>
    <c-popup v-model="showPopup" safe-area-inset-bottom mode="bottom" border-radius="20">
      <view class="province-head">经销商所在省份<image class="close-btn" src="http://static.jiuzhouauto.com/h5_image/agent/close.png" @click="closeProvince"></image></view>
      <scroll-view class="province-list" scroll-y>
        <view class="province-item-warp" v-for="(item, index) in provinceList" :key="index">
          <view class="province-item" :class="[index === provinceActiveIndex ? 'active' : '']" @click="selectProvince(index)">{{item.city}}</view>
        </view>
      </scroll-view>
    </c-popup>
  </view>
</template>

<script>
import popup from '@/components/common/popup/index';
// import {getAMap} from "@/common/sdk";

export default {
  components: {
    'c-popup': popup
  },
  props: {
    formdata: {
      type: Object,
      default: {
        d: {l: [], title: '', type: 1, tel: ''}
      }
    }
  },
  data() {
    return {
      showPopup: false,
      provinceList: [],
      region: {code: [], city: []},
      itemList: [],
      provinceActiveIndex: 0
    }
  },
  methods: {
    async init() {
/*      let res = await getAMap()
      if (res) {
        let adCode = getApp().globalData.CURRENT_ADCODE
        let province = getApp().globalData.CURRENT_PROVINCE
        if (adCode) adCode = adCode.replace(/(\d{2})\d{2}(\d{2})/g, "$10000")
        if (this.formdata.d.l.some(item => item.region.code[0] === adCode)) {
          this.region.code = [adCode]
          this.region.city = [province]
          this.itemList = this.gItemList(adCode)
        } else {
          this.region.code = ['000000']
          this.region.city = ['全国']
          this.itemList = this.gItemList()
        }
      }
 */
			this.region.code = ['000000']
			this.region.city = ['全国']
			this.itemList = this.gItemList()
		},
    gItemList(targetCode) {
      let itemList = []
      this.formdata.d.l.forEach((item, index) => {
        if (itemList.length <= 9) {
          if (targetCode) {
            if (item.region.code && item.region.code.length && item.region.code[0] === targetCode) {
              itemList.push(item)
            }
          } else {
            itemList.push(item)
          }
        }
      })
      return itemList;
    },
    call(phoneNum) {
      uni.makePhoneCall({
        phoneNumber: phoneNum
      })
    },
    getProvinceList() {
      let provinceList = [];
      provinceList.push({city: '全国', code: '000000'})
      this.formdata.d.l.forEach(item => {
        if (!provinceList.some(item1 => item1.code === item.region.code[0])) {
          provinceList.push({
            city: item.region.title[0],
            code: item.region.code[0]
          })
        }
      })
      this.provinceActiveIndex = provinceList.findIndex(item => item.code === this.region.code[0])
      this.provinceList = provinceList
    },
    switchProvince() {
      if (!this.provinceList.length) {
        this.getProvinceList()
      }
      this.showPopup = true;
    },
    closeProvince() {
      this.showPopup = false;
    },
    selectProvince(index) {
      this.provinceActiveIndex = index;
      this.region.code = [this.provinceList[index].code]
      this.region.city = [this.provinceList[index].city.indexOf('省') >= 0 ? this.provinceList[index].city : this.provinceList[index].city + '省']
      if(this.region.code[0] === '000000') {
        this.itemList = this.gItemList()
      } else {
        this.itemList = this.gItemList(this.provinceList[index].code)
      }
      this.closeProvince()
    },
  },
  created() {
    this.init()
  }
}
</script>

<style lang="scss" scoped scoped>
.agents-block {
  padding: 0 30upx 30upx;

  .head {
    padding: 26upx 25upx;
    color: #5CA722;

    .title {
      position: relative;
      color: #010101;

      &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 8upx;
        background-color: #5CA722;
      }
    }
  }

  .content {
    padding: 28upx 24upx;
    border: 2px solid #BFBFBF;
    border-radius: 30upx;

    .agent-item {
      &:nth-child(n+2) {
        margin-top: 42upx;
      }

      .company {
        font-size: 26upx;
      }

      .phone-btn {
        color: #5CA722;
        font-size: 23upx;
        //line-height: 36upx;
        //width: 110upx;
        //height: 34upx;
        border: 1px solid #5CA722;
        border-radius: 17upx;
        padding: 0 6upx;
      }
    }

    &.no-data {
      padding: 57upx 24upx;
      position: relative;

      .company {
        text-indent: 60upx;
        font-weight: bold;
        color: #5CA722;
        line-height: 40upx;
      }

      .phone-btn {
        position: absolute;
        right: 35upx;
        bottom: 34upx;
      }
    }
  }
  .province-head {
    padding: 36upx 36upx 0;
    line-height: 100upx;
    font-weight: bold;
    font-size: 40upx;
    position: relative;
    .close-btn {
      position: absolute;
      right: 36upx;
      top: 30upx;
      width: 47upx;
      height: 47upx;
    }
  }
  .province-list {
    padding: 0 29upx 26upx;
    max-height: 40vh;
    .province-item-warp {
      display: inline-block;
      width: calc(100% / 6);
      padding: 9upx;
      .province-item {
        height: 59upx;
        font-size: 24upx;
        font-weight: 400;
        color: #8A8A8A;
        line-height: 62upx;
        text-align: center;
        border-radius: 10upx;
        border: 2upx solid #8A8A8A;
        &.active {
          transition: all 100ms;
          background-color: #5CA722;
          border-color: #5CA722;
          color: #FFFFFF;
        }
      }
    }
  }
}
</style>
