var components;
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-uni-view',{staticClass:"u-mask",class:{
		'u-mask-zoom': _vm.zoom,
		'u-mask-show': _vm.show
	},style:([_vm.maskStyle, _vm.zoomStyle]),attrs:{"hover-stop-propagation":true},on:{"touchmove":function($event){$event.stopPropagation();$event.preventDefault();
arguments[0] = $event = _vm.$handleEvent($event);
(function () {}).apply(void 0, arguments)
},"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.click).apply(void 0, arguments)
}}},[_vm._t("default")],2)}
var recyclableRender = false
var staticRenderFns = []

export { render, staticRenderFns, recyclableRender, components }