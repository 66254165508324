var components;
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-uni-view',{staticClass:"item bg-white",on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.viewTo(_vm.newsList.d)
}}},[_c('v-uni-view',{staticClass:"itemView posR",style:(_vm.itemViewStyle)},[[(!_vm.playVideo && !_vm.isPc)?_c('lazy-load',{staticClass:"itemImg",attrs:{"loading-img":"http://static.jiuzhouauto.com/h5_image/loading_hori.jpg","error-img":"http://static.jiuzhouauto.com/h5_image/loading_failed_hori.jpg","img-mode":"aspectFill","height":_vm.bignewsH + 'PX',"image":_vm.newsList.d.view}}):(!_vm.playVideo && _vm.isPc)?_c('v-uni-image',{style:(_vm.imgH),attrs:{"mode":"aspectFill","src":_vm.newsList.d.view}}):_vm._e()],(_vm.playVideo)?_c('v-uni-video',{staticStyle:{"width":"100%","height":"100%"},attrs:{"autoplay":true,"src":_vm.videoUrl,"title":_vm.newsList.d.title,"poster":_vm.newsList.d.view,"id":'myVideo' + _vm.newsList.d.id},on:{"ended":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.handleEnd).apply(void 0, arguments)
},"pause":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.handlepause).apply(void 0, arguments)
},"waiting":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.handleWait).apply(void 0, arguments)
},"error":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.handleError).apply(void 0, arguments)
},"loadedmetadata":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.handleLoad).apply(void 0, arguments)
},"click":function($event){$event.stopPropagation();
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.toClickVideo).apply(void 0, arguments)
}}}):_vm._e(),(_vm.newsList.d.media && !_vm.playVideo)?_c('v-uni-view',{staticClass:"videoBtn",on:{"click":function($event){$event.stopPropagation();
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.toPlay).apply(void 0, arguments)
}}},[_c('img',{attrs:{"src":"http://static.jiuzhouauto.com/h5_image/video_log.png","alt":""}}),(_vm.newsList.d.media && _vm.newsList.d.live == '1' && _vm.videoError)?_c('v-uni-view',{staticClass:"error-text"},[_vm._v("直播已暂停，请稍后回来")]):_vm._e()],1):_vm._e(),(_vm.newsList.d.media && _vm.newsList.d.live != '1' && !_vm.playVideo)?_c('v-uni-view',{staticClass:"videoTime text-xs"},[_c('img',{attrs:{"src":"http://static.jiuzhouauto.com/h5_image/video_time.png","alt":""}}),_c('v-uni-text',[_vm._v(_vm._s(_vm.formatSeconds(_vm.newsList.d.duration)))])],1):_vm._e(),(_vm.newsList.d.media && _vm.newsList.d.live == '1' && !_vm.playVideo)?_c('v-uni-view',{staticClass:"videoLive"},[_c('img',{staticClass:"video-live-img",attrs:{"src":"http://static.jiuzhouauto.com/h5_image/living.png","alt":""}})]):_vm._e()],2),_c('v-uni-view',{staticClass:"flex padding-tb-sm border_b"},[(_vm.newsList.d.vlogo)?_c('v-uni-image',{staticClass:"itemVlogo bg-img",attrs:{"src":_vm.newsList.d.vlogo,"mode":"widthFix"}}):_c('v-uni-image',{staticClass:"itemVlogo bg-img",attrs:{"src":"http://static.jiuzhouauto.com/h5_image/info.png","mode":"widthFix"}}),_c('v-uni-view',{staticClass:"padding-left-sm flex-sub"},[_c('v-uni-view',{staticClass:"itemTitle"},[_vm._v(_vm._s(_vm.newsList.d.title))]),_c('v-uni-view',{staticClass:"text-xs text-gray padding-top-xs"},[_vm._v(_vm._s(_vm.newsList.d.auth)+"  阅读"+_vm._s(_vm.newsList.d.pv)+"  "+_vm._s(_vm.newsList.d.createdOn))])],1)],1)],1)}
var recyclableRender = false
var staticRenderFns = []

export { render, staticRenderFns, recyclableRender, components }