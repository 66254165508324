/*
 * @Descripttion: 
 * @Date: 2020-08-11 12:46:31
 * @LastEditTime: 2024-08-29 18:37:48
 */
import Vue from 'vue'
import Vuex from 'vuex'
import BusinessCard from './BusinessCard/index'
import share from './share/index'
import corpFavorite from './corpFavorite/index'
Vue.use(Vuex)

export default new Vuex.Store({
    strict: !(process.env.NODE_ENV === 'production'),
    modules: {
        BusinessCardModule: BusinessCard,
        share,
        corpFavorite
    }
})