<template>
	<view class="text-black">
		<view class="padding-lr padding-tb-xs bgf1 text-sm chatItem" v-if="formdata.d.unionid != this.userinfo">
			<view class="text-center chatDate">
				<text class="bg9 borderR5 text-xs disIB margin-tb-xs">{{$util.formatMsgTime(formdata.d.createdAt)}}</text>
			</view>
			<view class="flex">
        <image :src="formdata.d.headimgurl" mode="" style="width:100upx;height: 100upx;"
             class="borderR5 margin-right-sm chatAvatar"/>
				<view style="max-width: calc(100% - 120upx)" class="flex-sub">
					<view class="chatNickname text-gray">{{formdata.d.nickname}}</view>
					<view class="chatContent">
						<text class="chat chatY">{{formdata.d.content}}</text>
					</view>
				</view>
			</view>
		</view>
		<view class="padding-lr padding-tb-xs bgf1 text-sm chatItem" v-else>
			<view class="text-center chatDate">
				<text class="bg9 borderR5 text-xs disIB margin-tb-xs">{{$util.formatMsgTime(formdata.d.createdAt)}}</text>
			</view>
			<view class="flex text-right">
				<view style="max-width: calc(100% - 120upx)" class="flex-sub">
					<view class="chatNickname text-gray">{{formdata.d.nickname}}</view>
					<view class="chatContent">
						<text class="chat chatM">{{formdata.d.content}}</text>
					</view>
				</view>
				<image :src="formdata.d.headimgurl" mode="" style="width:100upx;height: 100upx;" class="borderR5 margin-left-sm chatAvatar" />
			</view>
		</view>
	</view>
</template>

<script>
	export default {
		behaviors:['uni://form-field'],
		props: {
			formdata: {
				type: Object,
				default: {}
			}
		},
		data () {
			return {
				userinfo:[]
			}
		},
		methods:{
		},
		async created() {
		}
	}
</script>

<style scoped scoped>
	.bg9{
		background-color: #ccc;
		color: #fff;
		padding: 2px 6px;
	}
	.bgf1{
		background-color: #f1f1f1;
	}
	.chat{
		background-color: #fff;
		padding: 20upx 16upx;
		position: relative;
		margin-top: 10upx;
		border-radius: 3px;
    word-break: normal;
    max-width: 94%;
    display: inline-block;
    white-space: pre-wrap;
    word-wrap: break-word;
	}
	.chatY:before{
    position: absolute;
    top: 10upx;
    left: -12upx;
		content: "";
		border-top: solid 10upx transparent;
		border-left: solid 0 transparent;
		border-right: solid 12upx #FFFFFF;
		border-bottom: solid 10upx transparent;
	}
	.chatM{
		background-color: #84e03f;
	}
	.chatM:before{
    position: absolute;
    top: 10upx;
    right: -12upx;
		content: "";
		border-top: solid 10upx transparent;
		border-left: solid 12upx #84e03f;
		border-right: solid 0 transparent;
		border-bottom: solid 10upx transparent;
	}
</style>
