<template>
  <view :class="['swiper-container-'+mySwiperId]">
    <view class="swiper-wrapper" ref="swiperwrap">
      <slot/>
    </view>
  </view>
</template>

<script>
import Swiper from 'swiper'
import md5 from 'js-md5'
export default {
  name: "PcScrollBar",
  props: {
    initDelay: {
      type: Number,
      default: 300
    },
    curIndex: {
      type: Number,
      default: 0
    },
    speed: {
      type: Number,
      default: 300
    },
    autoplay: {
      type: Boolean,
      default: false
    },
    slidesPerView: {
      type: [String, Number],
      default: 'auto'
    },
    spaceBetween: {
      type: Number,
      default: 10
    },
    freeMode: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      mySwiperId: '',
      mySwiper: null,
      active: 0,
      count: 0
    }
  },
  watch: {
    curIndex(newVal) {
      this.slideTo(newVal)
    }
  },
  methods: {
    layout() {
      let items = this.$refs.swiperwrap.children;
      this.count = items.length;
    },
    slideTo(index) {
      if (index === this.active) return false
      this.active = index
      this.mySwiper.slideTo(index, this.speed, false)
    },
    initSwiper() {
      setTimeout(() => {
        this.mySwiper = new Swiper(`.swiper-container-${this.mySwiperId}`, {
          observer: true,
          setWrapperSize: true,
          autoHeight: true,
          speed: this.speed,
          width: getApp().globalData.systemInfo.windowWidth,
          autoplay: this.autoplay,
          slidesPerView: this.slidesPerView,
          spaceBetween: this.spaceBetween,
          freeMode: this.freeMode,
          on:{
            beforeDestroy: function(){}
          },
        });
      }, this.initDelay)
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initSwiper()
    })
  },
  created() {
    this.mySwiperId = md5((new Date().getTime()).toString() + (Math.random()*1000).toString())
  },
  beforeDestroy() {
    this.mySwiper = null
  }
}
</script>

<style lang="scss" scoped scoped>
.swiper-container {
  cursor: grab;
}
.swiper-wrapper {
  ::v-deep .swiper-slide {
    display: inline-block;
  }
}
.swiper-slide {
  margin: 0;
}
</style>
