<template>
  <!-- 主体部分 -->
  <scroll-view
    scroll-y
    class="main forPC"
    v-if="mainData.length > 0 && dataShow"
    :refresher-triggered="triggered"
    :style="[
      {
        bottom: mainBottom + 'px',
        top: getMainTop + 'px',
        backgroundColor: mainBgColor,
        height: height
      }
    ]"
    scroll-with-animation
    @refresherrefresh="handleRefresher"
    :refresher-enabled="true"
    @scrolltolower="lower"
    @scroll="scroll"
    @scrolltoupper="upper"
    lower-threshold="500"
    :scroll-top="scrollTop"
  >
    <view
      class="main-container"
      :style="{
        'padding-bottom': paddingBottom
      }"
    >
      <template v-for="(item,index) in mainData">
        <!-- 走马灯组件-->
        <type-carousel v-if="item.t === 'carousel'" v-bind:formdata="item" :key="JSON.stringify(item) + index"></type-carousel>

        <!-- 可滑动网格组件 -->
        <type-gridScroll v-else-if="item.t === 'gridScroll'" v-bind:formdata="item" :key="JSON.stringify(item) + index"></type-gridScroll>

        <!-- 网格组件-->
        <type-grid v-else-if="item.t === 'grid'" v-bind:formdata="item" :key="JSON.stringify(item) + index"></type-grid>

        <!-- 大图文章组件-->
        <type-bignews v-else-if="item.t === 'bignews'" v-bind:formdata="item" :key="JSON.stringify(item) + index"></type-bignews>

        <!-- 瀑布流组件-->
        <type-halfImgList
          v-else-if="item.t === 'halfImgList'"
          v-bind:formdata="item"
          :key="'halfImgList' + index"
        ></type-halfImgList>

        <!-- 双图组件-->
        <type-halfImg
          class="waterfall"
          v-else-if="item.t === 'halfImg' && item.s.height"
          v-bind:formdata="item"
          :key="JSON.stringify(item) + index"
        ></type-halfImg>

        <!-- 图片组件-->
        <template v-else-if="item.t === 'pic'">
          <type-pic v-bind:formdata="item" :key="item.d.img"></type-pic>
        </template>
        <!-- 聊天内容组件-->
        <type-chatMsg v-else-if="item.t === 'chatMsg'" v-bind:formdata="item" :key="JSON.stringify(item) + index"></type-chatMsg>

        <!-- 企业信息收藏组件-->
        <type-entFavorite
          v-else-if="item.t === 'entFavorite'"
          v-bind:formdata="item"
          :followed="item.followBooth"
          :key="JSON.stringify(item) + index"
        ></type-entFavorite>

        <!-- 新企业收藏组件-->
        <type-entNewFavorite  
          v-else-if="item.t === 'entNewFavorite'"
          :hidingModel="item.s.hidingModel"
          :scrollTop="old.scrollTop"
          v-bind:formdata="item" 
          :key="JSON.stringify(item) + index">
        </type-entNewFavorite>

        <!-- 展位息收藏组件-->
        <type-boothFavorite
          v-else-if="item.t === 'boothFavorite'"
          v-bind:formdata="item"
          :followed="item.followBooth"
          :key="JSON.stringify(item) + index"
        ></type-boothFavorite>

        <!-- 视频组件-->
        <type-videoBlock
          v-else-if="item.t === 'videoBlock'"
          v-bind:formdata="item"
          :key="JSON.stringify(item) + index"
          :page-id="pageId"
        ></type-videoBlock>

        <!-- 计数组件-->
        <type-counter
          v-else-if="item.t === 'counter'"
          v-bind:formdata="item"
          :key="JSON.stringify(item) + index"
          :page-id="pageId"
        ></type-counter>

        <!-- 项目列表组件-->
        <type-itemList v-else-if="item.t === 'itemList'" v-bind:formdata="item" :key="JSON.stringify(item) + index"></type-itemList>

        <!-- 名片组件-->
        <type-business-card
          v-else-if="item.t === 'entCard'"
          v-bind:formdata="item"
          :key="JSON.stringify(item) + index"
        ></type-business-card>

        <!-- 展位滑动组件-->
        <type-booth-carousel
          v-else-if="item.t === 'boothCarousel'"
          v-bind:formdata="item"
          :key="JSON.stringify(item) + index"
        ></type-booth-carousel>

        <!-- 标准滑动组件-->
        <type-std-carousel
          v-else-if="item.t === 'stdCarousel'"
          v-bind:formdata="item"
          :key="JSON.stringify(item) + index"
        ></type-std-carousel>

        <!-- 文本行组件-->
        <type-text-box v-else-if="item.t === 'textBox'" v-bind:formdata="item" :key="JSON.stringify(item) + index"></type-text-box>

        <!-- 推荐经销商组件-->
        <type-agent v-else-if="item.t === 'agents'" v-bind:formdata="item" :key="JSON.stringify(item) + index"></type-agent>

        <!-- 求购过滤组件-->
        <type-purchaseFilter
          v-else-if="item.t === 'purchaseFilter'"
          v-bind:formdata="item"
          :page-id="pageId"
          :key="JSON.stringify(item) + index"
        ></type-purchaseFilter>

        <!-- 求购信息组件-->
        <type-purchase
          v-else-if="item.t === 'purchase'"
          :purchaseData="item.d"
          :show-bottom-bar="false"
          :key="JSON.stringify(item) + index"
        ></type-purchase>

        <!-- 求购回复组件-->
        <type-purchaseQuote
          v-else-if="item.t === 'purchaseQuote'"
          :quotation-data="item.d"
          :key="JSON.stringify(item) + index"
        ></type-purchaseQuote>
        <template v-else></template>
      </template>
    </view>
    <view class="bottom-block"></view>
  </scroll-view>
</template>

<script>
export default {
  props: {
    pageId: {
      type: String,
      default: ""
    },
    isIndex: {
      type: Boolean,
      default: false
    },
    mainData: {
      type: Array,
      default() {
        return [];
      }
    },
    mainBottom: {
      type: Number | String,
      default: 0
    },
    mainTop: {
      type: Number | String,
      default: 0
    },
    mainBgColor: {
      type: String,
      default: "#fffff"
    },
    mainPaddingBottom: {
      type: Number | String,
      default: 0
    },
    height: {
      type: Number | String,
      default: "auto"
    }
  },
  watch: {
    mainData(val) {
      // this.dataShow = false;
      // this.$nextTick(()=>{
      //   this.dataShow = true;
      // })
      // if (!this.isIndex) {
      //   this.scrollTop = this.old.scrollTop;
      //   this.$nextTick(function() {
      //     // this.scrollTop = 0;
      //     this.dataShow = false;
      //     setTimeout(() => {
      //       this.dataShow = true;
      //       // this.scrollTop = this.old.scrollTop
      //     }, 0);
      //   });
      // } else {
      //   this.dataShow = true;
      // }
    }
  },
  data() {
    return {
      dataShow: true,
      scrollTop: 0,
      old: {
        scrollTop: 0
      },
      followBooth: true,
      triggered: false
    };
  },
  computed: {
    radomStr() {
      
      return new Date().getTime();
    },
    paddingBottom() {
      return uni.getSystemInfoSync().windowBottom + this.mainPaddingBottom + "px";
    },
    getMainTop() {
      return uni.upx2px(this.mainTop);
    }
  },
  methods: {
    handleRefresher() {
      console.log("刷新");
      this.triggered = true;
      this.$emit("refresh");
      setTimeout(() => {
        this.triggered = false;
      }, 2000);
    },
    lower(e) {
      console.log("触发lower");
      this.$emit("lower", e);
    },
    upper(e) {
      this.$emit("upper", e);
      console.log("触发");
    },
    scroll(e) {
      this.old.scrollTop = e.detail.scrollTop;
      this.$emit("scroll", e);
      uni.$emit('scrollLoadImage');
    }
  }
};
</script>

<style scoped scoped>
.main {
  position: relative;
}
.bottom-block {
  width: 100%;
  height: 30upx;
}
</style>
