var components;
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-uni-view',[(_vm.cardData.d.mySwapCard && _vm.cardData.d.mySwapCard === 1)?_c('swipe-action',{attrs:{"show":_vm.cardData.d.show,"options":_vm.swipeAction},on:{"content-click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.clickCard).apply(void 0, arguments)
},"open":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.openSwipe).apply(void 0, arguments)
}},scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('v-uni-view',{staticClass:"delBtn flex justify-center align-center",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.deleteCard).apply(void 0, arguments)
}}},[_c('v-uni-image',{staticClass:"image",attrs:{"src":"http://static.jiuzhouauto.com/h5_image/bus_card/delete_card.png","mode":"aspectFit"}})],1)]},proxy:true}],null,false,3130843782)},[_c('v-uni-view',{staticStyle:{"padding":"20rpx 30rpx","height":"100%","box-sizing":"border-box"}},[_c('bus-card',{attrs:{"showEnt":_vm.showEnt,"card-data":_vm.cardData,"showNameCardImg":_vm.showNameCardImg,"showNameCard":true,"hideNoneData":true,"hidePhone":_vm.isMyCard ? false : !_vm.exchanged,"hideEmail":_vm.isMyCard ? false : !_vm.exchanged,"isMyCard":_vm.isMyCard}})],1)],1):_c('bus-card',{attrs:{"showNameCard":true,"showNameCardImg":_vm.showNameCardImg,"showEnt":_vm.showEnt,"card-data":_vm.cardData,"hideNoneData":true,"hidePhone":_vm.isMyCard ? false : !_vm.exchanged,"hideEmail":_vm.isMyCard ? false : !_vm.exchanged,"isMyCard":_vm.isMyCard},nativeOn:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.exchangeCard).apply(void 0, arguments)
}}},[(!_vm.isMyCard && !_vm.cardData.d.mySwapCard && _vm.showExchange)?_c('v-uni-view',{staticClass:"exchange flex justify-start align-center bg-white",attrs:{"slot":"button"},slot:"button"},[_c('v-uni-view',{staticClass:"exchangeCard",class:[_vm.exchanged ? '' : 'active']},[_vm._v(_vm._s(_vm.exchanged ? _vm.$t("entCard.ent_card_list.已交换") : _vm.$t("entCard.ent_card_list.交换名片")))])],1):_vm._e()],1)],1)}
var recyclableRender = false
var staticRenderFns = []

export { render, staticRenderFns, recyclableRender, components }