const state = {
  myCardId: [],
  myExchangedCardId: []
}

const actions = {
  setCardArray({commit}, cardArray) {
    commit('SET_MY_CARD_ARRAY', cardArray)
  },
  addMyCardId({commit, state}, cardId) {
    if (!state.myCardId.some(item => item === cardId)) {
      commit('ADD_MY_CARD_ID', cardId)
    }
  },
  // delMyCardId({commit}, cardId) {
  //   if (!state.myCardId.some(item => item === cardId)) {
  //     commit('DEL_MY_CARD_ID', cardId)
  //   }
  // },
  setMyExCardArray({commit}, cardArray) {
    commit('SET_EX_CARD_ARRAY', cardArray)
  },
  addMyExCardId({commit, state}, cardId) {
    if (!state.myExchangedCardId.some(item => item === cardId)) {
      commit('ADD_MY_EX_CARD_ID', cardId)
    }
  },
  delMyExCardId({commit, state}, cardId) {
    if (state.myExchangedCardId.some(item => item === cardId)) {
      commit('DEL_MY_EX_CARD_ID', cardId)
    }
  }
}

const mutations = {
  SET_MY_CARD_ARRAY(state, cardArray) {
    state.myCardId = cardArray
  },
  ADD_MY_CARD_ID(state, cardId) {
    state.myCardId.push(cardId)
  },
  // DEL_MY_CARD_ID(state, cardId) {
  //   let index = state.myCardId.findIndex(item => item === cardId)
  //   let cardArray = state.myCardId
  //   cardArray.splice(index, 1)
  //   state.myCardId = cardArray
  // },
  SET_EX_CARD_ARRAY(state, cardArray) {
    state.myExchangedCardId = cardArray
  },
  ADD_MY_EX_CARD_ID(state, cardId) {
    state.myExchangedCardId.push(cardId)
  },
  DEL_MY_EX_CARD_ID(state, cardId) {
    let index = state.myExchangedCardId.findIndex(item => item === cardId)
    let cardArray = state.myExchangedCardId
    cardArray.splice(index, 1)
    state.myExchangedCardId = cardArray
  }
}

const getter = {}

export default {
  namespace: true,
    state,
  mutations,
  getter,
  actions
}
