var components;
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-uni-view',{staticClass:"videoWarp item bg-white"},[_c('v-uni-view',{class:'video-' + _vm.videoId},[(_vm.showVideo)?_c('v-uni-video',{style:(_vm.videoStyle),attrs:{"autoplay":_vm.formatValue(_vm.newsList.d.autoplay, true),"loop":_vm.formatValue(_vm.newsList.d.loop, true),"src":_vm.newsList.d.video,"id":_vm.videoId,"object-fit":_vm.videoObjectFit,"poster":_vm.newsList.d.view,"controls":true,"preload":"none"},on:{"play":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.videoPlay).apply(void 0, arguments)
},"fullscreenchange":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.videoFullScreen).apply(void 0, arguments)
}}}):_vm._e()],1)],1)}
var recyclableRender = false
var staticRenderFns = []

export { render, staticRenderFns, recyclableRender, components }