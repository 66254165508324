// 需要拦截的函数
const fnList = ['navigateTo', 'redirectTo', 'reLaunch', 'switchTab', 'navigateBack']
export function initInteceptor() {
    fnList.forEach(fnName => {
        uni.addInterceptor(fnName, {
            success() {

                setTimeout(() => {
                    const pathname = window.location.href
                    const list = ['pages/info/index', 'pages/pages/index', 'pages/quotation/publish-quotation', 'pages/search/search_new', 'pages/supplier/index']
                    if (list.every(path => !pathname.includes(path))) {
                        // 打点
                        console.log('打点')
                        _paq.push(['deleteCustomVariables', 'page']);
                        _paq.push(['setCustomUrl', window.location.href]);
                        _paq.push(['trackPageView']);
                    }
                }, 100);

            }
        })
    })
}