import { render, staticRenderFns, recyclableRender, components } from "./index.vue?vue&type=template&id=45645272&scoped=true&"
var renderjs
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@dcloudio/vue-cli-plugin-uni/packages/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45645272",
  null,
  false,
  components,
  renderjs
)

export default component.exports