<template>
  <view class="col-box" v-if="lists.length > 0">
    <template v-for="(item, index) in lists">
      <view class="col" :class="[`col_${index}`]" :key="index">
        <template v-for="(info, idx) in item">
          <slot v-bind:info="info" :idx="idx" :colIdx="index" :pageInfo="pageInfo" />
        </template>
      </view>
    </template>
  </view>
</template>

<script>
import md5 from "js-md5";
import cardItem from "@/components/cardItem";
export default {
  data: () => ({
    lists: [],
    timeout: null,
		oldDataList: [],
  }),
  components: { cardItem },
  props: {
    pageInfo: {
      type: Object,
      default: () => ({})
    },
    column: {
      type: Number,
      default: 2
    },
    dataList: {
      type: Array,
      default: []
    }
  },
  watch: {
    dataList: {
      immediate: true,
      handler:function(list,oldList) {
        if (list.length > 0) {
          this.initLists();
        }
      }
    }
  },
  methods: {
    initLists() {
			// 当列宽不等时，重置列表
			if(this.lists.length!=this.column){
				this.lists = [];
				this.OldList = [];
				for (let i = 0; i < this.column; i++) {
					this.lists.push([]);
				}
			}
      this.formatList(this.dataList,this);
    },
    getHeight(id) {
      return new Promise(resolve => {
        this.$nextTick(() => {
          const query = uni.createSelectorQuery().in(this);
          query.select(`.col_${id}`).boundingClientRect(rect => {
            resolve(rect?.height ?? 0);
          });
          query.exec();
        });
      });
    },
    async waitForRender() {
      return new Promise(resolve => {
        this.$nextTick(() => {
          clearTimeout(this.timeout);
          this.timeout = setTimeout(() => {
            resolve();
          }, 50);
        });
      });
    },
    async getMinHeightCol() {
      let res = 0;
      let minHeight = 0;
      for (let i = 0; i < this.column; i++) {
        const height = await this.getHeight(i);
        if (i === 0) {
          minHeight = height;
        }
        if (height === 0) {
          res = i;
          break;
        }
        if (height < minHeight) {
          res = i;
          minHeight = height;
        }
      }
      return res;
    },
    async formatList(dataList,that) {
      let _list = dataList;
			// 比较与旧列表的差异
			let sameNo = -1;
			for(let i in _list){
				const iMd5=md5(JSON.stringify(_list[i]))
				sameNo=i
				if(that.oldDataList[i]!==iMd5) {
					// 若不同，则删除OldDataList后面的内容，并开始添加
					break;
				};
			}
			// 若完全不同，则清空旧列表和现有列表
			if(sameNo===-1){
				that.oldDataList = []
				that.lists = []
				for (let i = 0; i < that.column; i++) {
					that.lists.push([]);
				}
				sameNo = 0
			}
			await that.waitForRender();
      for (let i = sameNo; i < _list.length; i++) {
        let idx = await that.getMinHeightCol();
        that.lists[idx].push(_list[i]);
				that.oldDataList.push(md5(JSON.stringify(_list[i])))
        await that.waitForRender();
      }
     //  // #ifdef MP-WEIXIN
     // // 解决小程序渲染出错问题
     //  for (let i = 0; i < that.lists.length; i++) {
     //    let l = [];
     //    that.lists[i].forEach(item => {
     //      if (l.length === 0) {
     //        l.push(item);
     //      } else if (!l.some(t => t.d.title === item.d.title)) {
     //        l.push(item);
     //      }
     //    });
     //    that.lists[i] = l;
     //  }
     //  that.lists = that.lists;
     //  // this.$forceUpdate();
     //  // #endif
    }
  }
};
</script>

<style lang="scss" scoped scoped>
.col-box {
  width: 100%;
}
.col {
  vertical-align: top;
  width: 50%;
  display: inline-block;
}
</style>
