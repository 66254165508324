<template>
  <view>
    <view class="type-halfImgItem" @tap="toPage(picData)" v-if="picData.d.img && parseInt(picData.d.type) != 4">
      <view class="type-halfImgItemWarp">
        <view :style="{ height: imgHeight }" class="imgBg1Block">
          <!--         <image class="imgBg1" :src="picData.d.img" style="height: imgHeight"></image>-->
          <lazy-load
            :image="picData.d.img"
            class="imgBg1"
            :borderTopRadius="8"
            :height="imgHeight"
            loading-img="http://static.jiuzhouauto.com/h5_image/loading_squ.jpg"
            @load="loadFinish"
            error-img="http://static.jiuzhouauto.com/h5_image/loading_failed_squ.jpg"
            img-mode="aspectFill"
          ></lazy-load>
        </view>
        <!-- <view :style="{backgroundImage:'url(' + picData.d.img + ')', paddingTop: picData.s.height*100+'%'}" class="imgBg2"></view>-->
        <!-- <img :src="picData.d.img" mode="" style="padding-bottom:20%;width:100%;"></img> -->
        <!-- <image src="http://static.jiuzhouauto.com/h5_image/bg50.png" class="posT" />-->
        <view v-if="parseInt(picData.d.type) !== 2">
          <view
            v-if="
              parseInt(picData.d.type) === 3 &&
                (picData.d.title || picData.d.desc || picData.d.logo || picData.d.company)
            "
            class="text-black"
          >
            <view
              v-if="picData.d.title"
              class="halfImg-title text-justify padding-tb-xs text-bold text-cut"
              style="line-height: 60upx;"
              >{{ picData.d.title }}</view
            >
            <view
              v-if="picData.d.desc"
              class="text-justify text-sm over-hidden"
              style="height: 64upx;-webkit-box-orient: vertical;-webkit-line-clamp: 2;display: -webkit-box;"
              >{{ picData.d.desc }}</view
            >
            <view class="flex align-center padding-tb-xs" v-if="picData.d.logo || picData.d.company">
              <image v-if="showLogo" :src="picData.d.logo" mode="" class="logo" />
              <view v-if="picData.d.company" class="text-xs text-cut text-gray">{{ picData.d.company }}</view>
            </view>
          </view>
          <view class="tag" :style="{ bottom: parseInt(picData.d.type) === 3 ? '38%' : '24%' }">
            <view class="btn btn1" v-if="picData.d.redtag">{{ picData.d.redtag }}</view>
            <view class="btn btn2" v-else-if="picData.d.tag">{{ picData.d.tag }}</view>
          </view>
        </view>
        <view v-if="parseInt(picData.d.type) === 2">
          <view class="halfImg-title text-justify padding-sm text-bold text-cut" :style="titleStyle">{{
            picData.d.title
          }}</view>
          <view class="footer" v-if="picData.d.redtag || picData.d.tag">
            <view class="btn btn1" v-if="picData.d.redtag">{{ picData.d.redtag }}</view>
            <view class="btn btn2" v-else-if="picData.d.tag">{{ picData.d.tag }}</view>
          </view>
        </view>
      </view>
    </view>
    <view class="type-halfImgItemWarp3" @tap="toPage(picData)" v-if="picData.d.img && parseInt(picData.d.type) == 4">
      <view :style="{ height: imgHeight }" class="imgBg1Block">
        <lazy-load
          :image="picData.d.img"
          class="imgBg1"
          :borderTopRadius="8"
          :height="imgHeight"
          loading-img="http://static.jiuzhouauto.com/h5_image/loading_squ.jpg"
          @load="loadFinish"
          error-img="http://static.jiuzhouauto.com/h5_image/loading_failed_squ.jpg"
          img-mode="aspectFill"
        ></lazy-load>
      </view>
      <view>
        <view v-if="picData.d.title || picData.d.desc || picData.d.logo || picData.d.company" class="text-black">
          <view
            v-if="picData.d.title"
            class="halfImg-title text-justify padding-tb-xs text-cut"
            style="line-height: 60upx;"
            >{{ picData.d.title }}</view
          >
          <view
            v-if="picData.d.desc"
            class="text-justify text-sm over-hidden"
            style="height: 64upx;-webkit-box-orient: vertical;-webkit-line-clamp: 2;display: -webkit-box;"
            >{{ picData.d.desc }}</view
          >
          <view class="flex align-center padding-tb-xs" v-if="picData.d.logo || picData.d.company">
            <image v-if="showLogo" :src="picData.d.logo" mode="" class="logo" />
            <view v-if="picData.d.company" class="text-xs text-cut text-gray">{{ picData.d.company }}</view>
          </view>
        </view>
        <view class="tag" :style="{ bottom: parseInt(picData.d.type) === 3 ? '38%' : '24%' }">
          <view class="btn btn1" v-if="picData.d.redtag">{{ picData.d.redtag }}</view>
          <view class="btn btn2" v-else-if="picData.d.tag">{{ picData.d.tag }}</view>
        </view>
      </view>
    </view>
  </view>
</template>

<script>
import LazyLoad from "@/components/common/LazyLoad";
import { isUrlAddress } from "@/common/validate/url";
export default {
  components: {
    "lazy-load": LazyLoad
  },
  behaviors: ["uni://form-field"],
  props: {
    formdata: {
      type: Object,
      default: {}
    },
    idx: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      picData: {},
      showLogo: false
    };
  },
  watch: {
    formdata(val) {
      if (Object.keys(this.picData).length) {
        this.picData = val;
      }
    }
  },
  computed: {
    imgHeight() {
      return `${this.picData.s.height * ((getApp().globalData.systemInfo.windowWidth - uni.upx2px(90)) / 2)}px`;
    },
    boxH() {
      if (this.picData.s.height) {
        return (this.picData.s.height * getApp().globalData.systemInfo.windowWidth) / 2;
      }
      return 0;
    },
    titleStyle() {
      if (this.picData && this.picData.d.type && this.picData.d.type === 2) {
        return "line-height: normal;overflow: hidden;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;padding: 0;margin: 10px;text-overflow: unset;white-space: unset;";
      } else {
        return "";
      }
    }
  },
  methods: {
    toPage(obj) {
      console.log(obj);
      if (obj.d.app) {
        this.toGetPage(obj.d.app);
      }
    },
    loadFinish() {
      this.showLogo = true;
    },
    // 获取jiuzhouauto
    async toGetPage(obj) {
      let a = await this.$util.gotoApp({
        type: 1,
        app: obj
      });
      if (a) {
        await this.$util.gotoPage(a);
      } else {
        if (isUrlAddress(obj)) {
          getApp().globalData.appLocation.href = obj;
        }
      }
    }
  },
  created() {
    this.picData = this.formdata;
    Object.freeze(this.picData);
    // if (this.picData.s.height) {
    //   this.boxH = this.picData.s.height * getApp().globalData.systemInfo.windowWidth / 2
    // }
  }
};
</script>

<style scoped scoped>
.type-halfImgItemWarp3 {
  border-radius: 8upx;
  box-shadow: 0 0 5px rgb(214, 214, 214);
  break-inside: auto;
  box-sizing: border-box;
  overflow: hidden;
}
.newtype {
  margin-left: 10upx;
  margin-right: 19upx;
}
.type-halfImgItem {
  border-radius: 8upx;
  border: 2upx solid rgb(204, 204, 204);
  break-inside: auto;
  box-sizing: border-box;
  overflow: hidden;
}

.type-halfImgItemWarp {
  position: relative;
}

.imgBg1Block {
  background-color: #eee;
  position: relative;
}

.imgBg1 {
  width: 100%;
  height: 100%;
  border-radius: 4px 4px 0 0 !important;
  /*background-repeat: no-repeat;*/
  /*background-position: center center;*/
  /*background-size: cover;*/
  /*background-color: #FFFFFF;*/
  /*width: 100%*/
}

.posT {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.posFB {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.typeBlock2 {
}

.footer {
}

.footer .btn {
  float: right;
  border-radius: 15px;
  background: #f00;
  margin: 0 10px 10px;
  padding: 3px 10px;
  color: #fff;
}

.footer .btn2 {
  background-color: transparent;
  border: 1px solid #999;
  box-sizing: border-box;
  color: #999;
}

.tag {
  position: absolute;
  bottom: 36%;
  left: 0;
  right: 0;
}

.tag .btn {
  border-radius: 15px;
  background: #f00;
  margin: 0 auto;
  padding: 3px 10px;
  color: #fff;
  width: 36%;
  text-align: center;
  font-size: 16px;
}

.tag .btn2 {
  background-color: #999999;
}

.logo {
  width: 26upx;
  height: 26upx;
  background-color: #fff;
  border-radius: 20px;
  vertical-align: middle;
  margin-right: 5px;
}
.halfImg-title {
  padding-left: 10upx;
  padding-right: 10upx;
  font-size: 24upx;
}
</style>
