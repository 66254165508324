var components;
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-uni-view',{staticClass:"advisory"},[_c('v-uni-view',{class:_vm.active && 'advisory-overlay',on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.close).apply(void 0, arguments)
}}}),_c('v-uni-view',{staticClass:"advisory-dialog",class:['advisory-dialog-enter', 'advisory-dialog-leave', _vm.active && 'active']},[_c('v-uni-view',{staticClass:"advisory-dialog-header"},[_c('v-uni-view',{staticClass:"advisory-dialog-header-title"}),_c('v-uni-view',{staticClass:"advisory-dialog-header-close",on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.close).apply(void 0, arguments)
}}})],1),_c('v-uni-view',{staticClass:"advisory-dialog-content"},[_c('v-uni-form',[_c('v-uni-view',{staticClass:"advisory-dialog-content-input"},[_c('v-uni-text',[_vm._v("姓名")]),_c('v-uni-input',{attrs:{"type":"text","placeholder":"请输入真实姓名"},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})],1),_c('v-uni-view',{staticClass:"advisory-dialog-content-input"},[_c('v-uni-text',[_vm._v("手机号码")]),_c('v-uni-input',{attrs:{"type":"number","placeholder":"请输入手机号码"},model:{value:(_vm.formData.mobile),callback:function ($$v) {_vm.$set(_vm.formData, "mobile", $$v)},expression:"formData.mobile"}})],1),_c('v-uni-view',{staticClass:"advisory-dialog-content-input"},[_c('v-uni-text',[_vm._v("公司全称")]),_c('v-uni-input',{attrs:{"placeholder":"请输入公司全称"},model:{value:(_vm.formData.company),callback:function ($$v) {_vm.$set(_vm.formData, "company", $$v)},expression:"formData.company"}})],1),_c('v-uni-view',{staticClass:"advisory-dialog-content-input"},[_c('v-uni-text',[_vm._v("邮箱")]),_c('v-uni-input',{attrs:{"placeholder":"请输入邮箱号码"},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}})],1),_c('v-uni-view',{staticClass:"advisory-dialog-content-picker"},[_c('v-uni-text',[_vm._v("意向")]),_c('v-uni-picker',{attrs:{"value":_vm.formData.purpose,"range":_vm.options,"range-key":"l"},on:{"change":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.pickerChange).apply(void 0, arguments)
}}},[_c('v-uni-view',{staticClass:"advisory-dialog-content-picker-text"},[(_vm.options[_vm.formData.purpose])?_c('v-uni-view',{staticClass:"advisory-dialog-content-picker-text-black"},[_vm._v(_vm._s(_vm.options[_vm.formData.purpose].l))]):_c('v-uni-view',{staticClass:"advisory-dialog-content-picker-text-gray"},[_vm._v("请选择咨询意向")]),_c('v-uni-view',{staticClass:"advisory-dialog-content-picker-text-arrows"})],1)],1)],1),_c('v-uni-view',{staticClass:"advisory-dialog-content-input"},[_c('v-uni-text',[_vm._v("补充内容")]),_c('v-uni-input',{attrs:{"placeholder":"请输入补充内容"},model:{value:(_vm.formData.otherPurpose),callback:function ($$v) {_vm.$set(_vm.formData, "otherPurpose", $$v)},expression:"formData.otherPurpose"}})],1),_c('v-uni-view',{staticClass:"advisory-dialog-content-radio"},[_c('v-uni-radio-group',{staticStyle:{"transform":"scale(0.5)"}},[_c('v-uni-radio',{attrs:{"value":"agree","checked":_vm.agree === 'agree'},on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.toggleRadio('agree')
}}})],1),_c('v-uni-text',[_vm._v("已阅读并同意")]),_c('v-uni-text',{staticClass:"advisory-dialog-content-radio-statement",on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.policy('service')
}}},[_vm._v("《服务协议》")]),_c('v-uni-text',{staticClass:"advisory-dialog-content-radio-statement",on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.policy('privacy')
}}},[_vm._v("《隐私协议》")])],1),_c('v-uni-view',{staticClass:"advisory-dialog-content-button",on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.subForm).apply(void 0, arguments)
}}},[_vm._v("立即咨询")])],1)],1)],1)],1)}
var recyclableRender = false
var staticRenderFns = []

export { render, staticRenderFns, recyclableRender, components }