<template>
  <view class="purchase-wrap" @click="goToDetail">
    <view class="top app-flex app-flex-fs-fs">
      <view class="avatar">
        <image :src="purchaseData.headimgurl" mode="widthFix" />
      </view>
      <view class="info" :class="[canExpand ? 'padding-bottom':'']">
        <view class="nickname">{{purchaseData.nickname}}</view>
        <view class="content" :style="contentStyle">
          <text :id="`purchase-content-${purchaseData.id}`" style="user-select: auto">{{purchaseData.content}}</text>
        </view>
        <view v-if="canExpand" class="expand-btn app-flex app-flex-c-c" @click.stop="expandContent">
          <text class="text-sm">{{contentStyle ? '收起' : '点击展开'}}</text>
          <text :class="[contentStyle ? 'cuIcon-fold' : 'cuIcon-unfold']"></text>
        </view>
      </view>
    </view>
    <view v-if="purchaseData.imgs && purchaseData.imgs.length" class="flex justify-start imgList">
      <image :src="`${item}!w300`" mode="aspectFill" @click="preview(index)"
             class="img-item" v-for="(item, index) in purchaseData.imgs" :key="index"></image>
    </view>
    <view v-if="showBottomBar" class="bottom text-sm app-flex app-flex-sb-c">
      <view class="product"># {{purchaseData.prodCat.title[0]}}</view>
      <view class="region"># {{purchaseData.region.title[0]}}</view>
      <view class="comment-date">{{new Date(purchaseData.createdAt).format('yyyy-MM-dd HH:mm')}}</view>
      <view class="expiration-date">{{purchaseData.validity}}</view>
      <view class="comment app-flex">
        <image class="image" v-if="purchaseData.pv > 0" src="http://static.jiuzhouauto.com/h5_image/purchase/comment_active.png" mode="widthFix"/>
        <image class="image" v-if="purchaseData.pv <= 0" src="http://static.jiuzhouauto.com/h5_image/purchase/comment.png" mode="widthFix"/>
        <view :style="{color: purchaseData.pv > 0 ? 'red': '#8A8A8A'}" style="margin-left: 6upx" class="text-sm">{{purchaseData.pv > 999 ? '999+' : purchaseData.pv}}</view>
      </view>
    </view>
  </view>
</template>

<script>
export default {
  props: {
    purchaseData: {
      type: Object,
      default() { return {} }
    },
    showBottomBar: true
  },
  data() {
    return {
      canExpand: false,
      canPreview: false,
      contentStyle: ''
    }
  },
  methods: {
    preview(index) {
      if (this.purchaseData.canPreview) {
        let urls = this.purchaseData.imgs.map(item => item = `${item}!w750`)
        uni.previewImage({
          urls: urls,
          current: index
        })
      }
    },
    async goToDetail() {
      if (this.purchaseData.expand === 0) {
        const isLogin =  await this.$util.checkLogin()
        if (isLogin) {
          uni.navigateTo({
            url: `/pages/quotation/publish-quotation?id=${this.purchaseData.id}`
          })
        }
      }
    },
    expandContent() {
      if (this.canExpand) {
        let style = ''
        if (!this.contentStyle) {
          style = 'overflow: auto;max-height: unset;-webkit-box-orient:unset;-webkit-line-clamp:unset;padding-bottom: 30upx';
        }
        this.contentStyle = style
      }
    }
  },
  mounted() {
    if (this.purchaseData.expand === 1) {
      const query = uni.createSelectorQuery().in(this);
      query.select(`#purchase-content-${this.purchaseData.id}`).boundingClientRect(data => {
        if (data.height > uni.upx2px(42 * 3)) {
         this.canExpand = true
        }
      }).exec()
    }
  }
}
</script>

<style lang="scss" scoped scoped>
.purchase-wrap {
  &.active {
    border-top: 2px solid #F5F5F5;
  }
  padding: 35upx 29upx;
  .avatar {
    width: 95upx;
    height: 95upx;
  }
  .info {
    position: relative;
    margin-left: 36upx;
    .nickname {
      font-size: 26upx;
    }
    .content {
      margin-top: 21upx;
      display: -webkit-box;
      font-size: 28upx;
      line-height: 42upx;
      max-height: 126upx;
      word-break: break-all;
      white-space: pre-wrap;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
    }
    .expand-btn {
      color: rgb(41, 121, 255);
      width: 100%;
      position: absolute;
      left: 0;
      bottom: -15upx;
    }
  }
  .imgList {
    padding-top: 24rpx;
    padding-left: 27rpx;
    .img-item {
      border-radius: 10rpx;
      overflow: hidden;
      width: 300rpx;
      height: 200rpx;
      &:nth-child(n + 2) {
        margin-left: 40rpx;
      }
    }
  }
  .bottom {
    margin-top: 36upx;
    .product, .region {
      color: #5CA722;
    }
    .comment-date {
      align-self: flex-end;
    }
    .comment-date, .expiration-date {
      color: #8A8A8A;
    }
    .comment {
      .image {
        width: 37upx;
      }
    }
  }
}
</style>
