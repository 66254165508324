var components;
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-uni-view',{staticClass:"exhibitionTipModal flex justify-center align-center",on:{"click":function($event){$event.stopPropagation();
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.close).apply(void 0, arguments)
}}},[_c('v-uni-view',{staticClass:"exhibitionDialog bg-white flex justify-center align-center"},[_c('v-uni-view',{staticClass:"info"},[_c('v-uni-view',{staticClass:"title text-center"},[_vm._v(_vm._s(_vm.title))]),_c('v-uni-view',{staticClass:"content text-center"},[_vm._v(_vm._s(_vm.msg))]),_c('v-uni-button',{staticClass:"cu-btn button",on:{"click":function($event){$event.stopPropagation();
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.close).apply(void 0, arguments)
}}},[_vm._v("确定")])],1)],1)],1)}
var recyclableRender = false
var staticRenderFns = []

export { render, staticRenderFns, recyclableRender, components }