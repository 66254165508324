var components;
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-uni-view',{staticClass:"purchase-filter-wrap"},[_c('v-uni-view',{staticClass:"header app-flex app-flex-sb-c"},[_c('v-uni-view',{staticClass:"select app-flex app-flex-fs-c"},[_c('v-uni-view',{staticClass:"select-item text-bold",on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.showPopup('product')
}}},[_vm._v(_vm._s(_vm.product.title))]),_c('v-uni-view',{staticClass:"select-item text-bold",on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.showPopup('region')
}}},[_vm._v(_vm._s(_vm.region.title))])],1),_c('v-uni-view',{staticClass:"release-btn text-white text-center",on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.publish).apply(void 0, arguments)
}}},[_vm._v("+发布")])],1),_c('v-uni-view',{staticClass:"content"},_vm._l((_vm.filterDataList),function(item,index){return _c('type-purchase',{key:index,class:{ active: index > 0 },attrs:{"show-bottom-bar":true,"purchaseData":item.d}})}),1),_c('c-popup',{attrs:{"safe-area-inset-bottom":true,"mode":"bottom","border-radius":"20"},on:{"close":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.closePopup('region')
}},model:{value:(_vm.showRegionPopup),callback:function ($$v) {_vm.showRegionPopup=$$v},expression:"showRegionPopup"}},[_c('v-uni-view',{staticClass:"popup-head province"},[_vm._v("选择所在省份"),_c('v-uni-image',{staticClass:"close-btn",attrs:{"src":"http://static.jiuzhouauto.com/h5_image/agent/close.png"},on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.closePopup('region')
}}})],1),_c('v-uni-scroll-view',{staticClass:"popup-content province",attrs:{"scroll-y":true}},_vm._l((_vm.provinceList),function(item,index){return _c('v-uni-view',{key:index,staticClass:"popup-item-warp province"},[_c('v-uni-view',{staticClass:"popup-item",class:[index === _vm.provinceActiveIndex ? 'active' : ''],on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.selectPopupItem('region', index)
}}},[_vm._v(_vm._s(item.title))])],1)}),1)],1),_c('c-popup',{attrs:{"safe-area-inset-bottom":true,"mode":"bottom","border-radius":"20"},on:{"close":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.closePopup('product')
}},model:{value:(_vm.showProductPopup),callback:function ($$v) {_vm.showProductPopup=$$v},expression:"showProductPopup"}},[_c('v-uni-view',{staticClass:"popup-head product"},[_vm._v("选择产品分类"),_c('v-uni-image',{staticClass:"close-btn",attrs:{"src":"http://static.jiuzhouauto.com/h5_image/agent/close.png"},on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.closePopup('product')
}}})],1),_c('v-uni-scroll-view',{staticClass:"popup-content product",attrs:{"scroll-y":true}},_vm._l((_vm.productList),function(item,index){return _c('v-uni-view',{key:index,staticClass:"popup-item-warp product"},[_c('v-uni-view',{staticClass:"popup-item",class:[index === _vm.productActiveIndex ? 'active' : ''],on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.selectPopupItem('product', index)
}}},[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1)}
var recyclableRender = false
var staticRenderFns = []

export { render, staticRenderFns, recyclableRender, components }