<template>
  <view class="videoWarp item bg-white">
    <view :class="'video-' + videoId">
      <video
        v-if="showVideo"
        :autoplay="formatValue(newsList.d.autoplay, true)"
        :loop="formatValue(newsList.d.loop, true)"
        :src="newsList.d.video"
        :id="videoId"
        :object-fit="videoObjectFit"
        :poster="newsList.d.view"
        controls
        preload="none"
        :style="videoStyle"
        @play="videoPlay"
        @fullscreenchange="videoFullScreen"
      ></video>
    </view>
  </view>
</template>

<script>
import { uuid } from "@/common/util";

export default {
  behaviors: ["uni://form-field"],
  props: {
    formdata: {
      type: Object,
      default: { d: {}, s: { height: 0 }, t: "videoBlock" }
    }
  },
  data() {
    return {
      newsList: [],
      pageId: "",
      videoId: uuid(),
      videoObjectFit: "fill",
      videoContext: null,
      isPlaying: false,
      showVideo: false
    };
  },
  computed: {
    videoStyle() {
      let heightRatio = this.formdata.s && this.formdata.s.height ? this.formdata.s.height : 0;
      if (heightRatio > 0) {
        return `height: ${getApp().globalData.systemInfo.windowWidth * heightRatio}px;width:100%`;
      }
      return "width:100%";
    }
  },
  methods: {
    formatValue(v, defaultVal) {
      if (v === undefined || v === "") {
        return defaultVal;
      }
      return v !== 0;
    },
    disconnectObserver(observerName) {
      const observer = this[observerName];
      observer && observer.disconnect();
    },
    videoFullScreen(res) {
      if (res.detail.fullScreen) {
        this.videoObjectFit = "contain";
      } else {
        this.videoObjectFit = "fill";
      }
    },
    videoPause() {
      if (!this.videoContext) {
        this.videoContext = uni.createVideoContext(this.videoId, this);
      }
      this.isPlaying = false;
      this.videoContext.pause();
    },
    videoPlay() {
      this.isPlaying = true;
      uni.$emit(`${this.pageId}-video-play`);
      uni.$once(`${this.pageId}-video-play`, this.videoPause);
    }
  },
  created() {
    this.newsList = this.formdata;
    this.observer = {};
  },
  beforeDestroy() {
    // 销毁页面时，可能还没触发某张很底部的懒加载图片，所以把这个事件给去掉
    //observer.disconnect();
    this.videoContext = null;
    this.$off(`${this.pageId}-video-play`);
    this.disconnectObserver("contentObserver");
  },
  mounted() {
    setTimeout(() => {
      // 这里是组件内获取布局状态，不能用uni.createIntersectionObserver，而必须用this.createIntersectionObserver
      this.disconnectObserver("contentObserver");
      const contentObserver = uni.createIntersectionObserver(this);
      // 要理解这里怎么计算的，请看这个：
      // https://blog.csdn.net/qq_25324335/article/details/83687695
      contentObserver
        .relativeToViewport({
          bottom: 0,
          top: 0
        })
        .observe(".video-" + this.videoId, res => {
          if (res.intersectionRatio > 0) {
            if (!this.showVideo) {
              // 懒加载状态改变
              this.showVideo = true;
              // 如果图片已经加载，去掉监听，减少性能的消耗
              // this.disconnectObserver('contentObserver');
            }
          } else {
            if (this.isPlaying) {
              uni.$emit(`${this.pageId}-video-play`);
            }
          }
        });
      this.contentObserver = contentObserver;
      // #ifdef MP-WEIXIN
      this.showVideo = true;
      // #endif
    }, 30);
  }
};
</script>

<style scoped></style>
