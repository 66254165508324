<template>
  <view class="posR text-center padding-tb posFB flex justify-between align-center">
    <view class="inputMessage">
      <view class="inputContainer">
        <form-input class="content" v-model="inputContent" :maxlength="-1" type="textarea" auto-height
                    :field-style="fieldStyle" :clearable="false" :placeholder="$t('chat.输入提示')"/>
      </view>
    </view>
    <button class="cu-btn sentBtn text-lg" @click="sendMessage">{{$t('chat.发送')}}</button>
  </view>
</template>

<script>
  import debounce from "@/common/tools/debounce";
  import input from "@/components/common/input";

  export default {
    behaviors: ['uni://form-field'],
    components: {
      'form-input': input
    },
    props: {
      formdata: {
        type: Object,
        default: {}
      },
      pageId: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        inputContent: '',
        chatId: '',
        chatType: 4,
        fieldStyle: { // 输入框样式
          lineHeight: '32upx',
          minHeight: '32upx',
          color: 'rgb(0, 0, 0)'
        },
      }
    },
    methods: {
      // 发送留言
      async sendMessage() {
        if (this.inputContent === '') return false
        let res = await this.$util.api('kuaibao.news.saveCommentByTypeForApp', {
          title: '',
          content: this.inputContent,
          id: this.chatId,
          type: this.chatType,
        })
        if (res && parseInt(res.c) === 200) {
          this.inputContent = ''
          if (res.d.l.length > 1) {
            uni.$emit(`addChatMessage_${this.pageId}`, res.d.l[1])
          }
        }
      },
    },
    created() {
      this.chatId = this.formdata.d.id
      this.chatType = this.formdata.d.type
      // console.log('聊天组件：', this.formdata)
    }
  }
</script>

<style lang="scss" scoped scoped>
  .inputMessage {
    border-radius: 8upx;
    padding: 20upx 30upx 20upx 30upx;
    background-color: rgb(255, 255, 255);
    flex-grow: 1;
    .inputContainer {
      overflow-y: scroll;
      max-height: 120upx;
      .content {
        ::v-deep .u-clear-icon{
          display: none;
        }
        font-size: 28upx;
        color: rgb(153, 153, 153);
      }
    }
  }
  .sentBtn {
    font-size: 32upx;
    background-color: unset;
    margin-left: 49upx;
    color: rgb(255, 0, 0);
  }
</style>
