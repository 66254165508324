import store from "@/store";
import i18n from "@/common/i18n";
import md5 from 'js-md5';
import {isPcBroswer} from "@/common/tools/system";
import MessageModal from "@/components/common/modal";
import LaunchWeappModal from "@/components/common/openMiniappModal";
import TipModel from "@/components/jiuzhouapp/tip";


import * as Sentry from "@sentry/browser";


import queryString from "query-string";


import jweixin from "jweixin-module";










var AppGlobalData;
uni.$emit("updateMyFormList", {
  list: {}
});

uni.$emit("updateToken", {
  token: uni.getStorageSync("token") || ""
});

function setAppGlobalData(globalData) {
  AppGlobalData = globalData;
}

function getAppGlobalData() {
  return AppGlobalData || getApp().globalData;
}

// 定义日期格式化方法扩展
Date.prototype.format = function (fmt) {
  let o = {
    "M+": this.getMonth() + 1, //月
    "d+": this.getDate(), //日
    "h+": this.getHours() % 12 == 0 ? 12 : this.getHours() % 12, //时
    "H+": this.getHours(), //小时
    "m+": this.getMinutes(), //分
    "s+": this.getSeconds(), //秒
    "q+": Math.floor((this.getMonth() + 3) / 3), //季
    S: this.getMilliseconds() //毫秒
  };
  let week = {
    "0": "\u65e5",
    "1": "\u4e00",
    "2": "\u4e8c",
    "3": "\u4e09",
    "4": "\u56db",
    "5": "\u4e94",
    "6": "\u516d"
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
  }
  if (/(E+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (RegExp.$1.length > 1 ? (RegExp.$1.length > 2 ? "\u661f\u671f" : "\u5468") : "") + week[this.getDay() + ""]
    );
  }
  for (var k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) {
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length));
    }
  }
  return fmt;
};

function formatMsgTime(dateTimeStamp) {
  let minute = 1000 * 60;
  let hour = minute * 60;
  let day = hour * 24;
  let halfamonth = day * 15;
  let month = day * 30;
  let now = new Date().getTime();
  let diffValue = now - dateTimeStamp;
  if (diffValue < 0) {
    return;
  }
  let monthC = diffValue / month;
  let weekC = diffValue / (7 * day);
  let dayC = diffValue / day;
  let hourC = diffValue / hour;
  let minC = diffValue / minute;
  let result = "";
  if (monthC >= 1) {
    result =
      "" +
      i18n.tc("common.月", parseInt(monthC), {
        n: parseInt(monthC)
      }) +
      " " +
      i18n.t("common.前");
  } else if (weekC >= 1) {
    // result = "" + parseInt(weekC) + "周前";
    result =
      "" +
      i18n.tc("common.周", parseInt(weekC), {
        n: parseInt(weekC)
      }) +
      " " +
      i18n.t("common.前");
  } else if (dayC >= 1) {
    result =
      "" +
      i18n.tc("common.天", parseInt(dayC), {
        n: parseInt(dayC)
      }) +
      " " +
      i18n.t("common.前");
    // result = "" + parseInt(dayC) + i18n.t('common.day') + i18n.t('common.ago');
  } else if (hourC >= 1) {
    // result = "" + parseInt(hourC) + "小时前";
    result =
      "" +
      i18n.tc("common.时", parseInt(hourC), {
        n: parseInt(hourC)
      }) +
      " " +
      i18n.t("common.前");
  } else if (minC >= 1) {
    // result = "" + parseInt(minC) + "分钟前";
    result =
      "" +
      i18n.tc("common.分", parseInt(minC), {
        n: parseInt(minC)
      }) +
      " " +
      i18n.t("common.前");
  }
  // result = "刚刚";
  else result = i18n.t("common.刚刚");
  return result;
}

/**
 * http请求
 * @param req_m
 * @param req_data
 * @param req_head
 * @returns {Promise<unknown>}
 */
function api(req_m, req_data, req_head) {
  let data = {
    m: req_m || "",
    h: {
      v: "h-" + (getAppGlobalData().code || "1") + "-" + (uni.getStorageSync("channel") || "wechat"),
      d: uni.getStorageSync("deviceid") || "",
      t: uni.getStorageSync("token") || "",
      p: getAppGlobalData().platformId || ""
    },
    d: req_data || {}
  };
  if (req_head && req_head.lan) {
    data.h.lan = req_head.lan;
  } else {
    data.h.lan = i18n.locale;
  }
  let referrer = uni.getStorageSync("referrer");
  if (referrer) {
    data.h.r = referrer;
  }
  let unionid = uni.getStorageSync("unionid");
  if (unionid) {
    data.h.u = unionid;
  }
  let openid = uni.getStorageSync("openid");
  if (openid) {
    data.h.o = openid;
  }

  let preview = uni.getStorageSync("_preview");
  if (preview) {
    data.h.preview = preview
  }

  // 判断当前未登录
  // if (!data.h.t) {
  //   // 忽略一些API接口，直接返回
  //   if (data.m == 'kuaibao.businessCard.getMySwapCardAll' ||
  //     data.m == 'kuaibao.businessCard.getMyCardList' ||
  //     data.m == 'kuaibao.personal.getUserFavorite' ||
  //     data.m == 'user.user.getUserInfo') {
  //     return { c: 400, e: '', d: {} }
  //   }
  // }

  // console.log('API req:', req_m, data)
  return new Promise((resolve, reject) => {
    uni.request({
      url: getAppGlobalData().APIUrl,
      method: "POST",
      data: data,
      header: {
        "content-type": "application/json"
      },
      success: res => {
        // console.log('API res:', req_m, res.data)
        if (res.data && res.data.t) {
          uni.showToast({
            title: res.data.t,
            icon: "none",
            position: "bottom"
          });
        }
        if (res.data && res.data.c && parseInt(res.data.c) === 401) {
          uni.removeStorageSync("unionid");
          uni.removeStorageSync("token");
          uni.removeStorageSync("userinfo");
          login();
        }
        resolve(res.data);
      },
      fail: err => {
        reject(err);
      }
    });
  });
}

function getImgApi(req_m, req_data) {
  // console.log('getImgApi:', req_m, req_data)
  let data = {
    m: req_m || "",
    d: req_data || {}
  };
  // console.log('API req:', req_m, data)
  return new Promise((resolve, reject) => {
    uni.request({
      url: getAppGlobalData().APIUrl,
      method: "POST",
      data: data,
      header: {
        "content-type": "application/json"
      },
      success: res => {
        // console.log('API res:', req_m, res.data)
        if (res.data && res.data.t) {
          uni.showToast({
            title: res.data.t,
            icon: "none",
            position: "bottom"
          });
        }
        if (res.data && res.data.c && parseInt(res.data.c) === 401) {
          uni.removeStorageSync("unionid");
          uni.removeStorageSync("token");
          login();
        }
        resolve(res.data);
      },
      fail: err => {
        reject(err);
      }
    });
  });
}

function setDefaultShare() {
  api("kuaibao.expo.getMiniAppShareInfo").then(res => {
    if (res.c === 200) {
      console.log("share", res.d);
      store.dispatch("setDefaultShareInfo", res.d);
    }
  });
}

/**
 * 微信分享配置
 * @returns {Promise<void>}
 */
async function wxRegister() {

  let url = window.location.href.split("#")[0];
  url = url.split("?")[0];
  console.log("url", url);
  let res = await this.api("kuaibao.wxshare.WxshareBySpringExpoVideo", {
    wxshareUrl: url
  });
  if (parseInt(res.c) === 200) {
    try {
      let data = res.d.wxs;
      data.debug = false;
      data.fail = function (e) {
        console.log("jweixin.config.fail Error:", JSON.stringify(e));
        Sentry.captureMessage("jweixin.config.fail Error:" + JSON.stringify(e));
      };
      jweixin.config(data);
      jweixin.error(function (res) {
        console.log(res);
        Sentry.captureMessage("jweixin.config Error:");
      });
    } catch (e) {
      Sentry.captureMessage("wxRegister Error:", e);
    }
  } else {
    console.log("请求失败", res);
    // console.log(res.t);
  }

}

/**
 *
 * @param title {string} 分享标题
 * @param link {string} 分享链接，必须带有http或https
 * @param imgUrl {string} 分享链接的缩略图，必须能访问
 * @param desc {string} 分享的描述
 */
//分享名片的缩略图 http://img.jiuzhouauto.com/i/2020/08/06/1596679479542-79479.png
function jweixinShare(title, link, imgUrl, desc = "", pageId) {
  const channel = uni.getStorageSync("channel") || "";
  const unionid = uni.getStorageSync("unionid") || "";


  let shareLink = link && link !== "" ? link : window.location.href.split("#")[0];
  // shareLink = shareLink.split('#')[0].split('?')[0]
  let linkUrl = new URL(shareLink);
  // 若存在 hash
  if (linkUrl && linkUrl.hash) {
    let hashObj = linkUrl.hash.split("?");
    // 若存在参数
    if (hashObj[1]) {
      try {
        let hashParams = new URLSearchParams("?" + hashObj[1]);
        if (hashParams) {
          shareLink = linkUrl.origin + linkUrl.pathname + hashObj[0] + "?" + hashParams.toString();
        }
      } catch (e) {
        console.info("Error:", e);
      }
    }
  }
  jweixin.ready(function () {
    try {
      jweixin.updateTimelineShareData({
        title: title || "九州车后快豹",
        link: shareLink,
        imgUrl: imgUrl || "http://img.jiuzhouauto.com/i/2020/06/28/1593333835690-33835.png",
        success: function () {
        },
        fail: function (e) {
          console.log("updateTimelineShareData fail", e);
          Sentry.captureMessage("jweixin.ready.updateTimelineShareData fail.");
        },
        cancel: function () {
        }
      });
      // 分享给好友
      jweixin.updateAppMessageShareData({
        title: title || "九州车后快豹",
        desc: desc || store.state.share.defaultShareInfo.desc,
        link: shareLink,
        imgUrl: imgUrl || "http://img.jiuzhouauto.com/i/2020/06/28/1593333835690-33835.png",
        success: function () {
          console.log("jweixin.ready.updateAppMessageShareData success.");
        },
        fail: function (e) {
          console.log("updateAppMessageShareData fail", e);
          Sentry.captureMessage("jweixin.ready.updateAppMessageShareData fail.");
        },
        cancel: function () {
        }
      });
    } catch (e) {
      console.log("jweixin.ready Error:", e);
      Sentry.captureMessage("jweixin.ready Error:", e);
    }
  });


























}

function newApi() {
  return new Promise((resolve, reject) => {
    uni.request({
      url: getAppGlobalData().NEWAPIUrl,
      method: "POST",
      header: {
        "content-type": "application/json"
      },
      success: res => {
        // console.log('API res:', req_m, res.data)
        if (res.data && res.data.t) {
          uni.showToast({
            title: res.data.t,
            icon: "none",
            position: "bottom"
          });
        }
        if (res.data && res.data.c && res.data.c == 401) {
          uni.removeStorageSync("unionid");
          uni.removeStorageSync("token");
          uni.removeStorageSync("userinfo");
          login();
        }
        resolve(res.data);
      },
      fail: err => {
        reject(err);
      }
    });
  });
}

/**
 * 获取用户信息
 * @param {object} userinfo
 * @returns {Promise<{}|any>}
 */
async function getAppUserInfo(userinfo = {}) {
  let req_data = uni.getStorageSync("userinfo") || "";
  if (req_data) {
    req_data = JSON.parse(req_data);
  } else {
    let res = await api("user.user.getUserInfo", {});
    if (res.c == 200) {
      // console.log(res.d)
      req_data = res.d;
      uni.setStorageSync("userinfo", JSON.stringify(res.d));
      if (res.d.user.unionid) {
        uni.setStorageSync("unionid", res.d.user.unionid);

        // 设置一个cookie，用于video站的判断
        let exp = new Date();
        exp.setTime(exp.getTime() + 180 * 60 * 60 * 1000);
        document.cookie =
          "h5id" + "=" + res.d.user.unionid + ";expires=" + exp.toGMTString() + "; path=/; domain=.jiuzhouauto.com";

      }
      if (res.d.user.entId) {
        uni.setStorageSync("entId", res.d.user.entId);
      }
    }
  }
  try {
    if (req_data && req_data.user) {
      if (req_data.user.unionid) {

        _paq.push([ "setUserId", uni.getStorageSync("unionid") ]);

      }
      if (req_data.vistorLib && req_data.vistorLib.company) {

        _paq.push([ "setCustomVariable", 2, "company", req_data.vistorLib.company, "visit" ]);

      }
      if (req_data.user.nickname) {

        _paq.push([ "setCustomVariable", 3, "name", req_data.user.nickname, "visit" ]);

      }
    }
  } catch (e) {
  }

  return req_data;
}

function checkLogin() {
  return new Promise(async resolve => {

    let token = uni.getStorageSync("token");
    if (!token || token === "") {
      if (isWeixnH5() || i18n.locale === "en") {
        if (!new URLSearchParams(location.search).get("code")) {
          MessageModal.confirm("系统提示", "此操作需要进行微信授权，是否继续").then(() => {
            console.log("登录");
            login();
          });
        }
      } else if (isPcBroswer() && i18n.locale === "zh") {
        scan_code_login_tip();
      } else {
      }
      resolve(false);
    }
    resolve(true);















  });
}

/**
 * 微信授权
 * @param url
 * @returns {Promise<unknown>}
 */
function login(url) {

  return new Promise(async function (resolve, reject) {
    let token = await getToken();
    if (!token && isWeixnH5() && i18n.locale === "zh") {
      try {
        let curUrl = new URL(window.location.href);
        console.log("login:", curUrl);
        uni.setStorageSync("loginCBUrl", curUrl.origin + "/app/" + (curUrl.hash || ""));
        // 获取授权地址
        let oauthurl = getAppGlobalData().loginCB;

        console.info("Relogin.........", oauthurl);
        uni.removeStorageSync("token");
        uni.removeStorageSync("unionid");
        uni.removeStorageSync("userinfo");
        uni.removeStorageSync("mpUserProfile");
        getApp().globalData.appLocation.href = oauthurl;
      } catch (e) {
        console.log(e);
      }
    } else if (i18n.locale === "en") {
      uni.removeStorageSync("token");
      uni.removeStorageSync("unionid");
      uni.removeStorageSync("userinfo");
      uni.removeStorageSync("mpUserProfile");
      uni.navigateTo({
        url: "/packages_form/pages/login/index"
      });
    } else {
    }
    resolve({
      token
    });
  });
































}

function scan_code_login_tip() {
  MessageModal.confirm("系统提示", "此操作需要微信扫码登录后才能操作, 是否继续？")
    .then(action => {
      uni.navigateTo({
        url: "/packages_form/pages/login/scanCode"
      });
    })
    .catch(action => {
    });
}

function wxmp_login() {














































}

async function wxmp_getMobile(req) {


















  return resData;
}

async function getUnionid() {
  return uni.getStorageSync("unionid") || "";
}

async function getOpenid() {
  return uni.getStorageSync("openid") || "";
}

async function getMobile() {
  return uni.getStorageSync("mobile") || "";
}

function getConfig() {
  return getAppGlobalData().config || {};
}

function getSessionKey() {























  try {
    // await login()
    return uni.getStorageSync("session_key") || "";
  } catch (e) {
  }
  return "";

}

async function getToken() {
  return uni.getStorageSync("token") || "";
}

function getPlatformId() {
  return getAppGlobalData().platformId || "miniapp2";
}

function obj2Query(obj = {}) {
  let ret = "";
  if (!!obj) {
    for (let item in obj) {
      if (ret == "") {
        ret += "?";
      } else {
        ret += "&";
      }
      ret += item + "=" + obj[item];
    }
  }
  return ret;
}

function share(res, shareObj = {}) {
  let config = getConfig();
  let path = shareObj.path || config.sharePath;
  let unionid = uni.getStorageSync("unionid") || "";
  let channel = uni.getStorageSync("channel") || "wechat";
  if (path.includes("?")) {
    path = path + "&c=" + channel;
  } else {
    path = path + "?c=" + channel;
  }
  if (unionid) {
    path = path + "&r=" + unionid;
  }
  console.log("SharePath:", path);
  return {
    title: shareObj.title || config.shareTitle,
    path: path,
    imageUrl: shareObj.imageURL || config.shareImageUrl
  };
}

function procPic(url, option) {
  let ret = url;
  if (url.includes("img.jiuzhouauto.com") || url.includes("img.cimpauto.com")) {
    if (!url.includes("?")) {
      if (url.endsWith(".gif")) {
        option.f = "gif";
      }
      if (url.endsWith(".png")) {
        option.f = "png";
      }
      if (url.endsWith(".mp4")) {
        return ret;
      }

      let m = 1;
      if (option && option.m) {
        m = option.m;
      }
      ret += "?imageView2/" + m;

      let w = 750;
      if (option && option.w) {
        w = option.w;
      }
      ret += "/w/" + w;

      if (option && option.h) {
        ret += "/h/" + option.h;
      }

      if (option && option.q) {
        ret += "/q/" + option.q;
      }
      if (option && option.i) {
        ret += "/interlace/" + option.i;
      }
      if (option && option.f) {
        ret += "/format/" + option.f;
      }
    }
  }
  return ret;
}

function isLogin() {
  let ret = false;
  if (uni.getStorageSync("unionid") && uni.getStorageSync("token") && uni.getStorageSync("openid")) {
    ret = true;
  }
  return ret;
}

function timeFormat(timeS, timeE) {
  let dateTimeS = new Date(timeS);
  let dateTimeE = new Date(timeE);
  let TimeS = dateTimeS.format("yyyy.M.d H:mm");
  let TimeE = dateTimeE.format("yyyy.M.d H:mm");
  let itemData = "";
  if (dateTimeS.format("yyyy.M.d") == dateTimeE.format("yyyy.M.d")) {
    itemData = dateTimeS.format("yyyy.M.d H:mm") + " - " + dateTimeE.format("H:mm");
  } else {
    itemData = TimeS + " - " + TimeE;
  }
  return itemData;
}

async function procScene(scene) {
  if (scene && scene.length == 32) {
    let res = await api("kuaibao.expo.getSunCodeByScene", {
      scene
    });
    if (res && res.c == 200) {
      let url = "";
      if (res.d.channel) {
        uni.setStorageSync("channel", res.d.channel);
      }
      if (res.d.unionid) {
        uni.setStorageSync("referrer", res.d.unionid);
      }
      if (res.d.pages) {
        url = res.d.pages.trim();
      }

      if (res.d.data) {
        let params = {
          ...res.d.data
        };

        if (params?.act === 'siteSunQRLogin' && params?.loginKey) {
          uni.navigateTo({ url: `/pages/confirm/index?loginKey=${ params?.loginKey }` })

          // getApp().globalData.loginKey = params.loginKey
          // const userInfo = uni.getStorageSync('userinfo')
          // if (userInfo) {
          //   api('kuaibao.utils.setLoginKey', { loginKey: params.loginKey })
          // } else {
          //   uni.reLaunch({ url: '/packages_form/pages/form/index' })
          // }
        }

        let str = "";
        Object.keys(params).forEach(item => {
          if (typeof params[item] == "string") {
            if (params[item].trim().length > 0) {
              str = str + item + "=" + params[item] + "&";
            }
          } else if (typeof params[item] == "number") {
            str = str + item + "=" + params[item] + "&";
          }
        });
        if (str.length > 0) {
          url = url + "?" + str.substring(0, str.length - 1);
        }
        if (url.substr(0, 1) !== "/") {
          url = "/" + url;
        }
        uni.reLaunch({
          url
        });
      }
    }
    // console.log('decode scene:', res)
  }
}

async function sceneProc(option) {
  // console.log('sceneProc:', option)
  if (option && option.scene && option.scene.length == 32) {
    // console.log('scene:', option)
    let res = await api("kuaibao.expo.getSunCodeByScene", {
      scene: option.scene
    });
    if (res && res.c == 200) {
      let url = "";
      if (res.d.channel) {
        uni.setStorageSync("channel", res.d.channel);
      }
      if (res.d.unionid) {
        uni.setStorageSync("referrer", res.d.unionid);
      }
      if (res.d.pages) {
        url = res.d.pages.trim();
      }

      if (res.d.data) {
        return res.d.data;
      }
    }
  } else {
    return option;
  }
}

function initTabBar() {
  uni.setTabBarItem({
    index: 0,
    text: i18n.t("tabbar.资讯")
  });
  uni.setTabBarItem({
    index: 1,
    text: i18n.t("tabbar.制造商")
  });
  uni.setTabBarItem({
    index: 2,
    text: i18n.t("tabbar.会员")
  });
}

async function initApp(options, Vue) {
  if (options.query.loginCBUrl){
    uni.setStorageSync("loginCBUrl", decodeURIComponent(options.query.loginCBUrl));
  }

  if (options.query.project_path){
    uni.setStorageSync("project_path", decodeURIComponent(options.query.project_path));
  }

  if (options.query._preview) {
    uni.setStorageSync("_preview", 1)
  } else {
    uni.setStorageSync("_preview", 0)
  }

  setDefaultShare();

  // 保存推荐人
  if (options.query.r && options.query.r != getUnionid()) {
    uni.setStorageSync("referrer", options.query.r);
  }

  // 保存渠道
  if (options.query.c) {
    uni.setStorageSync("channel", options.query.c);

    _paq.push([ "setCustomDimension", 1, options.query.c ]);

  }
  // console.log('Channel:', uni.getStorageSync('channel') || 'wechat')

  // 生成设备id
  let did = uni.getStorageSync("deviceid");
  if (!did) {
    did = "11-" + uuid().replace(/-/g, "");
    uni.setStorageSync("deviceid", did);
  }

  console.log(options.query, 'options.query----')









  // 判断是否微信授权
  let qs = {};
  if (window && window.location && window.location.href) {
    // console.log("initApp location:", window.location.href)
    let uri = /\?([^?^#]+)#\//i.exec(window.location.href);
    if (uri) {
      qs = queryString.parse(uri[1]);
      // console.log("initApp.qs:", qs)
    }
  }

  let urlFormat = new URL(window.location.href);
  // console.log(window.location.href, urlFormat);

  if (
    (options.query && options.query.code && options.query.state) ||
    (qs.code && (qs.state === "prod" || qs.state === "local" || qs.state === "test"))
  ) {
    let url = window.location.href;

    if (url.indexOf(`${ location.protocol }//${ location.host }/app`) < 0) {
      url = `${ location.protocol }//${ location.host }/app/#`;
    }

    // console.log('come url', options.path)
    // console.log('Weixin OAUTH2:', options.query.code || qs.code)
    let res = await api("user.user.enrollByWxForApp", {
      code: options.query.code || qs.code,
      platformId: "videopublic",
    });
    if (res && parseInt(res.c) === 200 && res.d && res.d.token) {
      uni.setStorageSync("token", res.d.token);
      url = uni.getStorageSync("loginCBUrl");
      uni.removeStorageSync("loginCBUrl");
    }
    // console.log('===loginCBUrl====', url);
    window.history.replaceState(null, "", urlFormat.origin + "/app/#/");
    if (window.innerWidth > 768) {
      location.reload();
    }
    let token = uni.getStorageSync("token")
    url = decodeURIComponent(url).replace('${token}', token).replace('{token}', token)
    try {
      if (url.indexOf("/supplier/index") >= 0 || url.indexOf("/member/index") >= 0) {
        await projectJumpLoginSuccess()
        uni.switchTab({
          url: decodeURIComponent(new URL(url).hash.substring(1))
        });
      } else {
        uni.redirectTo({
          url: new URL(url).hash.substring(1)
        });
      }
    } catch (e) {
      uni.redirectTo({
        url: "/"
      });
    }
  } else {
    if (urlFormat.search || urlFormat.pathname != "/app/") {
      // getApp().globalData.appLocation.href = urlFormat.origin+'/app/'+(urlFormat.hash||'');
      window.history.replaceState(null, "", urlFormat.origin + "/app/" + (urlFormat.hash || ""));
    }
  }

  // let reLaunchUrl
  // let wxParams = []
  // if (!url || url === '') {
  //   url = window.location.href
  // }

  // let paths = url.split('#')
  //  wxParams = paths[0].split('?')[0]
  //  if (paths.length > 1) {
  //    reLaunchUrl = wxParams + '#' + paths[1]
  //  } else {
  //    reLaunchUrl = wxParams + '/#/'
  //  }
  //  if (wxParams.length > 1) {
  //    getApp().globalData.appLocation.href = reLaunchUrl
  //  }







  uni.getSystemInfo({
    success: function (e) {

      Vue.prototype.StatusBar = e.statusBarHeight;
      if (e.platform === "android") {
        Vue.prototype.CustomBar = e.statusBarHeight + 50;
      } else {
        Vue.prototype.CustomBar = e.statusBarHeight + 45;
      }











    }
  });
  try {
    if (options["scene"]) {
      // console.log('scene:' + options.scene)
    }
    if (
      options["scene"] &&
      (options.scene == "1001" || // 发现栏小程序主入口
        options.scene == "1005" || // 微信首页顶部搜索框的搜索结果页
        options.scene == "1006" || // 发现栏小程序主入口搜索框的搜索结果页
        options.scene == "1020" || // 公众号 profile 页相关小程序列表
        options.scene == "1026" || // 发现栏小程序主入口，「附近的小程序」列表
        options.scene == "1027" || // 微信首页顶部搜索框搜索结果页「使用过的小程序」列表
        options.scene == "1035" || // 公众号自定义菜单
        options.scene == "1102" || // 公众号 profile 页服务预览
        options.scene == "1103" || // 发现栏小程序主入口，「我的小程序」列表'
        options.scene == "1104" || // 微信聊天主界面下拉，「我的小程序」栏
        options.scene == "1089") // 微信聊天主界面下拉，「最近使用」栏
    ) {
      //wx.setStorageSync('_merchid', merchid)
      // var merchid = wx.getStorageSync('_merchid')
      // if (merchid > 0) {
      // wx.reLaunch({
      // url: "/pages/webview/webview?scene=m%3d" + merchid
      // })
    }
  } catch (e) {
  }

  // 清除用户信息
  uni.removeStorageSync("userinfo");
  // if (isWeixnH5()) {
  //   await getAppUserInfo()
  // }

  // 获取我已交换的全部名片ID
  let token = uni.getStorageSync("token") || "";
  if (token && token !== "") {
    await getAppUserInfo();
    await getMyExchangedId();
    // 获取所有名片的ID
    await getMyCard();
  } else {
    // 中文用户才进行登录授权
    if (i18n.locale === "zh") {
      // await login()
    }
  }
}

// 判断广告跳转环境
function toAdPage(obj) {
  // obj.investerurl = 'https://baidu.com?id=5dae6914c98af620d06f66c7&a=123&cc=22222'
  // obj.investerurl = 'https://baidu.com'
  // obj.investerurl = '/enroll/pages/detail?id=5dae6914c98af620d06f66c7'
  if (obj.investerurl.includes("http")) {
    //h5
    // console.log('urlToHttp:', obj.investerurl)
    let url = encodeURIComponent(obj.investerurl);
    uni.navigateTo({
      url: "/enroll/pages/url?id=" + url + "&title=" + encodeURIComponent(obj.title)
    });
  } else if (obj.investerurl.includes("/pages/")) {
    //内部
    // console.log('urlToPages:', obj.investerurl)
    uni.navigateTo({
      url: obj.investerurl
    });
  } else if (obj.investerurl === "#" || obj.investerurl === "") {
    //"#","" 无跳转不操作
    // console.log('url#:', obj.investerurl)
  } else {
    //app
    // console.log('urlToApp:', obj.investerurl)
  }
}

/**
 * 保存已经交换的名片ID
 * @returns {Promise<void>}
 */
async function getMyExchangedId() {
  let res = await api("kuaibao.businessCard.getMySwapCardAll", {});
  if (res && res.c === 200) {
    let myExCardId = res.d.cardArray;
    store.commit("SET_EX_CARD_ARRAY", myExCardId);
    // uni.setStorageSync('__exchangeCardId', JSON.stringify(myExCardId));
  }
}

async function getMyCard() {
  let res = await api("kuaibao.businessCard.getMyCardList", {});
  if (res && res.c === 200) {
    let myCardId = [];
    res.d.cardList.forEach(item => {
      myCardId.push(item._id);
    });
    store.commit("SET_MY_CARD_ARRAY", myCardId);
    // uni.setStorageSync('__myCardId', JSON.stringify(myCardId))
  }
}

/**
 * 解析参数生成跳转Url AppUrlStringify
 * params 参数对象
 * title 标题
 */
function AppUrlStringify(params, title) {
  let q = {};
  if (params) {
    try {
      q.params = JSON.stringify(params);
    } catch (e) {
    }
  }

  if (title) {
    try {
      q.title = title.toString().trim();
    } catch (e) {
    }
  }

  let qStr = queryString.stringify(q);

  return (getAppGlobalData().BaseURL || "") + "/pages/pages/index" + (qStr ? "?" + qStr : "");
}

/**
 * 解析跳转动作 gotoApp
 * type: 1 返回参数; 2 navigateTo; 3 redirectTo; 4. reLaunch;5 switchTab;
 * type为2的类型
 * app: App跳转动作
 */

async function gotoApp({ type = 1, app = "", h5 = "", params = {}, complete, success, fail }) {
  console.log('action app', app)
  let url = "";
  let isHttp = false;
  let q = {
    action: "",
    query: {}
  };
  let tmpUrl = "";
  if (!app || app === "") return;
  if (/^(https?):\/\//i.exec(app)) {
    // 判断是否http/https
    isHttp = true;
  } else if ((tmpUrl = /^jiuzhouapp:\/\/([^?]+)[?]?([\s\S]*)/i.exec(app))) {
    // 判断是否jiuzhouapp
    q.action = tmpUrl[1];
    if (tmpUrl[2]) {
      q.query = queryString.parse(tmpUrl[2]);
    }
  } else {
    return;
  }
  // console.log(q.query)
  if (!isHttp && q.action) {
    console.log("jiuzhouapp:", q);
    // 处理 jiuzhouapp 的各种动作
    let token = uni.getStorageSync("token") || "";
    switch (q.action) {
      case "specialColumn":
        console.log(q.query.name, "11");
        url = (getAppGlobalData().BaseURL || "") + "/?tagname=" + q.query.name;
        params = {
          type: "specialColunm",
          url
        };
        break;
      case "column":
        // jiuzhouapp://column?name=栏目名称
        // http://doc.jiuzhouauto.com/docs/kuaibao/URLSchemes#62g7n7
        params = {
          m: "kuaibao.index.getIndexNewsList",
          d: {
            trade: q.query.name || "all",
            pageIndex: 1
          }
        };
        url = AppUrlStringify(params);
        // console.log(params, url)
        break;
      case "newsList":
        params = {
          m: "kuaibao.index.getIndexNewsList",
          d: {
            trade: q.query.trade || "",
            menu: q.query.menu || "",
            tag: q.query.tag || "",
            queryParam: q.query.queryParam || "",
            title: q.query.title || "",
            pageIndex: 1,
            lan: q.query.lan || ""
          }
        };
        url = AppUrlStringify(params);
        // console.log(params, url)
        break;
      case "page":
        // page 打开企业号页面
        params = {
          m: "kuaibao.entPage.getEntPageInfo",
          d: {
            pageId: q.query.id || "",
            pageIndex: 1,
            pageSize: 20,
            lan: q.query.lan || ""
          }
        };
        url = AppUrlStringify(params);
        break;
      case "entHomepage":
        // 打开企业号首页页面
        params = {
          m: "kuaibao.entPage.getEntHomePageInfo",
          d: {
            entId: q.query.id || "",
            pageIndex: 1,
            pageSize: 20,
            lan: q.query.lan || ""
          }
        };
        url = AppUrlStringify(params);
        // console.log('entHomepage', url)
        break;
      case "showroomHomepage":
        // 展馆首页页面
        params = {
          m: "kuaibao.onlineExpo.getOlineBoothList",
          d: {
            showroom: q.query.id || "",
            pageIndex: 1,
            pageSize: 20,
            lan: q.query.lan || ""
          }
        };
        if (q.query.list != null) {
          params.d.list = q.query.list;
        }
        url = AppUrlStringify(params);
        // console.log(params, url)
        break;
      case "registerVistor": {
        let isLogin = await checkLogin();
        // 展馆首页页面
        // 判断用户是否报名线上展会
        if (!isLogin) return false;
        let res = await this.api("kuaibao.onlineExpo.enrollState", {
          expoType: 0,
          expoCode: q.query.id || ""
        });
        // 0未报名，1已报名并提示，2已报名，跳转动作
        if (res && res.c == "200") {
          if (res.d.enrollState == "0") {
            let roles = {
              expoCode: q.query.id,
              name: "registerVistor",
              lan: q.query.lan || ""
            };
            uni.navigateTo({
              url: "/packages_form/pages/form/index?roles=" + encodeURIComponent(JSON.stringify(roles))
            });
          } else {
            // 已报名的动作, 1,2 报名
            if (q.query.app) {
              let a = await this.gotoApp({
                type: 1,
                app: `${ q.query.app }${ q.query.app.indexOf("?") >= 0 ? "&" : "?" }lan=${ q.query.lan || "" }`
              });
              if (a) {
                this.gotoPage(a);
              } else {
                getApp().globalData.appLocation.href = q.query.app;
              }
            } else {
              if (parseInt(res.d.enrollState) === 1) {
                uni.showModal({
                  content: res.d.tips,
                  showCancel: false,
                  confirmText: i18n.t("common.确定")
                });
              } else if (parseInt(res.d.enrollState) === 2) {
                let resApp = await this.gotoApp({
                  type: 1,
                  app: `${ res.d.app }${ res.d.app.indexOf("?") >= 0 ? "&" : "?" }lan=${ q.query.lan || "" }`
                });
                if (resApp) {
                  this.gotoPage(resApp);
                } else {
                  getApp().globalData.appLocation.href = res.d.app;
                }
              } else {
                // console.log('新类型，未开发')
              }
            }
          }
        }
        break;
      }
      case "registerExhibitor": {
        let isLogin = await checkLogin();
        //  参展注册
        // 判断是否有报名
        if (!isLogin) return false;
        let resExhibitor = await this.api("kuaibao.onlineExpo.enrollState", {
          expoType: 1,
          expoCode: q.query.id || ""
        });
        if (resExhibitor && parseInt(resExhibitor.c) === 200) {
          if (parseInt(resExhibitor.d.enrollState) === 0) {
            // 未报名----跳去报名
            let rolesE = {
              expoCode: q.query.id,
              name: "registerExhibitor"
            };
            uni.navigateTo({
              url: "/packages_form/pages/form/index?roles=" + encodeURIComponent(JSON.stringify(rolesE))
            });
          } else {
            // 1,2 报名
            if (q.query.app) {
              let a = await this.gotoApp({
                type: 1,
                app: `${ q.query.app }${ q.query.app.indexOf("?") >= 0 ? "&" : "?" }lan=${ q.query.lan || "" }`
              });
              if (a) {
                this.gotoPage(a);
              } else {
                getApp().globalData.appLocation.href = q.query.app;
              }
            } else {
              if (parseInt(resExhibitor.d.enrollState) === 1) {
                // console.log(resExhibitor.d.tips)
                uni.showModal({
                  content: resExhibitor.d.tips,
                  showCancel: false,
                  confirmText: i18n.t("common.确定")
                });
              } else if (parseInt(resExhibitor.d.enrollState) === 2) {
                let resApp = await this.gotoApp({
                  type: 1,
                  app: `${ resExhibitor.d.app }${ resExhibitor.d.app.indexOf("?") >= 0 ? "&" : "?" }lan=${ q.query.lan ||
                  "" }`
                });
                this.gotoPage(resApp);
              } else {
                // console.log('新类型，未开发')
              }
            }
          }
        }
        break;
      }
      case "boothHomepage":
        // 企业展位首页页面
        params = {
          m: "kuaibao.onlineExpo.getOlineBoothHomePageInfo",
          d: {
            entId: q.query.id || "",
            pageIndex: 1,
            pageSize: 20,
            lan: q.query.lan || 0
          }
        };
        url = AppUrlStringify(params);
        // console.log(params, url)
        break;
      case "boothPage":
        // 企业展位页面
        params = {
          m: "kuaibao.onlineExpo.getOnlineBoothPageInfo",
          d: {
            pageId: q.query.id || "",
            pageIndex: 1,
            pageSize: 20,
            lan: q.query.lan || ""
          }
        };
        url = AppUrlStringify(params);
        // console.log(params, url)
        break;
      case "product":
        // 产品详情页面
        params = {
          m: "kuaibao.onlineExpo.getProductDetailAndComments",
          d: {
            id: q.query.id || "",
            pageIndex: 1,
            pageSize: 20,
            lan: q.query.lan || ""
          }
        };
        url = AppUrlStringify(params);
        // console.log(params, url)
        break;
      case "searchBooth":
        // 搜索展位页
        let newP = {
          expoCode: q.query.expoCode || ""
          // m: "kuaibao.onlineExpo.getOlineBoothList",
          // d: {
          //   expoCode: q.query.expoCode || '',
          //   queryParm: '',
          //   pageIndex: 1,
          //   pageSize: 10,
          //   lan: q.query.lan || ''
          // },
          // pageType: 2, //展位
          // type: 'up',
          // index: 1,
          // // 距离顶部占位
          // mainTop: '100'
        };
        uni.navigateTo({
          url: "/pages/search/search_booth?params=" + encodeURIComponent(JSON.stringify(newP))
        });
        break;
      case "news":
        // let obj = getAppGlobalData().NEWSUrl + q.query.id
        let obj = getApp().globalData.NEWSUrl + q.query.id + ".html";

        getApp().globalData.appLocation.href = obj;














        break;
      case "entNamecard":
        // if (!checkLogin()) return false
        uni.navigateTo({
          url: `/packages_form/pages/bus_card/ent_card_list?entId=${ q.query.id }`
        });
        break;
      case "entChat": {
        let isLogin = await checkLogin();
        if (!isLogin) return false;
        uni.navigateTo({
          url: `/packages_form/pages/chat/index?entId=${ q.query.entId }`
        });
        break;
      }
      case "userCenter": {
        let isLogin = await checkLogin();

        if (!isLogin) return false;
        if (i18n.locale === "en") {
          uni.navigateTo({
            url: "/pages/member/user"
          });
        }
        break;
      }
      case "tips":
        TipModel({
          title: q.query.title || "提示",
          msg: q.query.msg || ""
        });
        break;
      case "clipVideo":
        uni.navigateTo({
          url: `/pages/video_carousel_preview/index?id=${ q.query.id || "" }&img=${ encodeURIComponent(
            q.query.img || ""
          ) }&tag=${ encodeURIComponent(q.query.tag || "") }&trade=${ encodeURIComponent(
            q.query.trade || ""
          ) }&clipBookId=${ q.query.clipBookId || "" }`
        });
        break;
      case "searchEnt3":
        uni.navigateTo({
          url: "/pages/search/search_ent"
        });
        break;
      case "purchaseInfo": {
        let isLogin = await checkLogin();
        if (isLogin) {
          uni.navigateTo({
            url: `/pages/quotation/publish-quotation?id=${ q.query.id }`
          });
        }
        break;
      }
      case "home":
        break;
      case "Papayas":
        break;
      case "miniapp": //打开小程序
        if (isWeixnH5()) {
          LaunchWeappModal({
            appId: q.query.id,
            path: q.query.uri
          });
        } else if (isWeixinMini()) {
          uni.navigateToMiniProgram({
            appId: q.query.appId,
            path: q.query.uri
          });
        }
        break;
      case "clipBook":
        uni.navigateTo({
          url: `/pages/magazine/index?clipBookId=${ q.query.id }`
        });
        break;
      case "h5":






        getApp().globalData.appLocation.href = q.query.url;

        break;
      case "url":






        getApp().globalData.appLocation.href = q.query.url;

        break;
      case "showroom":
        const tab = q.query.tab
        uni.navigateTo({ url: `/pages/showroom/index?id=${ q.query.id }&tab=${ tab || 1 }` })
        break;
      case "expoMap":
        const token = uni.getStorageSync('token') ?? ''
        const { expoCode, hallCode, boothCode } = q.query

        const path = `${process.env.VUE_APP_EXMAP}/#/${expoCode}?hallCode=${hallCode||''}&boothCode=${boothCode||''}&token=${token}`

        if (isWeixnMPH5()) {
          uni.navigateTo({ url: `/pages/webpage/index?link=${encodeURIComponent(path)}` })
        } else {
          window.location = path
        }
        break;
      case "entNews2":
        uni.navigateTo({ url: `/pages/showroom/home/news?id=${ q.query.id }` })
        break;
      case "advisory":
        uni.$emit('showAdvisoryDialog', this.entId)
        break;
      case "goods":
        uni.navigateTo({ url: `/pages/showroom/home/goods/index?id=${ q.query.id }` })
        break;
      case "back":
        uni.navigateBack({
          delta: 1
        });
      default:
      // console.log(`不支持的 jiuzhouapp://${q.action} 动作。`);
    }
  } else {
    // console.log('不在解析范围内')
    if (isHttp) {

      getApp().globalData.appLocation.href = app;












      return;
    }
    return false;
  }

  switch (type) {
    case 1:
      // 1 返回参数;
      return params;
      break;
    case 2:
      // 2 navigateTo;
      // uni.navigateTo({
      //   url,
      //   complete,
      //   success,
      //   fail,
      // })
      break;
    case 3:
      // 3 redirectTo;
      // uni.redirectTo({
      //   url,
      //   complete,
      //   success,
      //   fail,
      // })
      break;
    case 4:
      // 4 reLaunch;
      // uni.reLaunch({
      //   url,
      //   complete,
      //   success,
      //   fail,
      // })
      break;
    case 5:
      // 5 switchTab;
      // uni.switchTab({
      //   url,
      //   complete,
      //   success,
      //   fail,
      // })
      break;
    default:
  }
  console.log("params", params);
  return params;
}

function gotoPage(obj, type = 1) {
  console.log("点击", type, obj);
  if (obj.hasOwnProperty("type") && obj.type === "specialColunm") {
    // window.location = obj.url
    let url = obj.url;



    try {
      uni.reLaunch({
        url: url,
        fail: function (E) {
          console.log(E);
        }
      });
    } catch (e) {
      console.log(e);
    }
    return;
  }
  if (obj) {
    if (obj.m) {
      if (parseInt(type) == 3) {
        // 获取内容打开page页面
        uni.redirectTo({
          url: "/pages/pages/index?obj=" + encodeURIComponent(JSON.stringify(obj))
        });
      } else if (parseInt(type) == 2) {
        uni.reLaunch({
          url: "/pages/pages/index?obj=" + encodeURIComponent(JSON.stringify(obj))
        });
      } else {
        // 获取内容打开page页面
        uni.navigateTo({
          url: "/pages/pages/index?obj=" + encodeURIComponent(JSON.stringify(obj))
        });
      }
    } else {
      // console.log('该类型暂不做操作：', obj)
    }
  }
}

/**
 * 判断是否微信H5环境
 */
function isWeixnH5() {
  try {

    var ua = navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      return true;
    }

  } catch (e) {
  }
  return false;
}

/**
 * 判断是否微信小程序WebView环境
 */
function isWeixnMPH5() {
  try {

    var ua = navigator.userAgent.toLowerCase();
    if (ua.match(/miniprogram/i) == "miniprogram") {
      return true;
    }




  } catch (e) {
  }
  return false;
}

/**
 *
 * 判断是否是微信小程序
 */
function isWeixinMini() {

  return false;

  return true;
}

/**
 * 切换语言
 * @param {String} targetLan 目标语言 zh、en
 */
function changeLanguage(targetLan = "") {
  if (!targetLan) {
    targetLan = i18n.locale === "en" ? "zh" : "en";
  }
  i18n.locale = targetLan;
  // uni.setStorageSync('__language', targetLan)
}

/**
 * 返回上一页
 */
function goBack() {









  history.go(-1);


  // if (getCurrentPages() && getCurrentPages().length === 1) {
  //   uni.reLaunch({
  //     url: "/",
  //     fail() {
  //       getApp().globalData.appLocation.href = "/app";
  //     }
  //   });
  // } else {
  //   // uni.navigateBack({
  //   //   delta: 1,
  //   //   fail:function(e) {
  //   //     console.log('backe',e)
  //   //   }
  //   // });
  //   history.go(-1)
  // }
}

/**
 * 设置页面埋点跟踪
 * @param res
 */
function setMatomo(res) {
  try {
    if (res && res.d) {
      // console.log('Res:', res)

      _paq.push([ "setCustomUrl", window.location.href ]);
      _paq.push([ "setDocumentTitle", res.d.title || document.title || "" ]);
      _paq.push([ "deleteCustomVariables", "page" ]);
      // _paq.push(['setGenerationTimeMs', 0]);
      _paq.push([ "setCustomVariable", 1, "entId", res.d.entId || "", "page" ]);
      _paq.push([ "trackPageView" ]);

    }
  } catch (e) {
    console.log(e);
  }
}

function uuid(len = 32, firstU = true, radix = null) {
  let chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz".split("");
  let uuid = [];
  radix = radix || chars.length;

  if (len) {
    // 如果指定uuid长度,只是取随机的字符,0|x为位运算,能去掉x的小数位,返回整数位
    for (let i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)];
  } else {
    let r;
    // rfc4122标准要求返回的uuid中,某些位为固定的字符
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = "-";
    uuid[14] = "4";

    for (let i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | (Math.random() * 16);
        uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r];
      }
    }
  }
  // 移除第一个字符,并用u替代,因为第一个字符为数值时,该guuid不能用作id或者class
  if (firstU) {
    uuid.shift();
    return "u" + uuid.join("");
  } else {
    return uuid.join("");
  }
}

async function getPlatformUnionid() {
  return uni.getStorageSync("platform_unionid");
}

function wxmp_getUserProfile() {
  let userinfo = {};
  return new Promise(function (resolve, reject) {
    uni.getUserProfile({
      desc: "用于鉴定基础用户身份信息",
      lang: "zh_CN",
      success: async function (res) {
        console.log("getUserProfile success:", res);
        userinfo = res;
        await wxmp_getUserInfo(res);
        resolve(userinfo);
      },
      fail: function (err) {
        console.log("getUserProfile fail...", err);
        resolve(userinfo);
      }
    });
  });
}

async function wxmp_getUserInfo(userinfo) {





































}

function getUrlParams(url) {
  let URLstr = url + "";
  let Params = URLstr.split("?")[1] || URLstr.split("#")[1];
  // 声明空对象保存URL参数数据
  let urlObj = {};
  if (Params) {
    Params = Params.replace("#", "&");
    let newParams = Params.split("&");
    newParams.forEach(function (value, index) {
      let newParams = value.split("=");
      if (newParams[1]) {
        // 将值保存到对象中
        urlObj[newParams[0]] = decodeURIComponent(newParams[1]);
      }
    });
  }
  // 返回对象数据
  return urlObj;
}

function urlParams2String(urlObj) {
  let retArray = [];
  for (let i of Object.keys(urlObj)) {
    retArray.push(`${ i }=` + encodeURIComponent((urlObj[i] || "") + ""));
  }
  return retArray.join("&");
}

function _initDialog() {
  let pickerEl = document.getElementById("_picker");
  let pickerIconEl = document.getElementById("_picker_icon");
  let btn = document.getElementById("_login_btn");
  let cover = document.getElementById("_dialog_cover");
  let pickerCheck = false;
  let serviceAgreement = document.getElementById("serviceAgreement");
  let privacyPolicy = document.getElementById("privacyPolicy");
  serviceAgreement.addEventListener("click", function () {
    document.body.removeChild(document.getElementById("login_dialog"));
    _policy("serviceAgreement");
  });
  privacyPolicy.addEventListener("click", function () {
    document.body.removeChild(document.getElementById("login_dialog"));
    _policy("privacyPolicy");
  });
  pickerEl.addEventListener("click", function () {
    if (pickerCheck) {
      pickerCheck = false;
      pickerIconEl.classList.remove("_dialog_picker_icon_active");
    } else {
      pickerCheck = true;
      pickerIconEl.classList.add("_dialog_picker_icon_active");
    }
  });
  btn.addEventListener("click", function () {
    if (pickerCheck) {
      login();
    } else {
      uni.showToast({
        title: "请勾选同意",
        icon: "none"
      });
    }
  });
  cover.addEventListener("click", function () {
    document.body.removeChild(document.getElementById("login_dialog"));
  });
}

function _policy(type) {
  let url = "";
  let title = "";
  if (type === "privacyPolicy") {
    url = i18n.locale === "en" ? getApp().globalData.privacyPolicyEn : getApp().globalData.privacyPolicyZh;
    title = i18n.t("common.隐私协议");
  } else if (type === "serviceAgreement") {
    url = i18n.locale === "en" ? getApp().globalData.serviceAgreementEn : getApp().globalData.serviceAgreementZh;
    title = i18n.t("common.服务协议");
  } else if (type === "otherPlatform") {
    // title = this.$t('common.第三方平台隐私政策')
    url = "https://static.jiuzhouauto.com/h5/listSDK.html";
    title = i18n.t("common.第三方平台隐私政策");
  }
  if (url && url !== "") {
    uni.navigateTo({
      url: `/pages/url/url?url=${ encodeURIComponent(url) }&title=${ encodeURIComponent(title) }`
    });
  }
}

function showLoginDialog() {
  document.body.insertAdjacentHTML(
    "beforeEnd",
    `<div id="login_dialog"> <div id="_dialog_cover" class="_dialog_cover"></div>
  <div class="_dialog_box">
      <div class="_dialog_title">请授权微信头像、昵称</div>
      <img class="_dialog_logo" src="http://img.jiuzhouauto.com/i/2022/07/21/1658388173594-88173.png" alt="">
      <div class="_dialog_btn" id="_login_btn">授权进入车后快豹</div>
      <div class="_dialog_picker" id="_picker">
          <div class="_dialog_picker-icon" id="_picker_icon"></div>
          <div class="_dialog_picker-text">
              <span>选中登录代表您已同意</span>
              <span class="_dialog_warn" id="serviceAgreement">《用户服务协议》</span>
              <span class="_dialog_warn" id="privacyPolicy">《隐私权政策》</span>
          </div>
      </div>
  </div></div>`
  );
  _initDialog();
}

async function projectJumpLoginSuccess() {
  let path = decodeURIComponent(uni.getStorageSync('project_path'));
  if (path) {
    let token = uni.getStorageSync("token")
    uni.removeStorageSync('project_path')
    window.location.assign(path.replace('${token}', token).replace('{token}', token))
    await sleep()
  }
}

function sleep(time = 1000) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve()
    }, time)
  })
}

function pageId() {
  return md5(`${ new Date().getTime().toString() }${ Math.floor(Math.random() * 99999 + 10000) }`)
}

export * from "./tools/calculate";

export {
  showLoginDialog,
  initApp,
  api,
  login,
  checkLogin,
  getAppUserInfo,
  setAppGlobalData,
  getAppGlobalData,
  setMatomo,
  newApi,
  gotoApp,
  gotoPage,
  isWeixnH5,
  isWeixnMPH5,
  getImgApi,
  wxRegister,
  formatMsgTime,
  jweixinShare,
  changeLanguage,
  uuid,
  goBack,
  scan_code_login_tip,
  getMyExchangedId,
  getMyCard,
  wxmp_getUserProfile,
  wxmp_getUserInfo,
  getPlatformUnionid,
  wxmp_getMobile,
  isWeixinMini,
  getUrlParams,
  urlParams2String,
  initTabBar,
  projectJumpLoginSuccess,
  sleep,
  pageId,
  procScene
};
