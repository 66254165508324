var components;
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.picData.d.img && _vm.picData.d.img !== '')?_c('v-uni-view',{staticClass:"posR"},[_c('v-uni-view',{staticClass:"posR img-box",style:(_vm.styleObj),on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.toPage(_vm.picData)
}}},[_c('lazy-load',{attrs:{"image":_vm.picData.d.img,"loading-img":_vm.picData.d && _vm.picData.d.type
          ? 'http://static.jiuzhouauto.com/h5_image/loading_squ.jpg'
          : 'http://static.jiuzhouauto.com/h5_image/loading_hori.jpg',"borderTopRadius":_vm.picData.s.radiusTopLeft,"borderBottomRadius":_vm.picData.s.radiusBottomLeft,"error-img":_vm.picData.d && _vm.picData.d.type
          ? 'http://static.jiuzhouauto.com/h5_image/loading_failed_squ.jpg'
          : 'http://static.jiuzhouauto.com/h5_image/loading_failed_hori.jpg'}})],1)],1):_vm._e()}
var recyclableRender = false
var staticRenderFns = []

export { render, staticRenderFns, recyclableRender, components }