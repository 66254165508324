<template>
  <view class="swiper-slide">
    <slot />
  </view>
</template>

<script>
export default {
  name: "PcscrollBarItem"
}
</script>

<style scoped scoped>

</style>
