<template>
  <view class="card" @click="goToVideo(info._id)" v-if="type === 'default'">
    <lazy-load
      :loading-img="info.frontCoverType === 1 ? loadingVer : loadingHori"
      :error-img="info.frontCoverType === 1 ? loadingVer : loadingHori"
      borderTopRadius="8"
      img-mode="aspectFill"
      :height="info.frontCoverType === 1 ? 380 : 186"
      :image="`${info.frontCoverView || info.view}${webp ? '!w300p' : '!w300'}`"
    />
    <view class="bg-white content-wrap">
      <view class="text-xl text-cut title">{{ info.title }}</view>
    </view>
  </view>
  <view v-else class="card-full" @click="goToVideo(info._id)">
    <lazy-load
      :image="info.frontCoverView ? `${info.frontCoverView}${webp ? '!w750p' : '!w750'}` : info.view"
      :height="info.frontCoverViewRatio ? bookListWidth * info.frontCoverViewRatio : 230"
      borderTopRadius="8"
      borderBottomRadius="8"
      :loading-img="loadingVer"
      :error-img="loadingHoriError"
      img-mode="aspectFill"
    ></lazy-load>
  </view>
</template>

<script>
import LazyLoad from "@/components/common/LazyLoad";

import checkWebp from "@/common/tools/webp";
const loadingHori = "http://static.jiuzhouauto.com/h5_image/loading_hori.jpg";
const loadingVer = "http://static.jiuzhouauto.com/h5_image/loading_ver.jpg";
const loadingHoriError = "http://static.jiuzhouauto.com/h5_image/loading_failed_hori.jpg";
const loadingVerError = "http://static.jiuzhouauto.com/h5_image/loading_failed_ver.jpg";
export default {
  props: {
    info: {
      type: Object,
      default: {}
    },
    type: {
      type: String,
      default: "default"
    },
    clipBookId: [String, Number],
    bookshelf: [String, Number]
  },
  components: { LazyLoad },
  data: () => ({
    webp: false,
    bookListWidth: 690,
    loadingHori,
    loadingVer,
    loadingHoriError,
    loadingVerError
  }),
  created() {
    checkWebp().then(res => (this.webp = res));
  },
  methods: {
    px2rpx(px) {},
    async toGetPage(obj) {
      let a = await this.$util.gotoApp({ type: 1, app: obj });
      if (a) {
        await this.$util.gotoPage(a);
      } else {
        getApp().globalData.appLocation.href = obj;
      }
    },
    goToVideo() {
      /* 统计打点 */
      // #ifdef H5
      _paq.push(["trackEvent", "clickId", this.info._id]);
      // #endif
      if (this.info.app) {
        this.toGetPage(this.info.app);
      }
    }
  }
};
</script>

<style lang="scss" scoped scoped>
.card {
  width: calc(100% - 30rpx);
  margin: 15rpx;
  box-shadow: 0 0 16rpx 2rpx rgba(0, 0, 0, 0.1);
  border-radius: 8rpx;
  overflow: hidden;
}
.card-full {
  width: calc(100% - 30rpx);
  border-radius: 8rpx;
  margin: 15rpx;
  box-shadow: 0 0 16rpx 2rpx rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
.content-wrap {
  padding: 18rpx 13rpx;

  .title {
    font-size: 24rpx;
  }
}
</style>
