<template>
	<view class="u-field" :class="{'u-border-top': borderTop, 'u-border-bottom': borderBottom }">
		<view class="u-field-inner" :class="[type == 'textarea' ? 'u-textarea-inner' : '', 'u-label-postion-' + labelPosition]">
			<!-- <view class="u-label" :class="[required ? 'u-required' : '']" :style="{
				justifyContent: justifyContent,
				flex: labelPosition == 'left' ? `0 0 ${labelWidth}rpx` : '1'
			}">
				<view class="u-icon-wrap" v-if="icon">
					<u-icon size="32" :custom-style="iconStyle" :name="icon" :color="iconColor" class="u-icon"></u-icon>
				</view>
				<slot name="icon"></slot>
				<text class="u-label-text" :class="[this.$slots.icon || icon ? 'u-label-left-gap' : '']">{{ label }}</text>
			</view> -->
			<view class="fild-body">
				<view class="u-flex-1 u-flex">
					<textarea v-if="type == 'textarea'" class="u-flex-1 u-textarea-class" :style="[fieldStyle]" :value="value"
					 :placeholder="placeholder" :placeholderStyle="placeholderStyle" :disabled="disabled" :maxlength="inputMaxlength"
					 :focus="focus" :autoHeight="autoHeight" :fixed="fixed" @input="onInput" @blur="onBlur" @focus="onFocus" @confirm="onConfirm"
					 @tap="fieldClick" />
					<input
						v-else
						:type="type"
						class="u-flex-1 u-field__input-wrap"
						:value="value"
						:password="password || type === 'password'"
						:placeholder="placeholder"
						:placeholderStyle="placeholderStyle"
						:disabled="disabled"
						:maxlength="inputMaxlength"
						:focus="focus"
						:confirmType="confirmType"
						@focus="onFocus"
						@blur="onBlur"
						@input="onInput"
						@confirm="onConfirm"
						@tap="fieldClick"
					/>
				</view>
				<view
				:size="clearSize"
				:style="{opacity: clearable && value != '' && focused ? '1' : '0'}"
				class="u-clear-icon"
				@tap="onClear">
					<view class="cuIcon-roundclosefill text-gray"></view>
				</view>
			</view>
		</view>
		<view v-if="errorMessage !== false && errorMessage != ''" class="u-error-message">{{ errorMessage }}</view>
	</view>
</template>

<script>
export default {
	name:"c-input",
	props: {
		icon: String,
		rightIcon: String,
		// arrowDirection: {
		// 	type: String,
		// 	default: 'right'
		// },
		required: Boolean,
		label: String,
		password: Boolean,
		clearable: {
			type: Boolean,
			default: true
		},
		// 左边标题的宽度单位rpx
		labelWidth: {
			type: [Number, String],
			default: 130
		},
		// 对齐方式，left|center|right
		labelAlign: {
			type: String,
			default: 'left'
		},
		inputAlign: {
			type: String,
			default: 'left'
		},
		iconColor: {
			type: String,
			default: '#606266'
		},
		autoHeight: {
			type: Boolean,
			default: true
		},
		errorMessage: {
			type: [String, Boolean],
			default: ''
		},
		placeholder: String,
		placeholderStyle: String,
		focus: Boolean,
		fixed: Boolean,
		value: [Number, String],
		type: {
			type: String,
			default: 'text'
		},
		disabled: {
			type: Boolean,
			default: false
		},
		maxlength: {
			type: [Number, String],
			default: 140
		},
		confirmType: {
			type: String,
			default: 'done'
		},
		// lable的位置，可选为 left-左边，top-上边
		labelPosition: {
			type: String,
			default: 'left'
		},
		// 输入框的自定义样式
		fieldStyle: {
			type: Object,
			default() {
				return {}
			}
		},
		// 清除按钮的大小
		clearSize: {
			type: [Number, String],
			default: 30
		},
		// lable左边的图标样式，对象形式
		iconStyle: {
			type: Object,
			default() {
				return {}
			}
		},
		// 是否显示上边框
		borderTop: {
			type: Boolean,
			default: false
		},
		// 是否显示下边框
		borderBottom: {
			type: Boolean,
			default: true
		},
	},
	data() {
		return {
			focused: false,
			itemIndex: 0,
		};
	},
	computed: {
		inputWrapStyle() {
			let style = {};
			style.textAlign = this.inputAlign;
			// 判断lable的位置，如果是left的话，让input左边两边有间隙
			if(this.labelPosition == 'left') {
				style.margin = `0 8rpx`;
			} else {
				// 如果lable是top的，input的左边就没必要有间隙了
				style.marginRight = `8rpx`;
			}
			return style;
		},
		rightIconStyle() {
			let style = {};
			if (this.arrowDirection == 'top') style.transform = 'roate(-90deg)';
			if (this.arrowDirection == 'bottom') style.transform = 'roate(90deg)';
			else style.transform = 'roate(0deg)';
			return style;
		},
		labelStyle() {
			let style = {};
			if(this.labelAlign == 'left') style.justifyContent = 'flext-start';
			if(this.labelAlign == 'center') style.justifyContent = 'center';
			if(this.labelAlign == 'right') style.justifyContent = 'flext-end';
			return style;
		},
		// uni不支持在computed中写style.justifyContent = 'center'的形式，故用此方法
		justifyContent() {
			if(this.labelAlign == 'left') return 'flex-start';
			if(this.labelAlign == 'center') return 'center';
			if(this.labelAlign == 'right') return 'flex-end';
		},
		// 因为uniapp的input组件的maxlength组件必须要数值，这里转为数值，给用户可以传入字符串数值
		inputMaxlength() {
			return Number(this.maxlength)
		},
		// label的位置
		fieldInnerStyle() {
			let style = {};
			if(this.labelPosition == 'left') {
				style.flexDirection = 'row';
			} else {
				style.flexDirection = 'column';
			}

			return style;
		}
	},
	methods: {
		onInput(event) {
			this.$emit('input', event.target.value);
		},
		onFocus(event) {
			this.focused = true;
			this.$emit('focus', event);
		},
		onBlur(event) {
			this.focused = false;
			this.$emit('blur', event);
		},
		onConfirm(e) {
			this.$emit('confirm', e.detail.value);
		},
		onClear(event) {
			this.$emit('input', '');
		},
		rightIconClick() {
			this.$emit('right-icon-click');
			this.$emit('click');
		},
		fieldClick() {
			this.$emit('click');
		}
	}
};
</script>

<style lang="scss" scoped scoped>
.u-field {
	font-size: 28rpx;
	// padding: 20rpx 28rpx;
	text-align: left;
	position: relative;
	color: #303133;
}

.u-field-inner {
	display: flex;
	align-items: center;
}

.u-textarea-inner {
	align-items: flex-start;
}

.u-textarea-class {
	min-height: 96rpx;
	width: auto;
	font-size: 28rpx;
}

.fild-body {
	display: flex;
	flex: 1;
	align-items: center;
}

.u-arror-right {
	margin-left: 8rpx;
}

.u-label-text {
	display: inline-block;
}

.u-label-left-gap {
	margin-left: 6rpx;
}

.u-label-postion-top {
	flex-direction: column;
	align-items: flex-start;
}

.u-label {
	width: 130rpx;
	flex: 1 1 130rpx;
	text-align: left;
	position: relative;
	display: flex;
	align-items: center;
}

.u-required::before {
	content: '*';
	position: absolute;
	left: -16rpx;
	font-size: 14px;
	color: #fa3534;
	height: 9px;
	line-height: 1;
}

.u-field__input-wrap {
	position: relative;
	overflow: hidden;
	font-size: 28rpx;
	height: 48rpx;
	flex: 1;
	width: auto;
}

.u-clear-icon {
	display: flex;
	align-items: center;
}

.u-error-message {
	color: #fa3534;
	font-size: 26rpx;
	text-align: left;
}

.placeholder-style {
	color: rgb(150, 151, 153);
}

.u-input-class {
	font-size: 28rpx;
}

.u-button-wrap {
	margin-left: 8rpx;
}
.u-flex-1{
	flex: 1;
}
.u-flex{
	display: flex;
}
</style>
