var components;
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-uni-view',{staticClass:"u-wrap",class:'u-lazy-item-' + _vm.elIndex,style:([
    _vm.borderRadiusStyle,
    {
      opacity: Number(_vm.opacity),
      // 因为time值需要改变,所以不直接用duration值(不能改变父组件prop传过来的值)
      transition: 'opacity ' + _vm.time / 1000 + 's ease-in-out',
    } ])},[_c('v-uni-view',{class:'u-lazy-item-' + _vm.elIndex},[(!_vm.isError)?_c('v-uni-image',{staticClass:"u-lazy-item",style:([_vm.borderRadiusStyle, {height: _vm.imgHeight}]),attrs:{"show-menu-by-longpress":"true","src":_vm.isShow ? _vm.image : _vm.loadingImg,"mode":_vm.imgMode},on:{"load":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.imgLoaded).apply(void 0, arguments)
},"error":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.loadError).apply(void 0, arguments)
},"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.clickImg).apply(void 0, arguments)
}}}):_c('v-uni-image',{staticClass:"u-lazy-item error",style:([_vm.borderRadiusStyle, {height: _vm.imgHeight}]),attrs:{"src":_vm.errorImg,"mode":_vm.imgMode},on:{"load":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.errorImgLoaded).apply(void 0, arguments)
},"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.clickImg).apply(void 0, arguments)
}}})],1)],1)}
var recyclableRender = false
var staticRenderFns = []

export { render, staticRenderFns, recyclableRender, components }