<template>
  <view class="cu-modal" :class="[visible ? 'show' : '']" @click="clickModal">
    <view class="cu-dialog">
      <view class="cu-bar bg-white justify-end">
        <view v-if="title && title !== ''" class="content">{{title}}</view>
        <view class="action">
          <text class="cuIcon-close text-red" @click.stop="handleAction('close')"></text>
        </view>
      </view>
      <view class="padding-xl">
        {{content}}
      </view>
      <view class="cu-bar bg-white justify-end">
        <view class="action">
          <button v-if="this.$type && this.$type !== 'alert'" class="cu-btn line-green text-green" @click.stop="handleAction('cancel')">取消</button>
          <button class="cu-btn bg-green margin-left" @click.stop="handleAction('confirm')">确定</button>
        </view>
      </view>
    </view>
  </view>
</template>

<script>
export default {
  name: 'Modal',
  props: {
  },
  data() {
    return {
      closed: false,
      visible: false,
      title: '',
      content: '',
      clickModalCancel: false
    }
  },
  watch: {
    closed(newVal) {
      if (newVal) {
        this.visible = false;
      }
    }
  },
  methods: {
    clickModal() {
      if (this.clickModalCancel) {
        this.handleAction('cancel')
      }
    },
    handleAction(action) {
      this.closed = true;
      this.$destroy(true);
      this.$el.parentNode.removeChild(this.$el);
      this.callback(action)
    },
    handleAfterLeave() {
    }
  }
}
</script>

<style scoped scoped>
</style>
