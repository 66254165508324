<template>
  <view class="girdWarp posR over-hidden bg-img"
        :style="[{width:'100%',height:gridH + 'px','background-image': backgroundImage ? 'url('+backgroundImage+')' : 'unset'}]">
    <view class="imgList" :class="'gird-' + girdId">
      <view class="view" 
      :style="[{'background-image': (item.img ? 'url('+item.img+')' : 'unset'), 'background-size': '100% 100%', 'background-repeat': 'no-repeat'}]"
            v-for="(item,index) in formdata.d.item" :key="index"
            @click="gridTo(item, index)"></view>
    </view>
  </view>
</template>

<script>
import { uuid } from '@/common/util';
export default {
  name: 'TypeGrid',
  behaviors: ['uni://form-field'],
  props: {
    formdata: {
      type: Object,
      default() {
        return {
          d: {
            item: []
          },
          s: {
            height: 0,
            background: ''
          }
        }
      }
    }
  },
  data() {
    return {
      girdId: uuid(),
      isShow: false,
    }
  },
  computed: {
    gridH() {
      return getApp().globalData.systemInfo.windowWidth * this.formdata.s.height
    },
    typeNumber() {
      if (this.formdata.footerType) {
        return this.formdata.footerType
      }
      return 4
    },
    backgroundImage() {
      return this.formdata.s.background || '';
    }
  },
  created() {
    // if (this.formdata.footerType) {
    //   this.typeNumber = this.formdata.footerType
    // }
    // this.cuIconList = this.formdata
    // this.gridH = getApp().globalData.systemInfo.windowWidth * this.cuIconList.s.height
    // 由于一些特殊原因，不能将此变量放到data中定义
    this.observer = {};
  },
  methods: {
    disconnectObserver(observerName) {
      const observer = this[observerName];
      observer && observer.disconnect();
    },
    gridTo(e, index) {
      if (e.app) {
        this.toGetPage(e.app, index)
      } else if (e.h5) {
        getApp().globalData.appLocation.href = e.h5
      } 
    },
    // 获取jiuzhouauto
    async toGetPage(obj, index) {
      let a = await this.$util.gotoApp({
        type: this.typeNumber,
        app: obj
      })
      if (a) {
        if (parseInt(this.typeNumber) === 1) {
          a.menuIndex = index + 1
          this.$emit('clickBottomNav', a)
        } else {
          await this.$util.gotoPage(a, this.typeNumber)
        }
      } else {
        if (/^https?:\/\/(([a-zA-Z0-9_-])+(\.)?)*(:\d+)?(\/((\.)?(\?)?=?&?[a-zA-Z0-9_-](\?)?)*)*$/i.test(obj)) {
          getApp().globalData.appLocation.href = obj
        }
      }
    },
  },
  // beforeDestroy() {
  //   this.disconnectObserver('contentObserver');
  // },
  mounted() {
    // setTimeout(() => {
    //   this.disconnectObserver('contentObserver');
    //   const contentObserver = uni.createIntersectionObserver(this);
    //   contentObserver.relativeToViewport({
    //     bottom: 0,
    //   }).observe('.gird-' + this.girdId, (res) => {
    //     if (res.intersectionRatio > 0) {
    //       this.isShow = true
    //       this.disconnectObserver('contentObserver');
    //     }
    //   })
    //   this.contentObserver = contentObserver;
    // }, 30)
  }
}
</script>

<style scoped scoped>
.imgList {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
}

.imgList > .view {
  flex: 1;
}
</style>
