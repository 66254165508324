var components;
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-uni-view',{staticClass:"swipe-container"},[_c('v-uni-movable-area',{staticClass:"u-swipe-action",style:({ backgroundColor: _vm.bgColor })},[_c('v-uni-movable-view',{staticClass:"u-swipe-view",style:({
					width: _vm.movableViewWidth ? _vm.movableViewWidth : '100%'
				}),attrs:{"direction":"horizontal","disabled":_vm.disabled,"x":_vm.moveX},on:{"change":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.change).apply(void 0, arguments)
},"touchend":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.touchend).apply(void 0, arguments)
},"touchstart":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.touchstart).apply(void 0, arguments)
}}},[_c('v-uni-view',{staticClass:"u-swipe-content",on:{"click":function($event){$event.stopPropagation();
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.contentClick).apply(void 0, arguments)
}}},[_vm._t("default")],2),(_vm.showBtn)?_c('v-uni-view',{staticClass:"u-swipe-del",style:([_vm.btnStyle])},[_vm._t("right")],2):_vm._e()],1)],1)],1)}
var recyclableRender = false
var staticRenderFns = []

export { render, staticRenderFns, recyclableRender, components }