<template>
  <!-- <view
    v-masonry="waterFlowId"
    origin-left="true"
    column-width=".masonryHalfImg"
    percent-position="true"
    transition-duration="0s"
    :item-selector="'.masonryHalfImg-' + waterFlowId"
  >
    <view
      :class="['masonryHalfImg', 'masonryHalfImg-' + waterFlowId]"
      v-masonry-tile
      v-for="(item, index) in formdata.d"
      :key="index"
    >
      <type-halfImg v-bind:formdata="item" ></type-halfImg>
    </view>
  </view> -->
  <view>
    <waterfall :column="2" :dataList="formdata.d" v-if="formdata.d.length > 0">
      <template v-slot="{ info, idx, colIdx }">
        <view :class="'col-' + colIdx + ' masonryHalfImg newHalfImg ' + (idx===0?'mt0':'')">
          <type-halfImg :formdata="info" :idx="idx" />
        </view>
      </template>
    </waterfall>
  </view>
</template>

<script>
import md5 from "js-md5";
import waterfall from "@/components/waterfall";

export default {
  props: {
    formdata: {
      type: Object,
      default() {
        return [];
      }
    }
  },
  components: { waterfall },
  data() {
    return {
      waterFlowId: ""
    };
  },
  methods: {
    async updateMasonry() {
      // await this.$redrawVueMasonry(this.waterFlowId);
    }
  },
  async updated() {
    await this.updateMasonry();
  },
  watch: {},
  created() {
    this.waterFlowId = md5(`waterFlow-${new Date().getTime().toString()}${Math.floor(Math.random() * 99999 + 10000)}`);
  }
};
</script>

<style scoped scoped>
.masonryHalfImg {
  width: 100%;
  /* margin: 1vw; */
}
.col-0 {
  padding-right: 15upx;
  padding-left: 30upx;
}
.col-1 {
  padding-left: 15upx;
  padding-right: 30upx;
}
.newHalfImg {
  display: inline-block;
  margin-top: 28upx;
}
.mt0 {
  margin-top: 0;
}
</style>
