import { render, staticRenderFns, recyclableRender, components } from "./PcScrollBarItem.vue?vue&type=template&id=76eb92af&scoped=true&"
var renderjs
import script from "./PcScrollBarItem.vue?vue&type=script&lang=js&"
export * from "./PcScrollBarItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@dcloudio/vue-cli-plugin-uni/packages/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76eb92af",
  null,
  false,
  components,
  renderjs
)

export default component.exports