<template>
  <view v-if="picData.d.img && picData.d.img !== ''" class="posR">
    <view class="posR img-box" :style="styleObj" @tap="toPage(picData)">
      <lazy-load
        :image="picData.d.img"
        :loading-img="
          picData.d && picData.d.type
            ? 'http://static.jiuzhouauto.com/h5_image/loading_squ.jpg'
            : 'http://static.jiuzhouauto.com/h5_image/loading_hori.jpg'
        "
        :borderTopRadius="picData.s.radiusTopLeft"
        :borderBottomRadius="picData.s.radiusBottomLeft"
        :error-img="
          picData.d && picData.d.type
            ? 'http://static.jiuzhouauto.com/h5_image/loading_failed_squ.jpg'
            : 'http://static.jiuzhouauto.com/h5_image/loading_failed_hori.jpg'
        "
      ></lazy-load>
      <!--			<img :src="picData.d.img" mode="" style="width:100%;height: auto;"></img>-->
    </view>
  </view>
</template>

<script>
import LazyLoad from "@/components/common/LazyLoad";
export default {
  components: {
    LazyLoad
  },
  behaviors: ["uni://form-field"],
  props: {
    formdata: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      picData: {}
    };
  },
  computed: {
    styleObj() {
      let styleMap = {
        marginBottom: "margin-bottom",
        marginLeft: "margin-left",
        marginRight: "margin-right",
        marginTop: "margin-top",
        radiusTopLeft: "border-top-left-radius",
        radiusTopRight: "border-top-right-radius",
        radiusBottomLeft: "border-bottom-left-radius",
        radiusBottomRight: "border-bottom-right-radius"
      };
      let style = ``;
      let unit = 'upx'
      // #ifdef MP-WEIXIN
      unit = 'rpx'
      // #endif
      Object.keys(styleMap).forEach(key => {
        if (this.picData.s[key]) {
          style += `${styleMap[key]}:${this.picData.s[key]}${unit};`;
        }
      });
      return style;
    }
  },
  methods: {
    toPage(obj) {
      if (obj.d.app) {
        this.toGetPage(obj.d.app);
      }
    },
    // 获取jiuzhouauto
    async toGetPage(obj) {
      let a = await this.$util.gotoApp({ type: 1, app: obj });
      if (a) {
        await this.$util.gotoPage(a);
      } else {
        getApp().globalData.appLocation.href = obj;
      }
    }
  },
  created() {
    this.picData = this.formdata;
    // if (this.picData.s.height) {
    //   this.boxH = this.picData.s.height * getApp().globalData.systemInfo.windowWidth
    // }
  }
};
</script>

<style scoped scoped>
.img {
  width: 100%;
}
.img-box {
  overflow: hidden;
}
.posT {
  position: absolute;
  top: 0;
  width: 100%;
}
.posFB {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.footer {
}
.footer .btn {
  float: right;
  border-radius: 15px;
  background: #f00;
  margin: 0 10px 10px;
  padding: 3px 10px;
  color: #fff;
}
.footer .btn2 {
  background: transparent;
  border: 1px solid #999;
  box-sizing: border-box;
  color: #999;
}
</style>
