var components;
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-uni-view',{staticClass:"purchase-wrap",on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.goToDetail).apply(void 0, arguments)
}}},[_c('v-uni-view',{staticClass:"top app-flex app-flex-fs-fs"},[_c('v-uni-view',{staticClass:"avatar"},[_c('v-uni-image',{attrs:{"src":_vm.purchaseData.headimgurl,"mode":"widthFix"}})],1),_c('v-uni-view',{staticClass:"info",class:[_vm.canExpand ? 'padding-bottom':'']},[_c('v-uni-view',{staticClass:"nickname"},[_vm._v(_vm._s(_vm.purchaseData.nickname))]),_c('v-uni-view',{staticClass:"content",style:(_vm.contentStyle)},[_c('v-uni-text',{staticStyle:{"user-select":"auto"},attrs:{"id":("purchase-content-" + (_vm.purchaseData.id))}},[_vm._v(_vm._s(_vm.purchaseData.content))])],1),(_vm.canExpand)?_c('v-uni-view',{staticClass:"expand-btn app-flex app-flex-c-c",on:{"click":function($event){$event.stopPropagation();
arguments[0] = $event = _vm.$handleEvent($event);
(_vm.expandContent).apply(void 0, arguments)
}}},[_c('v-uni-text',{staticClass:"text-sm"},[_vm._v(_vm._s(_vm.contentStyle ? '收起' : '点击展开'))]),_c('v-uni-text',{class:[_vm.contentStyle ? 'cuIcon-fold' : 'cuIcon-unfold']})],1):_vm._e()],1)],1),(_vm.purchaseData.imgs && _vm.purchaseData.imgs.length)?_c('v-uni-view',{staticClass:"flex justify-start imgList"},_vm._l((_vm.purchaseData.imgs),function(item,index){return _c('v-uni-image',{key:index,staticClass:"img-item",attrs:{"src":(item + "!w300"),"mode":"aspectFill"},on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.preview(index)
}}})}),1):_vm._e(),(_vm.showBottomBar)?_c('v-uni-view',{staticClass:"bottom text-sm app-flex app-flex-sb-c"},[_c('v-uni-view',{staticClass:"product"},[_vm._v("# "+_vm._s(_vm.purchaseData.prodCat.title[0]))]),_c('v-uni-view',{staticClass:"region"},[_vm._v("# "+_vm._s(_vm.purchaseData.region.title[0]))]),_c('v-uni-view',{staticClass:"comment-date"},[_vm._v(_vm._s(new Date(_vm.purchaseData.createdAt).format('yyyy-MM-dd HH:mm')))]),_c('v-uni-view',{staticClass:"expiration-date"},[_vm._v(_vm._s(_vm.purchaseData.validity))]),_c('v-uni-view',{staticClass:"comment app-flex"},[(_vm.purchaseData.pv > 0)?_c('v-uni-image',{staticClass:"image",attrs:{"src":"http://static.jiuzhouauto.com/h5_image/purchase/comment_active.png","mode":"widthFix"}}):_vm._e(),(_vm.purchaseData.pv <= 0)?_c('v-uni-image',{staticClass:"image",attrs:{"src":"http://static.jiuzhouauto.com/h5_image/purchase/comment.png","mode":"widthFix"}}):_vm._e(),_c('v-uni-view',{staticClass:"text-sm",staticStyle:{"margin-left":"6upx"},style:({color: _vm.purchaseData.pv > 0 ? 'red': '#8A8A8A'})},[_vm._v(_vm._s(_vm.purchaseData.pv > 999 ? '999+' : _vm.purchaseData.pv))])],1)],1):_vm._e()],1)}
var recyclableRender = false
var staticRenderFns = []

export { render, staticRenderFns, recyclableRender, components }