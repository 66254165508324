/*
 * @Descripttion: 
 * @Date: 2024-08-29 18:24:48
 * @LastEditTime: 2024-08-30 10:03:49
 */
const corpFavorite = {
    namespace: true,
    state: {
        status: false,
        corpInfo: {
            logo: '',
            company: '',
            companyEn: ''
        }
    },
    getters: {
        getCorpInfo: (state) => () => state.corpInfo
    },
    mutations: {
        SET_CORP_INFO(state, info) {
            state.corpInfo = info
        },
        SET_CORP_STATUS(state, status) {
            state.status = !status
        },
        SET_CORP_ORIGIN_STATUS(state, status) {
            state.status = status
        }
    },
    actions: {
        setCorpInfo({ commit }, info) {
            commit('SET_CORP_INFO', info )
        },
        setCorpStatus({ commit }, status) {
            commit("SET_CORP_STATUS", status)
        }, 
        setCorpOriginStatus({ commit }, status) {
            commit("SET_CORP_ORIGIN_STATUS", status)
        }
    }
}

export default corpFavorite