<template>
  <view style="position: relative" class="wrap">
    <!-- 小图区域-->
    <!-- 移动端-->
    <scroll-view :scroll-left="scrollPosition" v-if="!isPc && formdata.d && formdata.d.item.length" scroll-x show-scrollbar>
      <view :style="{height: imgStyle.height * 2 + 'px',width: imgStyle.width * 2 + 'px',padding:imgStyle.padding}" class="imgContainer">
        <view class="imgItem" :style="{marginLeft: index > 0 ? imgStyle.spacing : 0}" v-for="(img,index) in formdata.d.item" :key="index">
          <template>
            <image
										lazy-load="true"
                    :style="{width: imgStyle.width * 2 + 'px', height: imgStyle.height* 2 + 'px'}"
                    class="carouselImg"
                    :src="img.img"
                    @click="action(img.app)"
                    mode="aspectFill">
            </image >
          </template>
          <view v-if="img.text && img.text !== ''" class="carouselText">
            <view class="text">{{img.text || '图标底部显示的文字图标底部显示的文字图标底部显示的文字'}}</view>
          </view>
        </view>
      </view>
    </scroll-view>
    <!-- PC端-->
		<!-- #ifndef MP-WEIXIN -->
    <PcScrollBar v-else-if="isPc && formdata.d && formdata.d.item.length">
      <PcScrollBarItem v-for="(img,index) in formdata.d.item" :key="index">
        <image :src="img.img" :style="{width: imgStyle.width * 2 + 'px', height: imgStyle.height* 2 + 'px'}"
               @click="action(img.app)" mode="aspectFill"></image>
      </PcScrollBarItem>
    </PcScrollBar>
		<!-- #endif -->
  </view>
</template>

<script>
import {isPcBroswer} from "@/common/tools/system";
// #ifndef MP-WEIXIN
import PcScrollBar from "@/components/common/PcScrollBar";
import PcScrollBarItem from "@/components/common/PcScrollBarItem";
// #endif
export default {
  name: "TypeStdCarousel",
  components: {
		// #ifndef MP-WEIXIN
    PcScrollBar,
    PcScrollBarItem
		// #endif
  },
  props: {
    formdata: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      current: 0,
      startX: 0,
      startY: 0,
      scrollPosition: 0,
      isPc: isPcBroswer()
    };
  },
  computed: {
    imgStyle() {
      let height = getApp().globalData.systemInfo.windowWidth * this.formdata.s.height
      let width = height / this.formdata.s.ratio
      let marginH = this.formdata.s.marginH || 20
      let spacing = this.formdata.s.marginH || 30
      return {
        width: uni.upx2px(width),
        height: uni.upx2px(height),
        padding: marginH ? `0 ${getApp().globalData.systemInfo.windowWidth * this.formdata.s.marginH / 750}px` : 0,
        spacing: spacing ? `${getApp().globalData.systemInfo.windowWidth * this.formdata.s.spacing / 750}px` : 0
      }
    }
  },
  watch: {},
  methods: {
    async scrollAction() {
      await uni.$emit('uOnReachBottom')
    },
    async action(obj) {
      let a = await this.$util.gotoApp({type: 1, app: obj})
      if (a) {
        await this.$util.gotoPage(a)
      } else {
        if (obj && obj !== '') getApp().globalData.appLocation.href = obj
      }
    },
  },
  created() {
  }
}
</script>

<style lang="scss" scoped scoped>
.imgContainer {
  display: inline-flex;

  .imgItem {
    position: relative;
    .carouselText {
      background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      padding: 54upx 30upx 30upx 20upx;
      .text {
        width: calc(100%);
        height: 36upx;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
        color: white;
        font-size: 36upx;
        line-height: 36upx;
      }
    }
  }
}
</style>
