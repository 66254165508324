var components;
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-uni-view',{staticClass:"wrap",staticStyle:{"position":"relative"}},[(!_vm.isPc && _vm.formdata.d && _vm.formdata.d.item.length)?_c('v-uni-scroll-view',{attrs:{"scroll-left":_vm.scrollPosition,"scroll-x":true,"show-scrollbar":true}},[_c('v-uni-view',{staticClass:"imgContainer",style:({height: _vm.imgStyle.height * 2 + 'px',width: _vm.imgStyle.width * 2 + 'px',padding:_vm.imgStyle.padding})},_vm._l((_vm.formdata.d.item),function(img,index){return _c('v-uni-view',{key:index,staticClass:"imgItem",style:({marginLeft: index > 0 ? _vm.imgStyle.spacing : 0})},[[_c('v-uni-image',{staticClass:"carouselImg",style:({width: _vm.imgStyle.width * 2 + 'px', height: _vm.imgStyle.height* 2 + 'px'}),attrs:{"lazy-load":"true","src":img.img,"mode":"aspectFill"},on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.action(img.app)
}}})],(img.text && img.text !== '')?_c('v-uni-view',{staticClass:"carouselText"},[_c('v-uni-view',{staticClass:"text"},[_vm._v(_vm._s(img.text || '图标底部显示的文字图标底部显示的文字图标底部显示的文字'))])],1):_vm._e()],2)}),1)],1):(_vm.isPc && _vm.formdata.d && _vm.formdata.d.item.length)?_c('PcScrollBar',_vm._l((_vm.formdata.d.item),function(img,index){return _c('PcScrollBarItem',{key:index},[_c('v-uni-image',{style:({width: _vm.imgStyle.width * 2 + 'px', height: _vm.imgStyle.height* 2 + 'px'}),attrs:{"src":img.img,"mode":"aspectFill"},on:{"click":function($event){
arguments[0] = $event = _vm.$handleEvent($event);
_vm.action(img.app)
}}})],1)}),1):_vm._e()],1)}
var recyclableRender = false
var staticRenderFns = []

export { render, staticRenderFns, recyclableRender, components }